<template>
	<span class="display-flex align-items-flex-end">
		<span
			:class="['avatar', avatarBg, textColor]"
			:style="{
				height: avatarStyles[props.avatarSize].avatarHeight,
				width: avatarStyles[avatarSize].avatarWidth
			}"
		>
			<span v-if="hasName" :class="avatarStyles[props.avatarSize].initialIconSizeClass">
				{{ initials }}
			</span>
			<BaseSvgIcon
				v-else
				:width="avatarStyles[props.avatarSize].userIconWidth"
				:height="avatarStyles[props.avatarSize].userIconHeight"
				name="user"
			/>
		</span>
		<ProBadge
			v-if="isProOrPremium"
			class="align-self-flex-end"
			:size="avatarStyles[props.avatarSize].proBadgeSize"
			:text="isProUser ? 'Pro' : ''"
			:include-icon="isPremiumUser"
			:style="{ 'margin-left': avatarStyles[props.avatarSize].proBadgeMargin }"
		/>
	</span>
</template>

<script setup lang="ts">
import { AvatarSize, AvatarStyles } from 'types/layout';
import { isPremiumUser, isProOrPremium, isProUser } from '@utils/composables';
import { computed } from 'vue';
import ProBadge from '@components/badges/pro-badge.vue';
import { user } from '@store/modules/user';

interface Props {
	avatarSize?: AvatarSize;
}
const props = withDefaults(defineProps<Props>(), {
	avatarSize: 'small'
});

const avatarStyles: Record<AvatarSize, AvatarStyles> = {
	nav: {
		avatarHeight: '32px',
		avatarWidth: '32px',
		initialIconSizeClass: 'body-sm',
		proBadgeMargin: '-8px',
		proBadgeSize: 'small',
		userIconHeight: '16',
		userIconWidth: '16'
	},
	small: {
		avatarHeight: '48px',
		avatarWidth: '48px',
		initialIconSizeClass: 'body-md',
		proBadgeMargin: '-14px',
		proBadgeSize: 'medium',
		userIconHeight: '20',
		userIconWidth: '20'
	},
	medium: {
		avatarHeight: '64px',
		avatarWidth: '64px',
		initialIconSizeClass: 'body-xl font-weight-bold',
		proBadgeMargin: '-14px',
		proBadgeSize: 'medium',
		userIconHeight: '28',
		userIconWidth: '28'
	},
	large: {
		avatarHeight: '100px',
		avatarWidth: '100px',
		initialIconSizeClass: 'beta',
		proBadgeMargin: '-32px',
		proBadgeSize: 'large',
		userIconHeight: '36',
		userIconWidth: '36'
	}
};

const initials = computed((): string => {
	return hasName.value ? `${user.user?.firstName.charAt(0)}${user.user?.lastName.charAt(0)}` : '';
});

const hasName = computed((): boolean => {
	return !!user.user?.firstName && !!user.user?.lastName;
});

const avatarBg = computed((): string => {
	return isProOrPremium.value ? 'avatar-bg-pro' : 'avatar-bg';
});

const textColor = computed((): string => {
	return isProOrPremium.value ? 'text-color-white' : 'text-color-content-primary';
});
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;
.avatar {
	align-items: center;
	border: 1px solid gray(20);
	border-radius: 50%;
	display: flex;
	font-weight: 600;
	justify-content: center;
	letter-spacing: 1.5px;
	padding: 0;
	text-align: center;
	text-transform: uppercase;

	&.avatar-bg {
		background: token('greige');
	}

	&.avatar-bg-pro {
		background: token('content-primary');
	}
}
</style>
