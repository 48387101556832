<template>
	<div ref="target" class="module-md invitation-program-module position-relative bg-color-white">
		<div class="module-section">
			<div v-if="loading || isError">
				<div v-if="isError" class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
				<ModularDashboardPlaceholderBlock
					:show-error-icon="isError"
					class="width-100-smo py-50"
					error-icon-size="small"
					:use-animation="loading"
				/>

				<div v-if="isError" class="text-align-center">
					<ModularDashboardRetryButton @retry-clicked="loadModule()" />
				</div>
			</div>
			<div v-else>
				<div class="display-flex justify-content-space-between align-items-center">
					<div class="mr-100">
						<h2 class="body-md font-weight-bold">
							{{ invitationProgramModuleData?.title }}
						</h2>
						<p class="body-md mt-50 text-color-current-color">
							{{ invitationProgramModuleData?.subText }}
							<BaseButton classes="" @click="openDisclaimerModal">
								<BaseSvgIcon
									:fill="false"
									:stroke="true"
									name="info"
									class="mr-50"
									width="16"
									height="16"
								/>
							</BaseButton>
						</p>
					</div>
					<img class="icon-img" :src="invitationProgramModuleData?.iconUrl" alt="" />
				</div>
				<div class="position-relative">
					<BaseButton
						:mixpanel-custom-properties="mixpanelCustomProperties"
						:mixpanel-context="mixpanelActionContext"
						mixpanel-target="Copy Link"
						classes="
				copy-input-field display-flex justify-content-space-between align-items-center mt-100 width-100 rounded p-75
			"
						@click="copyInvitationLink"
					>
						<div class="display-flex invitation-link-wrapper">
							<BaseSvgIcon class="mr-50" height="24" width="24" name="invite-link" />
							<p class="m-0 overflow-hidden">
								{{ invitationProgramModuleData?.referralLink }}
							</p>
						</div>
						<div class="copy-button button button-default body-sm font-weight-bold">
							{{ linkCopied ? 'Copied' : 'Copy' }}
						</div>
					</BaseButton>
				</div>
				<transition name="fade" appear>
					<div
						v-if="linkCopied"
						:class="[
							'success-message text-align-center body-xs text-color-success mt-25',
							{
								'success-message--visible': linkCopied
							}
						]"
					>
						<span> Link copied to clipboard</span>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
export default {
	name: 'InvitationProgramModule'
};
</script>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { app } from '@store/modules/app';
import { copyToClipboard } from '@utils/clipboard';
import InvitationProgramModal from '@components/modals/invitation-program-modal.vue';
import { InvitationProgramModuleData } from 'types/modular-dashboard/custom';
import { MixpanelContext } from 'types/analytics';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useModularDashboardStore } from '@stores/modular-dashboard';

const isError = ref(false);

const loading = ref(true);

const target = ref<HTMLElement | null>(null);

const linkCopied = ref<boolean>(false);

const mixpanelCustomProperties: MixpanelContext = {
	'View Title': 'Invite Program Link Module'
};

const mixpanelActionContext: string = 'Invite Module';

const modularDashboardStore = useModularDashboardStore();

const invitationProgramModuleData = computed((): InvitationProgramModuleData | null => {
	return modularDashboardStore.invitationProgramModule;
});

watch(
	() => invitationProgramModuleData.value,
	(newModuleDetails, oldModuleDetails) => {
		if (oldModuleDetails === null && !!newModuleDetails && target.value) {
			trackMixpanelScrollIntoView(target.value, mixpanelCustomProperties);
		}
	},
	{ immediate: true }
);

function openDisclaimerModal(): void {
	app.UPDATE_CURRENT_MODAL({
		modal: InvitationProgramModal,
		props: {
			promoAmount: invitationProgramModuleData.value?.rewardPerInvite
		}
	});
}

async function copyInvitationLink(): Promise<void> {
	if (invitationProgramModuleData.value?.referralLink) {
		await copyToClipboard(invitationProgramModuleData.value?.referralLink);

		linkCopied.value = true;

		setTimeout(() => (linkCopied.value = false), 2000);
	}
}

async function loadModule(): Promise<void> {
	loading.value = true;
	isError.value = false;

	try {
		await modularDashboardStore.storeInvitationProgramModule();
	} catch {
		isError.value = true;
	} finally {
		loading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>
<style lang="scss" scoped>
@use '../../../../../styles/constants/_colors.scss' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.invitation-program-module {
	@include respond-to(smo) {
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
	}

	.copy-input-field {
		border: 1px solid gray(40);
		background: gray(5);
	}

	.icon-img {
		width: 64px;
		height: 64px;
	}

	.invitation-link-wrapper {
		max-width: 80%;
	}

	.copy-button {
		height: 1.85rem;
		padding: 0 0.5rem;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
