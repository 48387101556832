<template>
	<ListItemButton v-if="!props.item.complete" :list-item-config="getListItemConfig(item)" @click="openCta(item.cta)">
		<template #icon>
			<BaseSvgIcon name="check-validation-unfilled" :fill="false" />
		</template>
		<template #heading>
			<p class="body-md font-weight-normal">{{ item.cta.text }}</p>
		</template>
	</ListItemButton>
	<ListItem v-else :list-item-config="getListItemConfig(item)">
		<template #icon>
			<BaseSvgIcon name="check-validation-filled" />
		</template>
		<template #heading>
			<p class="body-md font-weight-normal text-color-content-tertiary text-decoration-line-through">{{
				item.cta.text
			}}</p>
		</template>
	</ListItem>
</template>
<script setup lang="ts">
import { GrowthChecklistItem } from 'types/account';
import ListItem from '@components/form/list-item/list-item.vue';
import ListItemButton from '@components/form/list-item/list-item-button.vue';
import { ListItemConfig } from 'types/form';
import { ModularDashboardCta } from 'types/modular-dashboard';
import { useRouter } from 'vue-router';

interface Props {
	item: GrowthChecklistItem;
}

const props = defineProps<Props>();

const router = useRouter();

function getListItemConfig(checklistItem: GrowthChecklistItem): ListItemConfig {
	return {
		heading: checklistItem.cta.text,
		iconSize: 'small',
		showChevron: !checklistItem.complete,
		cssClasses: 'px-0 py-100 bt-0'
	};
}

async function openCta(cta: ModularDashboardCta): Promise<void> {
	await router.push({ path: cta.targetUrl });
}
</script>
<style lang="scss" scoped>
.text-decoration-line-through {
	text-decoration: line-through;
}
</style>
