<template>
	<BasicBanner v-if="showBanner" data-context="Service Titan iPO">
		<p class="balance-text"
			>{{ bannerText }} <BaseLink class="text-color-white text-decoration-underline" :link="learnMoreLink"
		/></p>
		<template #close-button><span></span></template>
	</BasicBanner>
</template>

<script setup lang="ts">
import BasicBanner from '@components/layout/basic-banner.vue';
import { computed } from 'vue';
import { isAuthenticated } from '@utils/composables';
import { Link } from 'types/layout';
const bannerText = 'Read our 2024 year-end letter to investors.';

const learnMoreLink: Link = {
	text: 'Learn more',
	router: 'blog-post',
	params: { blogPostUrlSlug: '2024-year-end-letter-to-investors' }
};

const showBanner = computed((): boolean => {
	return !isAuthenticated.value;
});
</script>
<style scoped>
.balance-text {
	text-wrap: balance;
	-webkit-text-wrap: balance;
}
</style>
