<template>
	<div ref="target" class="mx-100-smo">
		<div v-if="isLoading || isError" class="module p-100 display-flex gap-100">
			<div class="display-flex flex-direction-column justify-content-space-between flex-1 gap-100">
				<div class="display-flex flex-direction-column gap-50">
					<div v-if="isError" class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
					<ModularDashboardPlaceholderLines
						:show-header="!isError"
						:show-footer="true"
						:use-animation="isLoading"
					/>
				</div>
				<div v-if="isError" class="text-align-center">
					<ModularDashboardRetryButton @retry-clicked="loadModule()" />
				</div>
			</div>
			<ModularDashboardPlaceholderBlock
				:show-error-icon="isError"
				class="width-100-smo"
				error-icon-size="small"
				:use-animation="isLoading"
			/>
		</div>
		<BaseLink
			v-else
			:link="moduleLink"
			classes="module display-flex flex-direction-column"
			mixpanel-element="Module"
			:mixpanel-target="details?.nextTask.headline"
		>
			<div class="p-100 position-relative">
				<div class="display-flex gap-100 justify-content-space-between">
					<div>
						<h4 class="body-lg font-weight-bold dark">{{ nextTask?.headline }}</h4>
						<p class="body-md dark mt-50">{{ nextTask?.description }}</p>
						<p
							v-if="showEstimate"
							class="body-sm text-color-content-secondary mt-50"
							data-test="time-to-complete"
							>{{ estimateText }}</p
						>
					</div>
					<div class="pr-200-md pr-100">
						<PercentageWheel :percentage="completedPercentage">
							<span class="beta ff-ivar-display font-weight-bold dark">{{ completedFraction }}</span>
						</PercentageWheel>
						<div class="position-absolute dismiss-button-container">
							<BaseButton
								v-if="details?.dismissible"
								classes="cta-link dismiss-button dark ml-50"
								data-test="dismiss"
								mixpanel-element="Module"
								mixpanel-target="Dismiss"
								:mixpanel-context="props.module.name"
								@click.prevent="dismiss()"
							>
								<BaseSvgIcon name="close" height="16" width="16" />
							</BaseButton>
						</div>
					</div>
				</div>
				<p class="mt-150 text-align-right">
					<span class="cta-link--rust">{{ nextTask?.ctaText }}</span>
				</p>
			</div>
		</BaseLink>
	</div>
</template>

<script lang="ts">
export default {
	name: 'AccountChecklistModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ModularDashboardContainer, ModularDashboardModule } from 'types/modular-dashboard';
import { AccountChecklistItem } from 'types/account';
import big from 'big.js';
import { getLinkFromRoute } from '@utils/request';
import { Link } from 'types/layout';
import { ModularDashboardAccountChecklist } from 'types/modular-dashboard/custom';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import PercentageWheel from '@components/charts/percentage-wheel.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useModularDashboardStore } from '@stores/modular-dashboard';
import { useRouter } from 'vue-router';

interface Props {
	module: ModularDashboardModule;
	container: ModularDashboardContainer;
}

interface Emits {
	(e: 'dismiss'): void;
}

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const target = ref<HTMLElement | null>(null);

const router = useRouter();

const modularDashboardStore = useModularDashboardStore();

const isLoading = ref(true);
const isError = ref(false);

const details = computed((): ModularDashboardAccountChecklist | null => {
	return modularDashboardStore.accountChecklistModuleData;
});

const nextTask = computed((): AccountChecklistItem | undefined => {
	return details.value?.nextTask;
});

const showEstimate = computed((): boolean => {
	return big(details.value?.tasksCompleted ?? '0').gt(0);
});

const estimateText = computed((): string => {
	return `Time to complete: ${nextTask.value?.estimateLabel ?? 0}`;
});

const completedPercentage = computed((): string => {
	if (!details.value) return '';

	const completedTasks = big(details.value?.tasksCompleted ?? '0');
	const totalTasks = big(details.value?.totalNumberOfTasks ?? '0');

	return completedTasks.div(totalTasks).times(100).toString();
});

const completedFraction = computed((): string => {
	return `${details.value?.tasksCompleted}/${details.value?.totalNumberOfTasks}`;
});

const moduleLink = computed((): Link => {
	return getLinkFromRoute(router, nextTask.value?.ctaRoute ?? '', '');
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': details.value?.nextTask.headline,
			'View Content': props.container.title,
			'View ID': props.module.name
		});
	}
});

function dismiss(): void {
	emits('dismiss');
}

async function loadModule(): Promise<void> {
	isLoading.value = true;
	isError.value = false;

	try {
		await modularDashboardStore.storeAccountChecklistModuleData();
	} catch {
		isError.value = true;
	} finally {
		isLoading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>

<style lang="scss" scoped>
.dismiss-button-container {
	top: 0.5rem;
	right: 0.5rem;
	.dismiss-button {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
	}
}
</style>
