import { OrderGroupResponse, RepeatOfferingInvestmentMetadata } from 'types/invest';
import { CrossSellResponse } from 'types/checkout';
import { currency } from '@filters/shared-filters';
import { investmentEntityRequest } from '@utils/request';
import { ReservationWindow } from 'types/reit-element';

export async function getRepeatOfferingInvestmentData(
	offeringVintageId: string,
	cohortId: string | null = null
): Promise<RepeatOfferingInvestmentMetadata> {
	const response = await investmentEntityRequest.get(`/repeat-investment/offering/${offeringVintageId}/metadata`, {
		params: { cohortId }
	});

	const repeatInvestmentData = response.data;

	const repeatReitInvestmentMetadata: RepeatOfferingInvestmentMetadata = {
		validReitInvestmentRange: repeatInvestmentData.validReitInvestmentRange,
		suggestedAchReitInvestmentAmounts: [],
		suggestedWireReitInvestmentAmounts: [],
		repeatOfferingInvestmentSelections: repeatInvestmentData.repeatReitInvestmentSelections,
		offeringName: repeatInvestmentData.offeringName,
		offeringId: repeatInvestmentData.offeringId,
		reitType: repeatInvestmentData.reitType,
		eligibleToInvestInOffering: repeatInvestmentData.eligibleToInvestInOffering,
		requiresK1Acknowledgment: repeatInvestmentData.requiresK1Acknowledgment,
		investmentEligibility: repeatInvestmentData.investmentEligibility,
		infoModule: repeatInvestmentData.infoModule
	};
	if (repeatInvestmentData.eligibleToInvestInOffering) {
		repeatReitInvestmentMetadata.suggestedAchReitInvestmentAmounts =
			repeatInvestmentData.suggestedAchReitInvestmentAmounts.map(
				(suggestedInvestmentAmount: { amount: string }) => {
					return {
						value: suggestedInvestmentAmount.amount,
						label: currency(suggestedInvestmentAmount.amount, 0),
						sortIndex: suggestedInvestmentAmount.amount
					};
				}
			);

		repeatReitInvestmentMetadata.suggestedAchReitInvestmentAmounts?.push({
			value: 'other',
			label: 'Other amount',
			sortIndex: 10000000
		});

		repeatReitInvestmentMetadata.suggestedWireReitInvestmentAmounts =
			repeatInvestmentData.suggestedWireReitInvestmentAmounts.map(
				(suggestedInvestmentAmount: { amount: string }) => {
					return {
						value: suggestedInvestmentAmount.amount,
						label: currency(suggestedInvestmentAmount.amount, 0),
						sortIndex: suggestedInvestmentAmount.amount
					};
				}
			);

		repeatReitInvestmentMetadata.suggestedWireReitInvestmentAmounts?.push({
			value: 'other',
			label: 'Other amount',
			sortIndex: 10000000
		});
	}
	return repeatReitInvestmentMetadata;
}

export const getOrderAllocation = async (amount: string, riaPlanId: string): Promise<OrderGroupResponse> => {
	const response = await investmentEntityRequest.get(`/investment/allocation`, {
		params: {
			amount,
			riaPlanId
		}
	});
	return response.data;
};

export const getOfferingReservationWindows = async (offeringVintageId: string): Promise<Array<ReservationWindow>> => {
	const response = await investmentEntityRequest.get(`/offering/${offeringVintageId}/reservation-windows`);
	return response.data;
};

export async function getCrossSellEligibility(): Promise<CrossSellResponse> {
	const response = await investmentEntityRequest.get('/cross-sell');
	return response.data;
}
