<template>
	<div v-if="numberOfItems > 0" class="mr-100-lg mt-25 display-flex ml-auto-smo mr-auto-smo ml-auto-mdo mr-auto-mdo">
		<span :class="isItemComplete(0) ? 'text-color-green' : 'text-color-bg-tertiary'">
			<BaseSvgIcon name="progress-block-rounded" :height="20" width="44.25" data-test="first-block" />
		</span>
		<span
			v-for="index in numberOfItems - 1"
			:key="index"
			:class="isItemComplete(index) ? 'text-color-green' : 'text-color-bg-tertiary'"
		>
			<BaseSvgIcon
				name="progress-block"
				class="progress-block-wrapper"
				:height="20"
				width="46"
				data-test="progress-block"
			/>
		</span>
		<span class="trophy-badge display-flex justify-content-center align-items-center">
			<BaseSvgIcon name="trophy" :height="24" :width="24" :fill="false" />
		</span>
	</div>
</template>
<script setup lang="ts">
interface Props {
	numberOfItems: number;
	numberOfCompleteItems: number;
}

const props = defineProps<Props>();

function isItemComplete(itemIndex: number): boolean {
	return itemIndex < props.numberOfCompleteItems;
}
</script>
<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;
@use '../../styles/utilities/respond-to.scss' as *;

.progress-block-wrapper {
	margin-left: -20px;
}

.trophy-badge {
	background-color: token('gold-light');
	width: 36.75px;
	height: 36.75px;
	border-radius: 50%;
	margin-left: -20px;
	margin-top: -4px;
	@include respond-to(lg) {
		top: 0;
		right: 0;
	}
}
</style>
