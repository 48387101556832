<template>
	<StandardModal data-context="Track rewards" @modal-close="close">
		<template #title>
			<h2 class="heading-5">{{ content.title }}</h2>
			<p v-if="content.subText" class="mt-50 body-md font-weight-normal">{{ content.subText }}</p>
		</template>
		<template #content>
			<table class="display-block">
				<thead v-if="content.header" class="display-block">
					<th>
						<h3 class="body-md"> {{ content.header }}</h3>
					</th>
				</thead>
				<tbody class="display-block body-md">
					<tr
						v-for="(pair, index) in content.keyValuePairs"
						:key="pair.key"
						:class="[
							'display-flex justify-content-space-between',
							{
								'bb py-100': !isLastItem(index),
								'pt-100': isLastItem(index)
							}
						]"
					>
						<th class="text-color-content-tertiary font-weight-normal">{{ pair.key }}</th>
						<td>{{ pair.value }}</td>
					</tr>
				</tbody>
			</table>
		</template>
	</StandardModal>
</template>

<script setup lang="ts">
import StandardModal from '@components/modals/standard-modal.vue';
import { TrackRewardsModalData } from 'types/modular-dashboard/custom';

interface Props {
	content: TrackRewardsModalData;
}

interface Emits {
	(e: 'modal-close'): void;
}

const emits = defineEmits<Emits>();

const props = defineProps<Props>();

function isLastItem(index: number): boolean {
	return index === props.content.keyValuePairs?.length - 1 || false;
}

function close(): void {
	emits('modal-close');
}
</script>
