<template>
	<div
		:class="[
			'list-item',
			'display-flex',
			listItemPadding,
			'justify-content-space-between',
			'align-items-center',
			'text-color-content-primary',
			{ disabled: disabled },
			listItemConfig.cssClasses
		]"
	>
		<div class="display-flex align-items-center">
			<div :class="['list-item__icon-container', 'mr-100', `icon--${iconSize}`]" role="presentation">
				<slot name="icon"></slot>
			</div>
			<div>
				<div class="list-item__heading-container display-flex align-items-center">
					<p
						:id="labelId"
						:class="[
							'list-item__heading mr-50',
							headingTypeSize,
							{ 'font-weight-bold': displaySubheading }
						]"
						><slot name="heading">{{ listItemConfig.heading }}</slot></p
					>
					<span class="list-item__tag"><slot name="tag"></slot></span>
				</div>
				<p
					v-if="displaySubheading"
					:class="['list-item__subheading mt-0 text-color-content-secondary', subheadingTypeSize]"
					><slot name="subheading"> <span v-html="listItemConfig.subheading" /> </slot
				></p>
			</div>
		</div>
		<slot name="control"
			><BaseSvgIcon
				v-if="listItemConfig.showChevron"
				role="presentation"
				class="arrow ml-100"
				name="chevron"
				dir="right"
				height="24"
				width="24"
		/></slot>
	</div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { ListItemConfig, ListItemIconSize } from 'types/form';

interface Props {
	disabled?: boolean;
	labelId?: string;
	listItemConfig: ListItemConfig;
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
	labelId: undefined
});

const slots = useSlots();

const displaySubheading = computed((): boolean => {
	return !!props.listItemConfig.subheading || (!!slots && !!slots.subheading && !!slots.subheading());
});

const iconSize = computed((): ListItemIconSize => {
	return props.listItemConfig.iconSize || 'large';
});

const listItemPadding = computed((): string => {
	switch (props.listItemConfig.listItemSize) {
		case 'small':
			return 'p-100';
		case 'large':
		default:
			return 'p-150';
	}
});

const headingTypeSize = computed((): string => {
	switch (props.listItemConfig.listItemSize) {
		case 'small':
			return 'body-md';
		case 'large':
		default:
			return 'body-lg';
	}
});

const subheadingTypeSize = computed((): string => {
	switch (props.listItemConfig.listItemSize) {
		case 'small':
			return 'body-sm';
		case 'large':
		default:
			return 'body-md';
	}
});
</script>

<style lang="scss">
@use '../../../styles/constants/colors' as *;
@use '../../../styles/utilities/respond-to.scss' as *;

.list-item {
	&.bb-0 {
		border-bottom: 0;
	}

	a {
		color: inherit;
	}

	.list-item__heading-container {
		flex-wrap: wrap-reverse;
	}

	.list-item__icon-container {
		&.icon--x-large {
			height: 4.5rem;
			width: 4.5rem;
			min-width: 4.5rem;
		}

		&.icon--large {
			height: 3rem;
			width: 3rem;
			min-width: 3rem;

			svg {
				height: inherit;
				width: inherit;
			}
		}

		&.icon--small {
			height: 1.5rem;
			width: 1.5rem;
			min-width: 1.5rem;

			svg {
				height: inherit;
				width: inherit;
			}
		}

		&:empty {
			display: none;
		}
	}

	&.disabled {
		&:hover {
			background-color: inherit;
		}

		filter: opacity(0.4);
	}
}

li:has(.list-item) + li:has(.list-item) {
	.list-item {
		border-top: 1px solid token('bg-tertiary');

		&.bt-0 {
			border-top: 0;
		}

		&.bt-0-md {
			@include respond-to('md') {
				border-top: 0;
			}
		}

		&.bt-0-lg {
			@include respond-to('lg') {
				border-top: 0;
			}
		}
	}
}

li.module:not(:first-of-type):has(.list-item) {
	margin-top: 1rem;
}
</style>
