<template>
	<div id="app">
		<component :is="currentBanner.banner" v-if="currentBanner" v-bind="currentBanner.props" />
		<ToastContainer />
		<RouterView :key="routeKey" />
		<transition name="transition--modal" mode="in-out">
			<component
				:is="currentModal.modal"
				v-if="currentModal"
				v-bind="currentModal.props"
				@modal-close="closeModal"
			/>
		</transition>
		<GdprCookieBanner v-if="!isWebview" />
		<IntercomLauncher v-if="isIntercomEnabled"></IntercomLauncher>
	</div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { MaybeComputedRef, Script, useHead } from '@unhead/vue';
import { useDebounceFn, useEventListener, useIdle } from '@vueuse/core';
import { app } from '@store/modules/app';
import { appCloudfrontPath } from '@constants';
import appConfig from './app.config.json';
import { CurrentBanner } from 'types/banner';
import { CurrentModal } from 'types/modal';
import GdprCookieBanner from '@components/layout/gdpr-cookie-banner.vue';
import { getScreenSize } from '@utils/client';
import { initializeMixpanel } from '@utils/analytics';
import IntercomLauncher from '@components/layout/intercom-launcher.vue';
import { isWebview } from '@utils/composables';
import { stripRouteHash } from '@utils/routing';
import ToastContainer from '@components/layout/toast-container.vue';
import { useRoute } from 'vue-router';
import { useThirdPartyAuthStore } from '@stores/third-party-auth';
import { webviewRoutesTest } from '@constants/routes';

// eslint-disable-next-line @typescript-eslint/no-empty-function
window.initMap = function () {
	// Intentionally left blank, callback is required. https://developers.google.com/maps/documentation/javascript/url-params#required_parameters
};

const route = useRoute();
const { idle } = useIdle(5 * 60 * 1000); // 5 min
const thirdPartyAuthStore = useThirdPartyAuthStore();

const metaDescription = `Invest in world-class private market investments like real estate, venture capital, and private credit. Fundrise is America's largest direct-access alternative asset manager.`;

const isIntercomEnabled = computed((): boolean => app.intercomEnabled);

const currentModal = computed((): CurrentModal | null => {
	document.body.style.overflow = app.currentModal ? 'hidden' : '';
	return app.currentModal;
});

const currentBanner = computed((): CurrentBanner | null => app.currentBanner);

const routeKey = computed((): string => stripRouteHash(route));

watch(idle, (idleValue) => {
	app.SET_USER_IS_IDLE(idleValue);
});

function initializeMeta(): void {
	const scripts: MaybeComputedRef<Script<['script']>[]> = [
		{
			src: `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_PLACES_API_KEY}&libraries=places&callback=initMap`,
			key: 'google-maps-script'
		},
		{
			src: `https://accounts.google.com/gsi/client`,
			key: 'google-sign-in-script',
			async: true,
			onload: () => (thirdPartyAuthStore.isGoogleLibraryLoaded = true)
		},
		{
			src: `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`,
			key: 'apple-sign-in-script',
			async: true,
			onload: () => (thirdPartyAuthStore.isAppleLibraryLoaded = true)
		},
		{ src: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js', key: 'plaid-script' }
	];

	const wafUrl = import.meta.env.VITE_WAF_CHALLENGE_URL;
	if (wafUrl) {
		scripts.push({
			src: wafUrl,
			key: 'waf-script',
			async: true
		});
	}

	useHead({
		titleTemplate: (title?: string) => (title ? `${title} | ${appConfig.title}` : appConfig.title),
		link: [
			{ rel: 'canonical', href: window.location.href },
			{
				rel: 'icon',
				type: 'image/png',
				href: `${appCloudfrontPath}/images/global/favicon.png`
			}
		],
		meta: [
			{ charset: 'utf-8' },
			{ name: 'viewport', content: 'width=device-width, minimum-scale=1.0, maximum-scale=1.0' },
			{ name: 'apple-mobile-web-app-capable', content: 'yes' },
			{ name: 'p:domain_verify', content: '411182b397074a11ab6dd421b34bc4e8' },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:url', content: window.location.href },
			{ property: 'fb:app_id', content: appConfig.facebookAppId },
			{ name: 'twitter:card', content: 'summary_large_image' },
			{ name: 'twitter:site', content: '@fundrise' },
			{ name: 'description', content: metaDescription },
			{ property: 'og:description', content: metaDescription },
			{
				property: 'og:image',
				content: `${appCloudfrontPath + appConfig.metaImage}`
			}
		],
		script: scripts
	});
}

function updateScreenSize(): void {
	app.UPDATE_SCREEN_SIZE(getScreenSize());
}

function closeModal(): void {
	app.UPDATE_CURRENT_MODAL(null);
}

// Note - This is manually mounted. It will run before the route guards.
async function setup(): Promise<void> {
	initializeMeta();
	updateScreenSize();
	useEventListener(window, 'load', useDebounceFn(updateScreenSize, 200));
	useEventListener(window, 'resize', useDebounceFn(updateScreenSize, 200));

	const params = new URLSearchParams(window.location.search);
	const webviewParam = params.get('webview');
	const isMobileWebviewRoute = webviewRoutesTest.test(window.location.pathname) || webviewParam === 'true';
	if (isMobileWebviewRoute) {
		app.SET_IS_MOBILE_WEBVIEW(true);
	}

	initializeMixpanel();
	await app.initializeLaunchDarklyInstance();
}

setup();
</script>
