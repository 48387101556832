import { getItemFromSessionStorage, removeItemFromSessionStorage } from '@utils/web-storage';
import { includesVerifyRoute, routesThatRequireEntitySelectionPostLogin } from '@router/auth-guard-helpers';
import { app } from '@store/modules/app';
import { investmentEntity } from '@store/modules/investment-entity';
import { isAuthenticated } from '@utils/composables';
import { RouteLocationRaw } from 'vue-router';
import { storageKeyLoginRedirect } from '@constants/storage-keys';
import { user } from '@store/modules/user';

export function handleLoginRedirect(loginRedirect: string): RouteLocationRaw {
	const entityList = user.user?.entityDetails?.investmentEntities ?? [];
	const loginRedirectRequiresEntitySelection =
		entityList.length > 1 &&
		routesThatRequireEntitySelectionPostLogin.some((element) => loginRedirect.includes(element));
	removeItemFromSessionStorage(storageKeyLoginRedirect);

	if (loginRedirectRequiresEntitySelection) {
		return { path: loginRedirect, query: { investmentEntityId: 'select' } };
	} else {
		return loginRedirect;
	}
}

export function determineUserDashboardRoute() {
	if (app.isAdvisor) {
		return { name: 'advisor-dashboard' };
	}
	if (investmentEntity.hasNeverBeenAnInvestor) {
		return { name: 'user-overview' };
	}
	return { name: 'account-overview' };
}

export async function determineLoginRoute(forceGetUser = false): Promise<RouteLocationRaw> {
	const loginRedirect = getItemFromSessionStorage(storageKeyLoginRedirect);
	const loginRedirectIncludesVerifyRoute = loginRedirect && includesVerifyRoute(loginRedirect);

	if (isAuthenticated.value && (!user.user || forceGetUser)) {
		await user.getUser();
	}

	const userNeedsToVerifyEmail = !app.isVerified && !investmentEntity.hasNeverBeenAnInvestor;
	const userNeedsToVerifyMobile =
		user.user?.shouldPromptUserForMobileVerify && !investmentEntity.hasNeverBeenAnInvestor;

	if (app.tokenScope === 'REQUIRES_ADDITIONAL_AUTH') {
		return { name: 'login-two-factor' };
	}

	if (!app.isVerified && loginRedirectIncludesVerifyRoute) {
		return handleLoginRedirect(loginRedirect);
	}

	if (userNeedsToVerifyEmail) {
		return { name: 'unverified-email' };
	}

	if (userNeedsToVerifyMobile) {
		return { name: 'login-mobile-verify' };
	}

	// New login-redirect logic should be added here.

	if (loginRedirect) {
		return handleLoginRedirect(loginRedirect);
	}

	return determineUserDashboardRoute();
}
