import { AccountValueChartTimeHorizonType, GrowthChecklistModuleData } from 'types/account';
import {
	ChecklistItemType,
	InvitationProgramModuleData,
	ModularDashboardAccountChecklist,
	ModularDashboardPortfolioOverview,
	ModularDashboardProgressModule
} from 'types/modular-dashboard/custom';
import { ModularDashboardData, ModularDashboardMetadata } from 'types/modular-dashboard';
import { app } from '@store/modules/app';
import { FeaturedCard } from 'types/layout';
import { investmentEntityRequest } from '@utils/request';
import { ModularDashboardDeltaItem } from 'types/modular-dashboard/delta-module';
import { PerformanceContextCardData } from 'types/performance-context';

export const dismissDashboardModule = async (module: string): Promise<void> => {
	await investmentEntityRequest.post(`/dashboard-modules/dismiss`, { module });
};

export const getModularDashboardData = async (): Promise<ModularDashboardData> => {
	const response = await investmentEntityRequest.get(`/modular-dashboard`);
	return response.data;
};

export const getModularDashboardMetadata = async (): Promise<ModularDashboardMetadata> => {
	const response = await investmentEntityRequest.get(`/modular-dashboard/metadata`);
	return response.data;
};

export const getDeltaModuleData = async (moduleName: string): Promise<ModularDashboardDeltaItem> => {
	const response = await investmentEntityRequest.get(`/modules/DELTA/${moduleName}`);
	return response.data;
};

export const getFeaturedModuleData = async (moduleName: string): Promise<FeaturedCard> => {
	const response = await investmentEntityRequest.get(`/modules/FEATURED_CONTENT/${moduleName}`);
	return response.data;
};

export const getAccountChecklistModuleData = async (): Promise<ModularDashboardAccountChecklist> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/ACCOUNT_CHECKLIST');
	return response.data;
};

export const getGrowthChecklistModuleData = async (): Promise<GrowthChecklistModuleData> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/GROWTH_CHECKLIST');
	return response.data;
};

export const getPortfolioOverviewModuleData = async (): Promise<ModularDashboardPortfolioOverview> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/PORTFOLIO_OVERVIEW');
	return response.data;
};

export const getProgressModuleData = async (): Promise<ModularDashboardProgressModule> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/PROGRESS');
	return response.data;
};

export const loadNextProgressModule = async (): Promise<ModularDashboardProgressModule> => {
	const response = await investmentEntityRequest.post('/modules/CUSTOM/PROGRESS/next-milestone');
	return response.data;
};

export const getPerformanceContextData = async (
	timeRange: AccountValueChartTimeHorizonType
): Promise<Array<PerformanceContextCardData>> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/ACCOUNT_VALUE_CHART', {
		params: {
			timeRange
		}
	});
	return response.data;
};

export const getInvitationProgramModule = async (): Promise<InvitationProgramModuleData> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/INVITATION_PROGRAM');
	return response.data;
};

export const trackChecklistItem = async (eventName: ChecklistItemType): Promise<void> => {
	if (!app.isReadOnlyAccess) {
		await investmentEntityRequest.post('/tracked-event', { type: eventName });
	}
};
