<template>
	<BaseButton
		class="list-item-button"
		:disabled="disabled"
		:labelled-by="buttonLabel"
		:data-test="testId"
		:mixpanel-element="mixpanelElement"
		:mixpanel-target="mixpanelTarget"
		:mixpanel-custom-properties="mixpanelCustomProperties"
		component="div"
		:no-style="true"
		:click-text="clickText"
		@click="handleClick"
	>
		<ListItem :label-id="buttonLabel" :disabled="disabled" :list-item-config="listItemConfig">
			<template #icon>
				<slot name="icon"></slot>
			</template>
			<template #heading>
				<slot name="heading"></slot>
			</template>
			<template #subheading>
				<slot name="subheading"></slot>
			</template>
			<template #tag>
				<slot name="tag"></slot>
			</template>
			<template #control>
				<slot name="control"></slot>
			</template>
		</ListItem>
	</BaseButton>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { convertTextToSlug } from '@utils/prismic/html-serializer';
import ListItem from '@components/form/list-item/list-item.vue';
import { ListItemConfig } from 'types/form';
import { MixpanelContext } from 'types/analytics';

interface Props {
	disabled?: boolean;
	labelId?: string;
	listItemConfig: ListItemConfig;
	testId?: string;
	mixpanelCustomProperties?: MixpanelContext;
	mixpanelElement?: string;
	mixpanelTarget?: string;
	clickText?: string;
}

interface Emits {
	(e: 'click', value: Event): void;
}

const emits = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
	labelId: undefined,
	testId: undefined,
	mixpanelCustomProperties: undefined,
	mixpanelElement: 'Button',
	mixpanelTarget: undefined,
	clickText: undefined
});

const buttonLabel = computed((): string => {
	return props.labelId || convertTextToSlug(props.listItemConfig.heading);
});

function handleClick(event: Event): void {
	if (!props.disabled) {
		emits('click', event);
	}
}
</script>
