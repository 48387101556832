import { investmentEntity } from '@store/modules/investment-entity';
import { RouteLocationRaw } from 'vue-router';
import { useInvestStore } from '@stores/invest';

export const addFundsEntryRedirectHandling = (): string | null => {
	switch (investmentEntity.additionalInvestmentEligibility) {
		case 'ENTITY_NO_INVESTMENTS':
			return '/start-investing';
		case 'ENTITY_NO_PLAN_SELECTION':
			return '/account/ria-upgrade';
		case 'ENTITY_MISSING_INFORMATION':
			switch (investmentEntity.entityType) {
				case 'INDIVIDUAL':
					return `/account/profile/personal-information`;
				case 'JOINT_ACCOUNT':
					return `/account/investment-accounts/joint-account/account-holder-information/edit`;
				case 'TRUST':
					return `/account/investment-accounts/trust/information/edit`;
				case 'LLC':
					return `/account/investment-accounts/entity-account/entity-information/edit`;
				default:
					return null;
			}
		default:
			return null;
	}
};

export const addFundsOfferingEntryRedirectHandling = (): null | RouteLocationRaw => {
	const investStore = useInvestStore();
	if (investmentEntity.isIraEntity) {
		return {
			name: 'ira-add-funds-start',
			query: { reitOfferingVintageId: investStore.investMetaData?.offering?.vintageId },
			replace: true
		};
	}
	switch (investmentEntity.additionalInvestmentEligibility) {
		case 'ENTITY_NO_INVESTMENTS':
			return { name: 'start-investing', replace: true };
		case 'ENTITY_MISSING_INFORMATION':
			switch (investmentEntity.entityType) {
				case 'INDIVIDUAL':
					return {
						name: 'profile-address-edit',
						replace: true
					};
				case 'JOINT_ACCOUNT':
					return {
						name: 'settings-joint-account-information-edit',
						replace: true
					};
				case 'TRUST':
					return {
						name: 'settings-trust-information-edit',
						replace: true
					};
				case 'LLC':
					return {
						name: 'settings-entity-account-information-edit',
						replace: true
					};
				default:
					return null;
			}
		default:
			return null;
	}
};
