import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	addNewEmailRecipient,
	deleteEmailRecipient,
	deleteNickname,
	getBeneficiaries,
	getDocumentDeliveryPreference,
	getEmailRecipients,
	updateDocumentDeliveryPreference,
	updateEmailRecipient,
	updateNickname
} from '@api/settings';
import { BankAccount, NewManualBankAccount, PaymentMethod, PaymentMethods } from 'types/payment';
import { deleteRecurringInvestmentSchedule, getRecurringInvestmentSchedule } from '@api/auto-invest';
import { DocumentDeliveryPreferenceEnum, EmailRecipient } from 'types/settings';
import {
	DripStatus,
	FeatureEligibility,
	InvestmentEntity,
	InvestmentEntityType,
	IraEntityMetadata,
	UserReferralEligibilityStatus
} from 'types/investment-entity';
import { EntityAccountDetails, JointAccountDetails, TrustAccountDetails } from 'types/checkout';
import { getBankAccounts, getPaymentMethods, linkManualBankAccount } from '@api/bank-accounts';
import { getCurrentRiaPlan, getPerformanceData, getPositionsTable, updateRiaPlan } from '@api/investment-entity';
import { getEntityAccountDetails, getJointAccountDetails, getTrustAccountDetails } from '@api/checkout';
import { getNotifications, updateNotifications } from '@api/header';
import { InvestmentStrategy, RiaPlan } from 'types/plan';
import { setDatadogAutoInvestSchedule, setDatadogUserAUM, setDatadogUserEntityData } from '@utils/datadog';
import { AdditionalInvestmentEligibility } from 'types/invest';
import { app } from './app';
import { AutoInvestSchedule } from 'types/auto-invest';
import { Beneficiary } from 'types/account';
import { getDrip } from '@api/drip';
import { getIraAccountDetails } from '@api/ira';
import { getNAVReinvestmentPreference } from '@api/nav-reinvestment';
import { IraAccountDetails } from 'types/ira';
import { isBankPaymentMethod } from '@utils/payment-methods';
import { NAVReinvestmentPreference } from 'types/nav-reinvestment';
import { NotificationResponse } from 'types/header';
import { Performance } from 'types/performance';
import { Positions } from 'types/portfolio';
import { setItemInLocalStorage } from '@utils/web-storage';
import store from '..';
import { useModularDashboardStore } from '@stores/modular-dashboard';
import { usePerformancePortfolioStore } from '@stores/performance-portfolio';
import { user } from '@store/modules/user';

@Module({
	namespaced: true,
	name: 'investmentEntity',
	store
})
class InvestmentEntityModule extends VuexModule {
	additionalInvestmentEligibility: AdditionalInvestmentEligibility = 'ENTITY_MISSING_INFORMATION';
	beneficiaries: Array<Beneficiary> = [];
	currentRiaPlanName = '';
	currentRiaPlanInvestmentStrategy: InvestmentStrategy | null = null;
	currentSelectedRiaPlanId = '';
	documentDeliveryPreference: DocumentDeliveryPreferenceEnum | null = null;
	eligibleForDrip = false;
	eligibleForInvestorGoal = false;
	eligibleForRecurringInvestment = false;
	eligibleForShareholderVote = false;
	entityType: InvestmentEntityType = 'INDIVIDUAL';
	entityTypeLabel = '';
	externalId = '';
	dripEnabled = false;
	navReinvestmentPreference: NAVReinvestmentPreference | null = null;
	dripPreference: DripStatus | string = '';
	featureEligibility: FeatureEligibility = {
		advancedReporting: 'DISABLED',
		customAllocation: 'DISABLED',
		directInvest: 'DISABLED'
	};
	freezeReason = '';
	hasActiveRecurringInvestmentSchedule = false;
	hasAdvisoryServices = false;
	hasEverOwnedIpoShares = false;
	hasLegacyInvestments = false;
	investmentEntityId = '';
	investmentEntityName = '';
	investorGoalExists = false;
	iraEntityMetadata: IraEntityMetadata = {
		isMtcIra: false,
		isIraOnboardingFinished: false,
		iraType: null,
		iraTypeLabel: ''
	};
	isFrozen = false;
	isIraEntity = false; // includes IRA entities and LLC entities with a tax-designation of IRA
	isReitInvestor = false;
	isVerifiedAccredited = false;
	nickname = '';
	showBrowseMenuItem = false;
	showTaxCenter = false;
	userReferralEligibilityStatus: UserReferralEligibilityStatus = 'INELIGIBLE';

	notifications: NotificationResponse = {
		notifications: [],
		generatedTimestamp: '',
		lastNotificationView: ''
	};
	riaPlan: RiaPlan | null = null;

	// Populated on request
	additionalEmailRecipients: Array<EmailRecipient> = [];
	autoInvestSchedule: AutoInvestSchedule | null = null;
	bankAccounts: Array<BankAccount> = [];
	entityAccountDetails: EntityAccountDetails | null = null;
	iraAccountDetails: IraAccountDetails | null = null;
	jointAccountDetails: JointAccountDetails | null = null;
	trustAccountDetails: TrustAccountDetails | null = null;
	paymentMethods: PaymentMethods | null = null;
	positions: Positions | null = null;
	performance: Performance | null = null;

	get investmentEntityObject(): InvestmentEntity {
		return {
			additionalInvestmentEligibility: this.additionalInvestmentEligibility,
			currentRiaPlanName: this.currentRiaPlanName,
			currentRiaPlanInvestmentStrategy: this.currentRiaPlanInvestmentStrategy,
			currentSelectedRiaPlanId: this.currentSelectedRiaPlanId,
			dripEnabled: this.dripEnabled,
			eligibleForDrip: this.eligibleForDrip,
			eligibleForInvestorGoal: this.eligibleForInvestorGoal,
			eligibleForRecurringInvestment: this.eligibleForRecurringInvestment,
			eligibleForShareholderVote: this.eligibleForShareholderVote,
			entityType: this.entityType,
			entityTypeLabel: this.entityTypeLabel,
			externalId: this.externalId,
			featureEligibility: this.featureEligibility,
			freezeReason: this.freezeReason,
			hasActiveRecurringInvestmentSchedule: this.hasActiveRecurringInvestmentSchedule,
			hasAdvisoryServices: this.hasAdvisoryServices,
			hasEverOwnedIpoShares: this.hasEverOwnedIpoShares,
			hasLegacyInvestments: this.hasLegacyInvestments,
			investmentEntityId: this.investmentEntityId,
			investmentEntityName: this.investmentEntityName,
			investorGoalExists: this.investorGoalExists,
			iraEntityMetadata: this.iraEntityMetadata,
			isFrozen: this.isFrozen,
			isIraEntity: this.isIraEntity,
			isReitInvestor: this.isReitInvestor,
			isVerifiedAccredited: this.isVerifiedAccredited,
			nickname: this.nickname,
			showBrowseMenuItem: this.showBrowseMenuItem,
			showTaxCenter: this.showTaxCenter,
			userReferralEligibilityStatus: this.userReferralEligibilityStatus
		};
	}

	get isLegacyInvestorWithCashDistribution(): boolean {
		return !this.currentSelectedRiaPlanId && !this.dripEnabled;
	}

	get isLegacyInvestorWithoutPlan(): boolean {
		return this.isReitInvestor && this.additionalInvestmentEligibility === 'ENTITY_NO_PLAN_SELECTION';
	}

	get hasNeverBeenAnInvestor(): boolean {
		return !this.isReitInvestor && !this.hasLegacyInvestments;
	}

	get isDirectInvestEligible(): boolean {
		return this.featureEligibility.directInvest === 'ELIGIBLE';
	}

	get isEligibleForReinvestment(): boolean {
		return this.eligibleForDrip || !this.isLegacyInvestorWithoutPlan;
	}

	get isRedeemedNoDirectInvestNoPlanAccount(): boolean {
		const modularDashboardStore = useModularDashboardStore();
		const isAccountFullyRedeemed =
			modularDashboardStore.modularDashboardMetadata?.settlementState === 'FULLY_REDEEMED';
		return isAccountFullyRedeemed && !this.isDirectInvestEligible && !this.currentRiaPlanName;
	}

	@Action({ rawError: true })
	public async setInvestmentEntity(newEntity: InvestmentEntity): Promise<void> {
		this.reset();
		setItemInLocalStorage('lastSelectedInvestmentEntity', newEntity.investmentEntityId);
		this.UPDATE_ADDITIONAL_INVESTMENT_ELIGIBILITY(newEntity.additionalInvestmentEligibility);
		this.UPDATE_CURRENT_RIA_PLAN_NAME(newEntity?.currentRiaPlanName ?? '');
		this.UPDATE_CURRENT_RIA_PLAN_INVESTMENT_STRATEGY(newEntity?.currentRiaPlanInvestmentStrategy ?? null);
		this.UPDATE_CURRENT_SELECTED_RIA_PLAN_ID(newEntity?.currentSelectedRiaPlanId ?? '');
		this.UPDATE_DRIP_ENABLED(newEntity.dripEnabled);
		this.UPDATE_ELIGIBLE_FOR_DRIP(newEntity.eligibleForDrip);
		this.UPDATE_ELIGIBLE_FOR_INVESTOR_GOAL(newEntity.eligibleForInvestorGoal);
		this.UPDATE_ELIGIBLE_FOR_RECURRING_INVESTMENT(newEntity.eligibleForRecurringInvestment);
		this.UPDATE_ELIGIBLE_FOR_SHAREHOLDER_VOTE(newEntity.eligibleForShareholderVote);
		this.UPDATE_ENTITY_TYPE(newEntity.entityType);
		this.UPDATE_ENTITY_TYPE_LABEL(newEntity?.entityTypeLabel ?? '');
		this.UPDATE_EXTERNAL_ID(newEntity.externalId);
		this.UPDATE_FEATURE_ELIGIBILITY(newEntity.featureEligibility);
		this.UPDATE_FREEZE_REASON(newEntity?.freezeReason ?? '');
		this.UPDATE_HAS_ACTIVE_RECURRING_INVESTMENT_SCHEDULE(newEntity.hasActiveRecurringInvestmentSchedule);
		this.UPDATE_HAS_ADVISORY_SERVICES(newEntity.hasAdvisoryServices);
		this.UPDATE_HAS_EVER_OWNED_IPO_SHARES(newEntity.hasEverOwnedIpoShares);
		this.UPDATE_HAS_LEGACY_INVESTMENTS(newEntity.hasLegacyInvestments);
		this.UPDATE_INVESTMENT_ENTITY_ID(newEntity.investmentEntityId);
		this.UPDATE_INVESTMENT_ENTITY_NAME(newEntity.investmentEntityName);
		this.UPDATE_INVESTOR_GOAL_EXISTS(newEntity.investorGoalExists);
		this.UPDATE_IRA_ENTITY_METADATA(newEntity.iraEntityMetadata);
		this.UPDATE_IS_FROZEN(newEntity.isFrozen);
		this.UPDATE_IS_IRA_ENTITY(newEntity.isIraEntity);
		this.UPDATE_IS_REIT_INVESTOR(newEntity.isReitInvestor);
		this.UPDATE_IS_VERIFIED_ACCREDITED(newEntity.isVerifiedAccredited);
		this.UPDATE_NICKNAME(newEntity.nickname);
		this.UPDATE_SHOW_BROWSE_MENU_ITEM(newEntity.showBrowseMenuItem);
		this.UPDATE_SHOW_TAX_CENTER(newEntity.showTaxCenter);
		this.UPDATE_USER_REFERRAL_ELIGIBILITY_STATUS(newEntity.userReferralEligibilityStatus);

		await this.storeNotifications();
		if (newEntity.currentRiaPlanName) {
			await this.storeRiaPlanForEntity();
		}

		app.initializeLaunchDarklyInstance();
		setDatadogUserEntityData(newEntity);
	}

	@Action({ rawError: true })
	public async storeRiaPlanForEntity(): Promise<void> {
		try {
			const riaPlan = await getCurrentRiaPlan();
			this.UPDATE_RIA_PLAN(riaPlan);
		} catch {}
	}

	@Action({ rawError: true })
	public async updateRiaPlanForEntity(plan: RiaPlan): Promise<void> {
		await updateRiaPlan(plan.id);
		this.UPDATE_RIA_PLAN(plan);
		this.UPDATE_CURRENT_SELECTED_RIA_PLAN_ID(plan.id);
		this.UPDATE_CURRENT_RIA_PLAN_NAME(plan.name);
		this.UPDATE_CURRENT_RIA_PLAN_INVESTMENT_STRATEGY(plan.investmentStrategy);
	}

	@Action({ rawError: true })
	public async storeAutoInvestSchedule(force = false): Promise<void> {
		if (force || this.autoInvestSchedule === null) {
			const autoInvestSchedule = await getRecurringInvestmentSchedule();
			this.UPDATE_AUTO_INVEST_SCHEDULE(autoInvestSchedule);
			setDatadogAutoInvestSchedule(!!autoInvestSchedule);
		}
	}

	@Action({ rawError: true })
	public async storeBankAccounts(force = false): Promise<void> {
		if (force || this.bankAccounts.length === 0) {
			const bankAccounts = await getBankAccounts();
			this.UPDATE_BANK_ACCOUNTS(bankAccounts);
		}
	}

	@Action({ rawError: true })
	public async storePaymentMethods(force = false): Promise<void> {
		if (this.paymentMethods === null || force) {
			const paymentMethods = await getPaymentMethods();
			this.UPDATE_PAYMENT_METHODS(paymentMethods);
		}
	}

	@Action({ rawError: true })
	public async storeBeneficiaries(force = false): Promise<void> {
		if (this.beneficiaries.length === 0 || force) {
			const beneficiaries = await getBeneficiaries();
			this.UPDATE_BENEFICIARIES(beneficiaries);
		}
	}

	@Action({ rawError: true })
	public async storeEmailRecipients(force = false): Promise<void> {
		if (this.additionalEmailRecipients.length === 0 || force) {
			const recipients = await getEmailRecipients();
			this.UPDATE_ADDITIONAL_EMAIL_RECIPIENTS(recipients);
		}
	}

	@Action({ rawError: true })
	public async storeEntityAccountDetails(force = false): Promise<void> {
		if (!this.entityAccountDetails || force) {
			const entityAccountDetails = await getEntityAccountDetails(investmentEntity.investmentEntityId);
			this.UPDATE_ENTITY_ACCOUNT_DETAILS(entityAccountDetails);
		}
	}

	@Action({ rawError: true })
	public async storeIraAccountDetails(): Promise<void> {
		if (this.entityType === 'IRA' && this.iraAccountDetails === null) {
			const iraAccountDetails = await getIraAccountDetails();
			this.UPDATE_IRA_ACCOUNT_DETAILS(iraAccountDetails);
		}
	}

	@Action({ rawError: true })
	public async storeJointAccountDetails(force = false): Promise<void> {
		if (!this.jointAccountDetails || force) {
			const jointAccountDetails = await getJointAccountDetails();
			this.UPDATE_JOINT_ACCOUNT_DETAILS(jointAccountDetails);
		}
	}

	@Action({ rawError: true })
	public async storeNotifications(): Promise<void> {
		if (this.notifications.notifications.length === 0 && !app.isThirdPartyLogin) {
			const notifications = await getNotifications();
			this.UPDATE_NOTIFICATIONS(notifications);
		}
	}

	@Action({ rawError: true })
	public async updateNotificationsView(): Promise<void> {
		const timestamp = this.notifications.generatedTimestamp;
		await updateNotifications({ timestamp });
		this.UPDATE_NOTIFICATIONS_LAST_VIEWED(timestamp);
	}

	@Action({ rawError: true })
	public async storePositions(): Promise<void> {
		if (this.positions === null) {
			const positions = await getPositionsTable();
			this.UPDATE_POSITIONS(positions);
			setDatadogUserAUM(positions.totalAccountValue);
		}
	}

	@Action({ rawError: true })
	public async refreshPositionsAndPerformance(): Promise<void> {
		const performancePortfolioStore = usePerformancePortfolioStore();
		const modularDashboardStore = useModularDashboardStore();

		const positions = await getPositionsTable();
		this.UPDATE_POSITIONS(positions);

		const performance = await getPerformanceData();
		this.UPDATE_PERFORMANCE(performance);

		await modularDashboardStore.$reset();

		performancePortfolioStore.$reset();
	}

	@Action({ rawError: true })
	public async storePerformance(): Promise<void> {
		if (this.performance === null) {
			const performance = await getPerformanceData();
			this.UPDATE_PERFORMANCE(performance);
		}
	}

	@Action({ rawError: true })
	public async storeTrustAccountDetails(force = false): Promise<void> {
		if (!this.trustAccountDetails || force) {
			const trustAccountDetailsData = await getTrustAccountDetails();
			this.UPDATE_TRUST_ACCOUNT_DETAILS(trustAccountDetailsData);
		}
	}

	@Action({ rawError: true })
	public async storeNAVReinvestmentPreference(): Promise<void> {
		if (this.navReinvestmentPreference === null) {
			const navReinvestmentPreference = await getNAVReinvestmentPreference();
			this.UPDATE_NAV_REINVESTMENT_PREFERENCE(navReinvestmentPreference);
		}
	}

	@Action({ rawError: true })
	public async storeDripPreference(): Promise<void> {
		if (this.dripPreference === '') {
			const dripPreference = await getDrip();
			this.UPDATE_DRIP_PREFERENCE(dripPreference);
		}
	}

	@Action({ rawError: true })
	public async storeDocumentDeliveryPreference(): Promise<void> {
		if (this.documentDeliveryPreference === null) {
			const preference = await getDocumentDeliveryPreference();
			this.UPDATE_DOCUMENT_DELIVERY_PREFERENCE(preference);
		}
	}

	@Action({ rawError: true })
	public async updateDocumentDeliveryPreference(preference: DocumentDeliveryPreferenceEnum): Promise<void> {
		await updateDocumentDeliveryPreference(preference);
		await user.getUser();
		this.UPDATE_DOCUMENT_DELIVERY_PREFERENCE(preference);
	}

	@Action({ rawError: true })
	public async submitManualBankAccount(payload: NewManualBankAccount): Promise<BankAccount> {
		const linkedAccount = await linkManualBankAccount(payload);
		this.ADD_BANK_ACCOUNT(linkedAccount);
		return linkedAccount;
	}

	@Action({ rawError: true })
	private async updateUserAndEmailRecipients(): Promise<void> {
		await user.getUser();
		await this.storeEmailRecipients(true);
	}

	@Action({ rawError: true })
	public async addOrUpdateEmailRecipient(payload: { recipientId: string; details: EmailRecipient }): Promise<void> {
		if (payload.recipientId) {
			await updateEmailRecipient(payload.recipientId, payload.details);
		} else {
			await addNewEmailRecipient(payload.details);
		}
		await this.updateUserAndEmailRecipients();
	}

	@Action({ rawError: true })
	public async deleteEmailRecipient(recipientId: string): Promise<void> {
		await deleteEmailRecipient(recipientId);
		await this.updateUserAndEmailRecipients();
	}

	@Action({ rawError: true })
	public async updateNickname(newNickname: string): Promise<void> {
		this.UPDATE_NICKNAME(newNickname);
		await updateNickname(newNickname);
	}

	@Action({ rawError: true })
	public async deleteNickname(): Promise<void> {
		this.UPDATE_NICKNAME(null);
		await deleteNickname();
	}

	@Action({ rawError: true })
	public async deleteRecurringInvestmentSchedule(): Promise<void> {
		await deleteRecurringInvestmentSchedule();
		this.UPDATE_AUTO_INVEST_SCHEDULE(null);
		this.UPDATE_HAS_ACTIVE_RECURRING_INVESTMENT_SCHEDULE(false);
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_ADDITIONAL_INVESTMENT_ELIGIBILITY('ENTITY_MISSING_INFORMATION');
		this.UPDATE_CURRENT_RIA_PLAN_NAME('');
		this.UPDATE_CURRENT_RIA_PLAN_INVESTMENT_STRATEGY(null);
		this.UPDATE_CURRENT_SELECTED_RIA_PLAN_ID('');
		this.UPDATE_DRIP_ENABLED(false);
		this.UPDATE_ELIGIBLE_FOR_DRIP(false);
		this.UPDATE_ELIGIBLE_FOR_INVESTOR_GOAL(false);
		this.UPDATE_ELIGIBLE_FOR_RECURRING_INVESTMENT(false);
		this.UPDATE_ELIGIBLE_FOR_SHAREHOLDER_VOTE(false);
		this.UPDATE_ENTITY_TYPE('INDIVIDUAL');
		this.UPDATE_ENTITY_TYPE_LABEL('');
		this.UPDATE_EXTERNAL_ID('');
		this.UPDATE_FEATURE_ELIGIBILITY({
			advancedReporting: 'DISABLED',
			customAllocation: 'DISABLED',
			directInvest: 'DISABLED'
		});
		this.UPDATE_FREEZE_REASON('');
		this.UPDATE_HAS_ACTIVE_RECURRING_INVESTMENT_SCHEDULE(false);
		this.UPDATE_HAS_ADVISORY_SERVICES(false);
		this.UPDATE_HAS_EVER_OWNED_IPO_SHARES(false);
		this.UPDATE_HAS_LEGACY_INVESTMENTS(false);
		this.UPDATE_INVESTMENT_ENTITY_ID('');
		this.UPDATE_INVESTMENT_ENTITY_NAME('');
		this.UPDATE_INVESTOR_GOAL_EXISTS(false);
		this.UPDATE_IRA_ENTITY_METADATA({
			isMtcIra: false,
			isIraOnboardingFinished: false,
			iraType: null,
			iraTypeLabel: ''
		});
		this.UPDATE_IS_FROZEN(false);
		this.UPDATE_IS_IRA_ENTITY(false);
		this.UPDATE_IS_REIT_INVESTOR(false);
		this.UPDATE_IS_VERIFIED_ACCREDITED(false);
		this.UPDATE_NICKNAME('');
		this.UPDATE_RIA_PLAN(null);
		this.UPDATE_SHOW_BROWSE_MENU_ITEM(false);
		this.UPDATE_SHOW_TAX_CENTER(false);
		this.UPDATE_USER_REFERRAL_ELIGIBILITY_STATUS('INELIGIBLE');
		this.UPDATE_ADDITIONAL_EMAIL_RECIPIENTS([]);
		this.UPDATE_AUTO_INVEST_SCHEDULE(null);
		this.UPDATE_BANK_ACCOUNTS([]);
		this.UPDATE_PAYMENT_METHODS(null);
		this.UPDATE_POSITIONS(null);
		this.UPDATE_PERFORMANCE(null);
		this.UPDATE_JOINT_ACCOUNT_DETAILS(null);
		this.UPDATE_TRUST_ACCOUNT_DETAILS(null);
		this.UPDATE_IRA_ACCOUNT_DETAILS(null);
		this.UPDATE_ENTITY_ACCOUNT_DETAILS(null);
		this.UPDATE_BENEFICIARIES([]);
	}

	@Mutation
	public UPDATE_ADDITIONAL_INVESTMENT_ELIGIBILITY(eligibility: AdditionalInvestmentEligibility): void {
		this.additionalInvestmentEligibility = eligibility;
	}

	@Mutation
	public UPDATE_AUTO_INVEST_SCHEDULE(autoInvestSchedule: AutoInvestSchedule | null): void {
		this.autoInvestSchedule = autoInvestSchedule;
	}

	@Mutation
	public UPDATE_BANK_ACCOUNTS(bankAccounts: BankAccount[]): void {
		this.bankAccounts = bankAccounts;
	}

	@Mutation
	public UPDATE_PAYMENT_METHODS(paymentMethods: PaymentMethods | null): void {
		this.paymentMethods = paymentMethods;
	}

	@Mutation
	public UPDATE_BENEFICIARIES(beneficiaries: Array<Beneficiary>): void {
		this.beneficiaries = beneficiaries;
	}

	@Mutation
	public ADD_BANK_ACCOUNT(bankAccount: BankAccount): void {
		this.bankAccounts.push(bankAccount);
	}

	@Mutation
	public ADD_PAYMENT_METHOD(paymentMethod: PaymentMethod): void {
		if (isBankPaymentMethod(paymentMethod)) {
			this.paymentMethods?.bankAccounts.push(paymentMethod);
		}
	}

	@Mutation
	public UPDATE_ENTITY_ACCOUNT_DETAILS(entityAccountDetails: EntityAccountDetails | null): void {
		this.entityAccountDetails = entityAccountDetails;
	}

	@Mutation
	public UPDATE_IRA_ACCOUNT_DETAILS(iraAccountDetails: IraAccountDetails | null): void {
		this.iraAccountDetails = iraAccountDetails;
	}

	@Mutation
	public UPDATE_NOTIFICATIONS(notifications: NotificationResponse): void {
		this.notifications = notifications;
	}

	@Mutation
	public UPDATE_NOTIFICATIONS_LAST_VIEWED(timestamp: string): void {
		this.notifications.lastNotificationView = timestamp;
	}

	@Mutation
	public UPDATE_POSITIONS(positions: Positions | null): void {
		this.positions = positions;
	}

	@Mutation
	public UPDATE_PERFORMANCE(performance: Performance | null): void {
		this.performance = performance;
	}

	@Mutation
	public UPDATE_RIA_PLAN(riaPlan: RiaPlan | null): void {
		this.riaPlan = riaPlan;
	}

	@Mutation
	public UPDATE_CURRENT_RIA_PLAN_NAME(name: string): void {
		this.currentRiaPlanName = name;
	}

	@Mutation
	public UPDATE_CURRENT_RIA_PLAN_INVESTMENT_STRATEGY(strategy: InvestmentStrategy | null): void {
		this.currentRiaPlanInvestmentStrategy = strategy;
	}

	@Mutation
	public UPDATE_CURRENT_SELECTED_RIA_PLAN_ID(name: string): void {
		this.currentSelectedRiaPlanId = name;
	}

	@Mutation
	public UPDATE_DOCUMENT_DELIVERY_PREFERENCE(preference: DocumentDeliveryPreferenceEnum): void {
		this.documentDeliveryPreference = preference;
	}

	@Mutation
	public UPDATE_DRIP_ENABLED(dripEnabled: boolean): void {
		this.dripEnabled = dripEnabled;
	}

	@Mutation
	public UPDATE_DRIP_PREFERENCE(dripPreference: DripStatus): void {
		this.dripPreference = dripPreference;
	}

	@Mutation
	public UPDATE_NAV_REINVESTMENT_PREFERENCE(preference: NAVReinvestmentPreference): void {
		this.navReinvestmentPreference = preference;
	}

	@Mutation
	public UPDATE_ELIGIBLE_FOR_DRIP(eligibleForDrip: boolean): void {
		this.eligibleForDrip = eligibleForDrip;
	}

	@Mutation
	public UPDATE_ELIGIBLE_FOR_INVESTOR_GOAL(eligible: boolean): void {
		this.eligibleForInvestorGoal = eligible;
	}

	@Mutation
	public UPDATE_ELIGIBLE_FOR_RECURRING_INVESTMENT(eligibleForRecurringInvestment: boolean): void {
		this.eligibleForRecurringInvestment = eligibleForRecurringInvestment;
	}

	@Mutation
	public UPDATE_ELIGIBLE_FOR_SHAREHOLDER_VOTE(eligibleForShareholderVote: boolean): void {
		this.eligibleForShareholderVote = eligibleForShareholderVote;
	}

	@Mutation
	public UPDATE_ENTITY_TYPE(entityType: InvestmentEntityType): void {
		this.entityType = entityType;
	}

	@Mutation
	public UPDATE_ENTITY_TYPE_LABEL(label: string): void {
		this.entityTypeLabel = label;
	}

	@Mutation
	public UPDATE_EXTERNAL_ID(externalId: string): void {
		this.externalId = externalId;
	}

	@Mutation
	public UPDATE_FEATURE_ELIGIBILITY(featureEligibility: FeatureEligibility) {
		this.featureEligibility = featureEligibility;
	}

	@Mutation
	public UPDATE_FREEZE_REASON(freezeReason: string): void {
		this.freezeReason = freezeReason;
	}

	@Mutation
	public UPDATE_HAS_EVER_OWNED_IPO_SHARES(hasEverOwnedIpoShares: boolean): void {
		this.hasEverOwnedIpoShares = hasEverOwnedIpoShares;
	}

	@Mutation
	public UPDATE_HAS_ACTIVE_RECURRING_INVESTMENT_SCHEDULE(hasActiveRecurringInvestmentSchedule: boolean): void {
		this.hasActiveRecurringInvestmentSchedule = hasActiveRecurringInvestmentSchedule;
	}

	@Mutation
	public UPDATE_HAS_ADVISORY_SERVICES(hasAdvisoryServices: boolean): void {
		this.hasAdvisoryServices = hasAdvisoryServices;
	}

	@Mutation
	public UPDATE_HAS_LEGACY_INVESTMENTS(hasLegacyInvestments: boolean): void {
		this.hasLegacyInvestments = hasLegacyInvestments;
	}

	@Mutation
	public UPDATE_INVESTMENT_ENTITY_ID(id: string): void {
		this.investmentEntityId = id;
	}

	@Mutation
	public UPDATE_INVESTMENT_ENTITY_NAME(name: string): void {
		this.investmentEntityName = name;
	}

	@Mutation
	public UPDATE_INVESTOR_GOAL_EXISTS(investorGoalExists: boolean): void {
		this.investorGoalExists = investorGoalExists;
	}

	@Mutation
	public UPDATE_IRA_ENTITY_METADATA(iraEntityMetadata: IraEntityMetadata): void {
		this.iraEntityMetadata = iraEntityMetadata;
	}

	@Mutation
	public UPDATE_IS_FROZEN(isFrozen: boolean): void {
		this.isFrozen = isFrozen;
	}

	@Mutation
	public UPDATE_IS_IRA_ENTITY(isIra: boolean): void {
		this.isIraEntity = isIra;
	}

	@Mutation
	public UPDATE_IS_REIT_INVESTOR(isReitInvestor: boolean): void {
		this.isReitInvestor = isReitInvestor;
	}

	@Mutation
	public UPDATE_IS_VERIFIED_ACCREDITED(acc: boolean): void {
		this.isVerifiedAccredited = acc;
	}

	@Mutation
	public UPDATE_JOINT_ACCOUNT_DETAILS(jointAccountDetails: JointAccountDetails | null): void {
		this.jointAccountDetails = jointAccountDetails;
	}

	@Mutation
	public UPDATE_NICKNAME(nickname: string | null): void {
		this.nickname = nickname ?? '';
	}

	@Mutation
	public UPDATE_SHOW_BROWSE_MENU_ITEM(showBrowseMenu: boolean): void {
		this.showBrowseMenuItem = showBrowseMenu;
	}

	@Mutation
	public UPDATE_SHOW_TAX_CENTER(showTaxCenter: boolean): void {
		this.showTaxCenter = showTaxCenter;
	}

	@Mutation
	public UPDATE_TRUST_ACCOUNT_DETAILS(trustAccountDetails: TrustAccountDetails | null): void {
		this.trustAccountDetails = trustAccountDetails;
	}

	@Mutation
	public UPDATE_USER_REFERRAL_ELIGIBILITY_STATUS(status: UserReferralEligibilityStatus): void {
		this.userReferralEligibilityStatus = status;
	}

	@Mutation
	public UPDATE_ADDITIONAL_EMAIL_RECIPIENTS(recipients: Array<EmailRecipient>): void {
		this.additionalEmailRecipients = recipients;
	}
}

if (!store.hasModule('investmentEntity')) {
	store.registerModule('investmentEntity', InvestmentEntityModule);
}

export const investmentEntity = getModule(InvestmentEntityModule);
