import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { useRedemptionRequestStore } from '@stores/redemption-request';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const redemptionRequestRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/reits/redemptions/request/',
		name: 'redemption-request-REIT',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/redemption-request/redemption-request.vue'),
		beforeEnter: async (to, from, next) => {
			const redemptionRequestStore = useRedemptionRequestStore();

			await redemptionRequestStore.fetchRedemptionRequestInterstitialVariant();
			redemptionRequestStore.redemptionType = 'REIT';
			await redemptionRequestStore.fetchAndStoreMetadata();
			await investmentEntity.storeBankAccounts();
			next();
		},
		redirect: { name: 'redemption-request-start-REIT' },
		children: [
			{
				path: 'start',
				name: 'redemption-request-start-REIT',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/redemption-request/redemption-request-start.vue')
			},
			{
				path: 'form',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				redirect: { name: 'redemption-request-start-REIT' }
			},
			{
				path: 'message',
				name: 'redemption-request-interstitial-REIT',
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					await redemptionRequestStore.fetchRedemptionRequestInterstitialVariant();

					if (redemptionRequestStore.showRedemptionRequestInterstitial) {
						next();
					} else {
						next({ name: 'redemption-request-REIT' });
					}
				},
				component: () => import('@views/redemption-request/redemption-request-interstitial.vue')
			},
			{
				path: 'redemption-preference',
				name: 'redemption-request-preference-REIT',
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (redemptionRequestStore.skipPreferencePage) {
						next({ name: 'redemption-request-form-REIT', replace: true });
					} else if (!redemptionRequestStore.hasRedeemableReits) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/redemption-request/redemption-request-preference.vue')
			},
			{
				path: 'request-form',
				name: 'redemption-request-form-REIT',
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (redemptionRequestStore.redemptionPreference === 'FULL') {
						next({ name: 'redemption-request-review-REIT', replace: true });
					} else if (!redemptionRequestStore.hasRedeemableReits) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/redemption-request/redemption-request-form.vue')
			},
			{
				path: 'review',
				name: 'redemption-request-review-REIT',
				meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (!redemptionRequestStore.hasRedeemableReits || !redemptionRequestStore.hasRequests) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						redemptionRequestStore.storeRedemptionPreview();
						redemptionRequestStore.showProceeds = false;
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-review.vue')
			},
			{
				path: 'survey',
				name: 'redemption-request-survey-REIT',
				meta: { step: 6, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (redemptionRequestStore.redemptionPreview === null) {
						next({ name: 'redemption-request-review-REIT', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-survey.vue')
			},
			{
				path: 'acknowledgements',
				name: 'redemption-request-agreements-REIT',
				meta: { step: 7, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (!redemptionRequestStore.completedSurvey) {
						next({ name: 'redemption-request-survey-REIT', replace: true });
					} else if (!redemptionRequestStore.hasRequests) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-agreements.vue')
			},
			{
				path: 'success',
				name: 'redemption-request-success-REIT',
				meta: { step: 8, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (
						redemptionRequestStore.redemptionPreview === null ||
						redemptionRequestStore.redemptionRequestGroupId === ''
					) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-success.vue')
			}
		]
	},
	{
		path: '/ipo/redemptions/request/',
		name: 'redemption-request-IPO',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/redemption-request/redemption-request.vue'),
		beforeEnter: async (to, from, next) => {
			const redemptionRequestStore = useRedemptionRequestStore();

			redemptionRequestStore.redemptionType = 'IPO';
			await redemptionRequestStore.fetchAndStoreMetadata();
			await investmentEntity.storeBankAccounts();
			next();
		},
		redirect: { name: 'redemption-request-start-IPO' },
		children: [
			{
				path: 'start',
				name: 'redemption-request-start-IPO',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/redemption-request/redemption-request-start.vue')
			},
			{
				path: 'form',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				redirect: { name: 'redemption-request-start-IPO' }
			},
			{
				path: 'message',
				name: 'redemption-request-interstitial-IPO',
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					await redemptionRequestStore.fetchRedemptionRequestInterstitialVariant();

					if (redemptionRequestStore.showRedemptionRequestInterstitial) {
						next();
					} else {
						next({ name: 'redemption-request-IPO' });
					}
				},
				component: () => import('@views/redemption-request/redemption-request-interstitial.vue')
			},
			{
				path: 'redemption-preference',
				name: 'redemption-request-preference-IPO',
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (redemptionRequestStore.skipPreferencePage) {
						next({ name: 'redemption-request-form-IPO', replace: true });
					} else if (!redemptionRequestStore.hasRedeemableReits) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/redemption-request/redemption-request-preference.vue')
			},
			{
				path: 'request-form',
				name: 'redemption-request-form-IPO',
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (redemptionRequestStore.redemptionPreference === 'FULL') {
						next({ name: 'redemption-request-review-IPO', replace: true });
					} else if (!redemptionRequestStore.hasRedeemableReits) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/redemption-request/redemption-request-form.vue')
			},
			{
				path: 'review',
				name: 'redemption-request-review-IPO',
				meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (!redemptionRequestStore.hasRedeemableReits || !redemptionRequestStore.hasRequests) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						await redemptionRequestStore.storeRedemptionPreview();
						redemptionRequestStore.showProceeds = false;
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-review.vue')
			},
			{
				path: 'survey',
				name: 'redemption-request-survey-IPO',
				meta: { step: 6, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (redemptionRequestStore.redemptionPreview === null) {
						next({ name: 'redemption-request-review-IPO', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-survey.vue')
			},
			{
				path: 'acknowledgements',
				name: 'redemption-request-agreements-IPO',
				meta: { step: 7, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (!redemptionRequestStore.completedSurvey) {
						next({ name: 'redemption-request-survey-IPO', replace: true });
					} else if (!redemptionRequestStore.hasRequests) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequestStore.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-agreements.vue')
			},
			{
				path: 'success',
				name: 'redemption-request-success-IPO',
				meta: { step: 8, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const redemptionRequestStore = useRedemptionRequestStore();

					if (
						redemptionRequestStore.redemptionPreview === null ||
						redemptionRequestStore.redemptionRequestGroupId === ''
					) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/redemption-request/redemption-request-success.vue')
			}
		]
	}
];
export default redemptionRequestRoutes;
