<template>
	<BaseLink
		:link="link"
		classes="display-flex justify-content-space-between align-items-center gap-100 zero-percent-asset-class"
		mixpanel-context="Dashboard Portfolio Widget"
		:mixpanel-target="allocation.assetClass.name"
		:mixpanel-custom-properties="{ eligibility: allocation.directInvestmentEligibility }"
		@mouseenter="isHovering = true"
		@mouseleave="isHovering = false"
	>
		<div class="display-flex align-items-center gap-100">
			<SimplePercentageWheel :percentage="percentage('0')" chart-size="40" />
			<div class="display-flex flex-direction-column align-self-center">
				<div class="display-flex align-items-center">
					<div class="body-md font-weight-bold asset-class-label">{{ assetClassLabel }}</div>
				</div>
				<div v-if="assetClassDescription" class="body-xs line-clamp-2">{{ assetClassDescription }}</div>
			</div>
		</div>
		<span class="text-color-content-secondary">
			<BaseSvgIcon v-if="displayChevron" role="presentation" class="arrow" name="chevron" dir="right" />
		</span>
	</BaseLink>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { isMobile } from '@utils/composables';
import { Link } from 'types/layout';
import { ModularDashboardPortfolioOverviewModule } from 'types/modular-dashboard/custom';
import { percentage } from '@filters/shared-filters';
import SimplePercentageWheel from '@charts/simple-percentage-wheel.vue';

interface Props {
	allocation: ModularDashboardPortfolioOverviewModule;
}

const props = defineProps<Props>();

const isHovering = ref(false);

const link = computed<Link>(() => ({
	router: 'view-offering-details',
	params: {
		offeringId: props.allocation.fundId
	}
}));

const isEligibleToInvest = computed((): boolean => props.allocation.directInvestmentEligibility === 'ELIGIBLE');

const assetClassLabel = computed((): string => {
	const label = props.allocation.assetClass.label;
	return isEligibleToInvest.value ? `+ Add ${label}` : label;
});

const assetClassDescription = computed((): string | undefined => {
	return isEligibleToInvest.value ? props.allocation.assetTypeDescription : 'Learn more';
});

const displayChevron = computed((): boolean => {
	return isMobile.value;
});
</script>

<style lang="scss">
@use '../../styles/constants/colors' as *;

.zero-percent-asset-class {
	color: token('content-primary');
	padding: 8px;
	border: 1px solid transparent;
	min-height: 64px;

	&:hover {
		color: token('content-primary');
		background-color: token('bg-secondary');
		border-color: gray(20);
		border-radius: 4px;
	}
}
</style>
