<template>
	<StandardModal
		data-context="Invitation Program Modal"
		modal-size-classes="col-6-md col-offset-3-md"
		@modal-close="close"
	>
		<template #title>
			<h2 class="heading-5">Invite friends disclaimer</h2>
		</template>
		<template #content>
			<p class="body-md"
				>The {{ currency(promoAmount, 0) }} gift is provided in the form of a voucher redeemable for shares and
				may be subject to applicable terms, conditions, and eligibility requirements set forth by Fundrise.
				<BaseLink class="text-decoration-underline text-color-current-color" :link="termsLink" @click="close" />
			</p>
		</template>
		<template #button-section>
			<BaseButtonGroup classes="mt-150">
				<template #primary>
					<BaseButton class="button button-default" @click="close">Got it</BaseButton>
				</template>
			</BaseButtonGroup>
		</template>
	</StandardModal>
</template>
<script setup lang="ts">
import { currency } from '@filters/shared-filters';
import { Link } from 'types/layout';
import StandardModal from '@components/modals/standard-modal.vue';

interface Props {
	promoAmount: string;
}

defineProps<Props>();

interface Emits {
	(e: 'modal-close'): void;
}

const termsLink: Link = {
	text: 'View full terms and conditions',
	router: 'referral-terms-conditions'
};

const emits = defineEmits<Emits>();

function close(): void {
	emits('modal-close');
}
</script>
