import { BankAccount, NewManualBankAccount } from 'types/payment';
import {
	checkRolloverCheckDate,
	checkRolloverSourceEligibilityAddFunds,
	checkSimpleIraFirstContributionDateAddFunds,
	checkSourceEligibilityAddFunds,
	completeIraRepeatInvestment,
	completeOnboarding,
	getContactDocuments as getContactDocumentsAPI,
	getEntryData as getEntryDataAPI,
	getIncompleteOrder as getIncompleteOrderAPI,
	getIncompleteRollover as getIncompleteRolloverAPI,
	getIraBankAccounts,
	getIraFeesAndContributionLimits as getIraFeesAndContributionLimitsAPI,
	getIraFundingType,
	getIraInfoForRepeatInvestment as getIraInfoForRepeatInvestmentAPI,
	getPendingRollover as getPendingRolloverAPI,
	getVerifyFlowInfo as getVerifyFlowInfoAPI,
	resetIra as resetIraAPI,
	resubmitPendingRollover,
	sendMtcCode,
	submitAccountType as submitAccountTypeAPI,
	submitAcknowledgedFees as submitAcknowledgedFeesAPI,
	submitAcknowledgedRollover,
	submitAddFundsOrderGroup,
	submitAddFundsRollover,
	submitAmount,
	submitContactDocument,
	submitDripPreference as submitDripPreferenceAPI,
	submitEmailRequest,
	submitFundingType as submitFundingTypeAPI,
	submitMtcRolloverSignatures,
	submitMtcSignatures,
	submitOnboardingOrderGroup,
	submitOnboardingRollover,
	submitPaymentMethod as submitPaymentMethodAPI,
	submitPersonalInfo as submitPersonalInfoAPI,
	submitPhoneVerification as submitPhoneVerificationAPI,
	submitPlan as submitPlanAPI,
	submitRolloverAccountType,
	submitRolloverCheckDate as submitRolloverCheckDateAPI,
	submitRolloverMethod as submitRolloverMethodAPI,
	submitSelectedNumber as submitSelectedNumberAPI,
	submitSimpleIraFirstContributionDate as submitSimpleIraFirstContributionDateAPI,
	submitSourceAccountType as submitSourceAccountTypeAPI,
	submitTransferAccountInfo as submitTransferAccountInfoAPI,
	submitTransferAmount as submitTransferAmountAPI,
	uploadVerificationManual
} from '@api/ira';
import { computed, ref } from 'vue';
import {
	DtcParticipant,
	IraAccountType,
	IraAddFundsExistingOrderGroupParams,
	IraAddFundsNewContributionOrderGroupParams,
	IraAddFundsTransferOrderGroupParams,
	IraCompleteRolloverOrderGroupParams,
	IraData,
	IraDocumentSubmission,
	IraDocumentType,
	IraFeesAndContributionLimits,
	IraFlow,
	IraFundingType,
	IraOtpDeliveryType,
	IraPersonalInformation,
	IraRolloverTypeWithLabel,
	IraStateDeterminerStep,
	IraSubmitRolloverParams,
	IraTypeWithLabel,
	IraVerificationDocument,
	IraVerificationStatus,
	MillenniumVerificationType,
	RolloverMethod,
	RolloverType,
	SourceAccountTypeSubmission
} from 'types/ira';
import { placeFirstOrder, placeOrder as placeOrderAPI } from '@api/checkout';
import { defineStore } from 'pinia';
import { DripStatus } from 'types/investment-entity';
import { getReitOfferingVintage } from '@api/reit-offering-vintage';
import { getRepeatOfferingInvestmentData } from '@api/add-funds';
import { getRiaPlanById } from '@api/ria';
import { investmentEntity } from '@store/modules/investment-entity';
import { PhoneNumber } from 'types/user';
import { redirect } from '@utils/client';
import { ReitOfferingVintage } from 'types/reit-offering-vintage';
import { RiaPlan } from 'types/plan';
import { user } from '@store/modules/user';

export const useIraStore = defineStore('ira', () => {
	const stateDeterminerStep = ref<IraStateDeterminerStep | null>(null);
	const dataRetrieved = ref(false);
	const personalInfo = ref<IraPersonalInformation>({
		firstName: '',
		lastName: '',
		primaryAddress: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: ''
		},
		mailingAddress: null
	});

	const currentPhone = ref<PhoneNumber | null>(null);
	const fundingType = ref<IraFundingType>('CONTRIBUTION');
	const accountType = ref<IraAccountType>('TRADITIONAL_IRA');
	const accountTypeLabel = ref('Traditional');
	const dripPreference = ref<boolean | null>(null);
	const plan = ref<RiaPlan | null>(null);
	const reitOfferingVintage = ref<ReitOfferingVintage | null>(null);
	const iraBankAccounts = ref<Array<BankAccount>>([]);
	const selectedPaymentMethod = ref<BankAccount | null>(null);
	const acknowledgedFees = ref(false);
	const orderGroupAcknowledged = ref(false);
	const selectedAmount = ref('');
	const selectedTaxYear = ref('');
	const editRedirect = ref('');
	const reitInvestmentOrderGroupId = ref('');
	const contactDocument = ref<IraDocumentType | null>(null);
	const flow = ref<IraFlow>('SIGN-UP');
	const isFunded = ref(false);
	const legacyIra = ref(false);
	const verificationStatus = ref<IraVerificationStatus | null>(null);
	const mtcContactCreated = ref(false);
	const addFundsPlanSelectionRequired = ref(false);
	const addFundsDripPreferenceRequired = ref(false);
	const addFundsEdirect = ref(false);

	// Transfers specific
	const sourceAccountType = ref<IraAccountType | null>(null);
	const sourceAccountTypeLabel = ref('');
	const simpleIraFirstContributionDate = ref('');
	const simpleIraFirstContributionDateValid = ref<boolean | null>(null);
	const sourceCustodianName = ref('');
	const sourceDtcParticipantNumber = ref('');
	const sourceDtcParticipant = ref<DtcParticipant | null>(null);
	const sourceAccountNumber = ref('');

	// Contribution error specific
	const maxContributionLimit = ref<string | null>(null);

	// Rollover specific
	const rolloverType = ref<RolloverType | null>(null);
	const rolloverTypeLabel = ref('');
	const rolloverMethod = ref<RolloverMethod | null>(null);
	const rolloverCheckDate = ref('');
	const rolloverCheckDateValid = ref<boolean | null>(null);
	const rolloverPlanAlreadySet = ref(false);
	const rolloverDripAlreadySet = ref(false);
	const rolloverAgreementsSubmitted = ref<boolean | null>(null);
	const initialRolloverAmount = ref('');
	const rolloverInitiationDate = ref('');
	const rolloverConfirmedThreshold = ref<string | null>(null);

	// General IRA fee and contribution limit data
	const taxYear = ref<string | null>(null);
	const contributionLimit = ref<string | null>(null);
	const seniorContributionLimit = ref<string | null>(null);
	const feeAmount = ref<string | null>('null');
	const annualThresholdLimit = ref<string | null>(null);
	const accountSizeThresholdLimit = ref<string | null>(null);

	const isTransfer = computed(() => fundingType.value === 'TRANSFER');
	const isRollover = computed(() => fundingType.value === 'ROLLOVER');

	async function getEntryData(): Promise<void> {
		const entryData = await getEntryDataAPI();
		setEntryData(entryData);
	}

	async function getIncompleteOrder(): Promise<void> {
		const incompleteOrder = await getIncompleteOrderAPI();
		dataRetrieved.value = true;

		if (incompleteOrder.completeOnboardingOrder) {
			flow.value = 'COMPLETE-ORDER-SIGNUP';
		} else {
			flow.value = 'COMPLETE-ORDER-ADD-FUNDS';
		}
		if (incompleteOrder.reitInvestmentOrderGroupId) {
			reitInvestmentOrderGroupId.value = incompleteOrder.reitInvestmentOrderGroupId;
		}

		orderGroupAcknowledged.value = true;
	}

	async function getIncompleteRollover(): Promise<void> {
		const incompleteData = await getIncompleteRolloverAPI();
		dataRetrieved.value = true;
		flow.value = 'COMPLETE-ROLLOVER';
		fundingType.value = 'ROLLOVER';
		initialRolloverAmount.value = incompleteData.millenniumFundingDto.amount;
		updateDripPreferenceByType(
			incompleteData.iraEntityDto.reinvestmentPreferenceDto?.reinvestmentPreference ?? null
		);

		if (incompleteData.iraEntityDto.riaPlanDto) {
			plan.value = incompleteData.iraEntityDto.riaPlanDto;
			rolloverPlanAlreadySet.value = true;
		}
		if (dripPreference.value !== null) {
			rolloverDripAlreadySet.value = true;
		}
		if (incompleteData.millenniumFundingDto.sourceAccountType) {
			updateSourceAccountType(incompleteData.millenniumFundingDto.sourceAccountType);
		}
		if (incompleteData.iraEntityDto.accountType) {
			updateAccountType(incompleteData.iraEntityDto.accountType);
		}

		if (incompleteData.millenniumFundingDto.rolloverInitiationDate) {
			rolloverInitiationDate.value = incompleteData.millenniumFundingDto.rolloverInitiationDate;
		}
		if (incompleteData.millenniumFundingDto.rolloverReitOfferingVintageDto) {
			rolloverPlanAlreadySet.value = true;
			rolloverDripAlreadySet.value = true;
			addFundsEdirect.value = true;
			reitOfferingVintage.value = incompleteData.millenniumFundingDto.rolloverReitOfferingVintageDto;
		}
		if (incompleteData.millenniumFundingDto.rolloverConfirmedThreshold) {
			rolloverConfirmedThreshold.value = incompleteData.millenniumFundingDto.rolloverConfirmedThreshold;
		}
	}

	async function getPendingRollover(): Promise<void> {
		await getPendingRolloverAPI();
		dataRetrieved.value = true;
		fundingType.value = 'ROLLOVER';
		flow.value = 'REACKNOWLEDGE-ROLLOVER';
		orderGroupAcknowledged.value = true;
	}

	async function getVerifyFlowInfo(): Promise<void> {
		const verifyFlowInfo = await getVerifyFlowInfoAPI();
		dataRetrieved.value = true;

		if (verifyFlowInfo.verificationStatus) {
			verificationStatus.value = verifyFlowInfo.verificationStatus;
		}
	}

	async function resetIra(): Promise<string> {
		const iraData = await resetIraAPI();
		setEntryData(iraData);

		selectedAmount.value = '';
		selectedTaxYear.value = '';
		updateSourceAccountType(null);
		dripPreference.value = null;
		return 'ira-funding';
	}

	async function resetAddFunds(): Promise<string> {
		selectedAmount.value = '';
		fundingType.value = 'CONTRIBUTION';
		selectedPaymentMethod.value = null;
		selectedTaxYear.value = '';
		sourceAccountNumber.value = '';
		sourceCustodianName.value = '';
		sourceDtcParticipantNumber.value = '';
		sourceDtcParticipant.value = null;
		sourceAccountType.value = null;
		reitInvestmentOrderGroupId.value = '';
		reitOfferingVintage.value = null;
		addFundsEdirect.value = false;
		orderGroupAcknowledged.value = false;

		return 'ira-add-funds-funding';
	}

	async function resetAll(): Promise<void> {
		selectedAmount.value = '';
		fundingType.value = 'CONTRIBUTION';
		selectedPaymentMethod.value = null;
		selectedTaxYear.value = '';
		sourceAccountNumber.value = '';
		sourceCustodianName.value = '';
		sourceDtcParticipantNumber.value = '';
		sourceDtcParticipant.value = null;
		sourceAccountType.value = null;
		reitInvestmentOrderGroupId.value = '';
		reitOfferingVintage.value = null;
		addFundsEdirect.value = false;
		orderGroupAcknowledged.value = false;
		plan.value = null;
		dripPreference.value = null;
	}

	async function resetFlow(): Promise<string> {
		let dest = '';
		if (flow.value === 'SIGN-UP') {
			dest = await resetIra();
		} else {
			dest = await resetAddFunds();
		}
		return dest;
	}

	function setEntryData(entryData: IraData): void {
		dataRetrieved.value = true;
		stateDeterminerStep.value = entryData.onboardingStatus;
		personalInfo.value = entryData.personalInfo;
		orderGroupAcknowledged.value = entryData.iraOnboardingMetadata.orderGroupAcknowledged;
		acknowledgedFees.value = entryData.iraOnboardingMetadata.feesAcknowledged;
		mtcContactCreated.value = entryData.iraEntityInfo.mtcContactCreated;
		if (entryData.iraOnboardingMetadata.rolloverAgreementsSubmitted) {
			rolloverAgreementsSubmitted.value = entryData.iraOnboardingMetadata.rolloverAgreementsSubmitted;
		}
		if (entryData.iraOnboardingMetadata.selectedAmount) {
			selectedAmount.value = entryData.iraOnboardingMetadata.selectedAmount;
		}
		if (entryData.iraOnboardingMetadata.selectedTaxYear) {
			selectedTaxYear.value = entryData.iraOnboardingMetadata.selectedTaxYear;
		}
		if (entryData.iraOnboardingMetadata.fundingType) {
			fundingType.value = entryData.iraOnboardingMetadata.fundingType;
		}
		if (entryData.iraEntityInfo.accountType) {
			updateAccountType(entryData.iraEntityInfo.accountType);
		}
		if (entryData.iraEntityInfo.verificationStatus) {
			verificationStatus.value = entryData.iraEntityInfo.verificationStatus;
		}
		if (entryData.iraOnboardingMetadata.selectedPhoneNumberDto) {
			currentPhone.value = entryData.iraOnboardingMetadata.selectedPhoneNumberDto;
		}
		if (entryData.iraOnboardingMetadata.dripEnabled !== undefined) {
			dripPreference.value = entryData.iraOnboardingMetadata.dripEnabled;
		}
		if (entryData.iraOnboardingMetadata.riaPlanDto) {
			plan.value = entryData.iraOnboardingMetadata.riaPlanDto;
		}
		if (entryData.iraOnboardingMetadata.selectedPaymentMethodOptionDto !== undefined) {
			selectedPaymentMethod.value = entryData.iraOnboardingMetadata.selectedPaymentMethodOptionDto;
		}
		if (entryData.iraOnboardingMetadata.reitInvestmentOrderGroupId) {
			reitInvestmentOrderGroupId.value = entryData.iraOnboardingMetadata.reitInvestmentOrderGroupId;
		}
		if (entryData.iraOnboardingMetadata.sourceAccountType) {
			updateSourceAccountType(entryData.iraOnboardingMetadata.sourceAccountType);
		}
		if (entryData.iraOnboardingMetadata.rolloverType) {
			updateRolloverType(entryData.iraOnboardingMetadata.rolloverType);
		}
		if (entryData.iraOnboardingMetadata.rolloverMethod) {
			rolloverMethod.value = entryData.iraOnboardingMetadata.rolloverMethod;
		}
		if (entryData.iraOnboardingMetadata.indirectRolloverChequeDate) {
			rolloverCheckDate.value = entryData.iraOnboardingMetadata.indirectRolloverChequeDate;
		}
		if (entryData.iraOnboardingMetadata.indirectRolloverChequeDateValid !== undefined) {
			rolloverCheckDateValid.value = entryData.iraOnboardingMetadata.indirectRolloverChequeDateValid;
		}
		if (entryData.iraOnboardingMetadata.simpleIraFirstContributionDate) {
			simpleIraFirstContributionDate.value = entryData.iraOnboardingMetadata.simpleIraFirstContributionDate;
		}
		if (entryData.iraOnboardingMetadata.simpleIraFirstContributionDateValid !== undefined) {
			simpleIraFirstContributionDateValid.value =
				entryData.iraOnboardingMetadata.simpleIraFirstContributionDateValid;
		}
		if (entryData.iraOnboardingMetadata.sourceCustodianName) {
			sourceCustodianName.value = entryData.iraOnboardingMetadata.sourceCustodianName;
		}
		if (entryData.iraOnboardingMetadata.sourceDtcParticipantNumber) {
			sourceDtcParticipantNumber.value = entryData.iraOnboardingMetadata.sourceDtcParticipantNumber;
		}
		if (entryData.iraOnboardingMetadata.sourceAccountNumber) {
			sourceAccountNumber.value = entryData.iraOnboardingMetadata.sourceAccountNumber;
		}
		if (entryData.iraOnboardingMetadata.sourceDtcParticipant) {
			sourceDtcParticipant.value = entryData.iraOnboardingMetadata.sourceDtcParticipant;
		}
	}

	async function getIraInfoForRepeatInvestment(reitOfferingVintageId: string | null): Promise<void> {
		const iraEntityInfo = await getIraInfoForRepeatInvestmentAPI();
		dataRetrieved.value = true;
		isFunded.value = iraEntityInfo.funded;
		legacyIra.value = iraEntityInfo.legacyIra;
		mtcContactCreated.value = iraEntityInfo.mtcContactCreated;
		updateDripPreferenceByType(iraEntityInfo.reinvestmentPreferenceDto?.reinvestmentPreference ?? null);

		if (iraEntityInfo.riaPlanDto) {
			plan.value = iraEntityInfo.riaPlanDto;
		} else {
			addFundsPlanSelectionRequired.value = true;
		}
		if (iraEntityInfo.accountType) {
			updateAccountType(iraEntityInfo.accountType);
		}
		if (dripPreference.value === null) {
			addFundsDripPreferenceRequired.value = true;
		}

		if (reitOfferingVintageId !== null && reitOfferingVintageId !== undefined) {
			const reitOfferingVintageLocal = await getReitOfferingVintage(reitOfferingVintageId);
			reitOfferingVintage.value = reitOfferingVintageLocal;
			addFundsPlanSelectionRequired.value = false;
			addFundsDripPreferenceRequired.value = false;
			addFundsEdirect.value = true;

			const repeatOfferingInvestmentMetadata = await getRepeatOfferingInvestmentData(reitOfferingVintageId);
			if (!repeatOfferingInvestmentMetadata.eligibleToInvestInOffering) {
				throw new Error('EDIRECT_INELIGIBLE');
			}
		}
	}

	async function getBankAccounts(): Promise<Array<BankAccount>> {
		const bankAccounts = await getIraBankAccounts();
		iraBankAccounts.value = bankAccounts;
		return iraBankAccounts.value;
	}

	async function getContactDocuments(): Promise<IraDocumentType> {
		if (!contactDocument.value) {
			const contactDoc = await getContactDocumentsAPI();
			contactDocument.value = contactDoc;
			return contactDoc;
		}

		return contactDocument.value;
	}

	async function storeIraFundingType(): Promise<void> {
		const fundingTypeLocal = await getIraFundingType();
		if (fundingTypeLocal) {
			fundingType.value = fundingTypeLocal;
		}
	}

	async function submitPersonalInfo(): Promise<void> {
		await submitPersonalInfoAPI(personalInfo.value);
	}

	async function submitSelectedNumber(newNumber: string | PhoneNumber): Promise<PhoneNumber> {
		const submissionObject =
			typeof newNumber === 'string' ? { newPhoneNumber: newNumber } : { phoneNumberId: newNumber.id };
		const phoneNumber = await submitSelectedNumberAPI(submissionObject);
		currentPhone.value = phoneNumber;
		user.ADD_PHONE_NUMBER(phoneNumber);
		return phoneNumber;
	}

	async function submitPlan(planId: string): Promise<string> {
		let dest = '';

		if (flow.value === 'SIGN-UP') {
			const planLocal = await submitPlanAPI(planId);
			plan.value = planLocal;
			dest = fundingType.value === 'CONTRIBUTION' ? 'ira-funding-source' : 'ira-transfer-fees';
		} else if (flow.value === 'COMPLETE-ROLLOVER') {
			const planLocal = await getRiaPlanById(planId);
			plan.value = planLocal;

			dest = rolloverDripAlreadySet.value ? 'ira-complete-rollover-confirm' : 'ira-complete-rollover-drip';
		} else {
			const planLocal = await getRiaPlanById(planId);
			plan.value = planLocal;
			if (addFundsDripPreferenceRequired.value) {
				dest = 'ira-add-funds-drip';
			} else {
				dest = fundingType.value === 'CONTRIBUTION' ? 'ira-add-funds-funding-source' : 'ira-add-funds-confirm';
			}
		}

		return editRedirect.value || dest;
	}

	async function submitConfirmation(documentId: string): Promise<string> {
		let dest = flow.value === 'SIGN-UP' ? 'ira-agreements' : 'ira-add-funds-agreements';
		if ((flow.value === 'SIGN-UP' || legacyIra.value) && !mtcContactCreated.value) {
			await submitContactDocument(documentId);
			mtcContactCreated.value = true;
		}

		if (fundingType.value === 'ROLLOVER') {
			await submitAcknowledgedRollover(true);
		}

		if (fundingType.value !== 'ROLLOVER') {
			await submitOrderGroup();
		}

		if (fundingType.value === 'ROLLOVER' && flow.value === 'ADD-FUNDS' && !legacyIra.value) {
			await submitRollover();
			dest = 'ira-add-funds-success';
		}

		return dest;
	}

	async function submitCompleteRolloverConfirm(): Promise<string> {
		const params: IraCompleteRolloverOrderGroupParams = {
			amount: selectedAmount.value,
			fundingType: fundingType.value
		};

		if (reitOfferingVintage.value) {
			params.reitOfferingVintageId = reitOfferingVintage.value.reitOfferingVintageId;
		} else if (plan.value) {
			params.riaPlanId = plan.value.id;
		}

		const reitInvestmentOrderGroupIdLocal = await submitAddFundsOrderGroup(params);
		reitInvestmentOrderGroupId.value = reitInvestmentOrderGroupIdLocal;
		return 'ira-complete-rollover-agreements';
	}

	async function submitFundingType(fundingTypeParam: IraFundingType): Promise<string> {
		let dest = '';

		if (flow.value === 'SIGN-UP') {
			if (fundingType.value !== fundingTypeParam) {
				await resetIra();
			}
			await submitFundingTypeAPI(fundingTypeParam);
			dest =
				fundingTypeParam === 'TRANSFER' || fundingTypeParam === 'ROLLOVER'
					? 'ira-important-information'
					: 'ira-account-type';
		} else {
			switch (fundingTypeParam) {
				case 'EXISTING':
					dest = 'ira-add-funds-transfer-amount';
					break;
				case 'TRANSFER':
					dest = 'ira-add-funds-important-information';
					break;
				case 'ROLLOVER':
					addFundsDripPreferenceRequired.value = false;
					addFundsPlanSelectionRequired.value = false;
					dest = 'ira-add-funds-important-information';
					break;
				default:
					dest = 'ira-add-funds-amount';
					break;
			}
		}

		fundingType.value = fundingTypeParam;
		return editRedirect.value || dest;
	}

	async function placeOrder(agreementInfo: {
		fundriseDocumentIds: Array<string>;
		iraDocumentsToSubmit: Array<IraDocumentSubmission>;
	}): Promise<void> {
		let reinvestmentPreferenceEnum: DripStatus | null = null;

		if (!addFundsEdirect.value) {
			reinvestmentPreferenceEnum = dripPreference.value ? 'RIA_REINVESTMENT' : 'CASH_DISTRIBUTION';
		}

		if (flow.value === 'SIGN-UP') {
			await placeFirstOrder(agreementInfo.fundriseDocumentIds);
		} else {
			await placeOrderAPI(
				reitInvestmentOrderGroupId.value,
				agreementInfo.fundriseDocumentIds,
				reinvestmentPreferenceEnum
			);
		}
		orderGroupAcknowledged.value = true;
	}

	async function submitRolloverAgreements(agreementInfo: {
		fundriseDocumentIds: Array<string>;
		iraDocumentsToSubmit: Array<IraDocumentSubmission>;
	}): Promise<string> {
		if (flow.value === 'COMPLETE-ROLLOVER') {
			await submitMtcSignatures(reitInvestmentOrderGroupId.value, agreementInfo.iraDocumentsToSubmit);
			await placeOrder(agreementInfo);
			await completeIraRepeatInvestment(reitInvestmentOrderGroupId.value);
		} else {
			await submitMtcRolloverSignatures(agreementInfo.iraDocumentsToSubmit);
			rolloverAgreementsSubmitted.value = true;
		}

		if (
			(flow.value === 'ADD-FUNDS' && legacyIra.value) ||
			(flow.value === 'SIGN-UP' && verificationStatus.value === 'VERIFIED')
		) {
			await submitRollover();
		} else if (flow.value === 'REACKNOWLEDGE-ROLLOVER') {
			await resubmitPendingRollover();
		}

		let dest: string;
		switch (flow.value) {
			case 'ADD-FUNDS':
				dest = 'ira-add-funds-success';
				break;
			case 'COMPLETE-ROLLOVER':
				dest = 'ira-complete-rollover-success';
				break;
			case 'REACKNOWLEDGE-ROLLOVER':
				dest = 'ira-reacknowledge-rollover-success';
				break;
			default:
				if (verificationStatus.value === 'VERIFIED') {
					dest = 'ira-success';
				} else {
					dest = 'ira-otp-type';
				}
		}

		return dest;
	}

	async function submitAgreements(agreementInfo: {
		fundriseDocumentIds: Array<string>;
		iraDocumentsToSubmit: Array<IraDocumentSubmission>;
	}): Promise<string> {
		if (fundingType.value === 'ROLLOVER') {
			return submitRolloverAgreements(agreementInfo);
		}

		await submitMtcSignatures(reitInvestmentOrderGroupId.value, agreementInfo.iraDocumentsToSubmit);

		const flowsToPlaceOrder = flow.value === 'SIGN-UP' || flow.value === 'ADD-FUNDS';

		if (!orderGroupAcknowledged.value && flowsToPlaceOrder) {
			await placeOrder(agreementInfo);
		}

		if (flow.value === 'ADD-FUNDS' || flow.value === 'COMPLETE-ORDER-ADD-FUNDS') {
			await completeIraRepeatInvestment(reitInvestmentOrderGroupId.value);
		} else if (
			flow.value === 'COMPLETE-ORDER-SIGNUP' ||
			(flow.value === 'SIGN-UP' && verificationStatus.value === 'VERIFIED')
		) {
			await completeOnboarding();
		}

		let dest: string;
		switch (flow.value) {
			case 'ADD-FUNDS':
				dest = 'ira-add-funds-success';
				break;
			case 'COMPLETE-ORDER-ADD-FUNDS':
			case 'COMPLETE-ORDER-SIGNUP':
				dest = 'ira-complete-order-success';
				break;
			default:
				if (verificationStatus.value === 'VERIFIED') {
					dest = 'ira-success';
				} else {
					dest = 'ira-otp-type';
				}
		}
		return dest;
	}

	function getAddFundsAmountSubmitNextPage(): string {
		if (addFundsPlanSelectionRequired.value) {
			return 'ira-add-funds-plan';
		} else if (addFundsDripPreferenceRequired.value) {
			return 'ira-add-funds-drip';
		}

		if (fundingType.value === 'CONTRIBUTION') {
			return 'ira-add-funds-funding-source';
		}

		return 'ira-add-funds-confirm';
	}

	async function submitAmountAndTaxYear(payload: Record<'amount' | 'taxYear', string>): Promise<string> {
		let dest = getAddFundsAmountSubmitNextPage();

		if (flow.value === 'SIGN-UP') {
			await submitAmount(payload.amount, payload.taxYear);

			if (plan.value) {
				dest = await submitPlan(plan.value.id);
			} else {
				dest = 'ira-plan';
			}
		}

		selectedAmount.value = payload.amount;
		selectedTaxYear.value = payload.taxYear;
		return editRedirect.value || dest;
	}

	async function submitTransferAmount(amount: string): Promise<string> {
		let dest = getAddFundsAmountSubmitNextPage();

		if (flow.value === 'SIGN-UP') {
			await submitTransferAmountAPI(amount);

			if (fundingType.value === 'ROLLOVER') {
				dest = 'ira-transfer-fees';
			} else if (plan.value) {
				dest = await submitPlan(plan.value.id);
			} else {
				dest = 'ira-plan';
			}
		} else if (flow.value === 'COMPLETE-ROLLOVER') {
			if (rolloverPlanAlreadySet.value && rolloverDripAlreadySet.value) {
				dest = 'ira-complete-rollover-confirm';
			} else if (rolloverPlanAlreadySet.value) {
				dest = 'ira-complete-rollover-drip';
			} else {
				dest = 'ira-complete-rollover-plan';
			}
		}
		selectedAmount.value = amount;
		return editRedirect.value || dest;
	}

	async function submitTransferFees(bankAccount: BankAccount): Promise<string> {
		const dest = fundingType.value === 'ROLLOVER' ? 'ira-confirm' : 'ira-drip';

		await submitPaymentMethod(bankAccount);
		await submitAcknowledgedFees(true);

		return editRedirect.value || dest;
	}

	async function submitAccountType(accountTypeParam: IraAccountType): Promise<string> {
		let dest = '';
		const apiType = await submitAccountTypeAPI(accountTypeParam);
		updateAccountType(apiType);
		switch (fundingType.value) {
			case 'TRANSFER':
				dest = 'ira-transfer-account-info';
				break;
			case 'ROLLOVER':
				dest = 'ira-rollover-method';
				break;
			default:
				dest = 'ira-amount';
		}
		return editRedirect.value || dest;
	}

	async function submitBankAccount(bankAccountInfo: NewManualBankAccount): Promise<string> {
		await investmentEntity.submitManualBankAccount(bankAccountInfo);

		let dest = '';
		if (flow.value === 'SIGN-UP') {
			dest = fundingType.value === 'CONTRIBUTION' ? 'ira-funding-source' : 'ira-transfer-fees';
		} else if (flow.value === 'ADD-FUNDS') {
			dest = 'ira-add-funds-funding-source';
		}

		return dest;
	}

	async function submitDripPreference(dripEnabled: boolean): Promise<string> {
		let dest = '';
		if (flow.value === 'SIGN-UP') {
			await submitDripPreferenceAPI(dripEnabled);
			dest = 'ira-confirm';
		} else if (flow.value === 'COMPLETE-ROLLOVER') {
			dest = 'ira-complete-rollover-confirm';
		} else if (flow.value === 'ADD-FUNDS') {
			dest = fundingType.value === 'CONTRIBUTION' ? 'ira-add-funds-funding-source' : 'ira-add-funds-confirm';
		}

		dripPreference.value = dripEnabled;

		return editRedirect.value || dest;
	}

	async function submitPaymentMethod(method: BankAccount): Promise<string> {
		let dest = 'ira-add-funds-confirm';

		if (flow.value === 'SIGN-UP') {
			await submitPaymentMethodAPI(method.id);
			dest = 'ira-fee-schedule';
		}

		if (selectedPaymentMethod.value && selectedPaymentMethod.value.id !== method.id) {
			acknowledgedFees.value = false;
		}
		selectedPaymentMethod.value = method;

		return editRedirect.value || dest;
	}

	async function submitOtpDelivery(selectedOtpDeliveryType: IraOtpDeliveryType): Promise<string> {
		const iraVerification = await sendMtcCode(selectedOtpDeliveryType);
		verificationStatus.value = iraVerification.verificationStatus;
		let dest;
		switch (verificationStatus.value) {
			case 'ERROR':
			case 'DELIVERY_FAILURE':
				dest = 'ira-verification-error';
				break;
			case 'AWAITING_DOCUMENTATION':
				dest = 'ira-documents';
				break;
			case 'VERIFIED':
				if (fundingType.value === 'ROLLOVER') {
					await submitRollover();
				} else {
					await completeOnboarding();
				}
				dest = 'ira-success';
				break;
			default:
				dest = 'ira-mobile-verify';
		}
		return dest;
	}

	async function submitPhoneVerification(code: string): Promise<string> {
		const iraVerification = await submitPhoneVerificationAPI(code);
		verificationStatus.value = iraVerification.verificationStatus;
		let dest;
		switch (verificationStatus.value) {
			case 'ERROR':
			case 'DELIVERY_FAILURE':
				dest = 'ira-verification-error';
				break;
			case 'AWAITING_DOCUMENTATION':
				dest = 'ira-documents';
				break;
			default:
				if (fundingType.value === 'ROLLOVER') {
					await submitRollover();
				} else {
					await completeOnboarding();
				}
				dest = 'ira-success';
				break;
		}
		await user.getUser();
		return dest;
	}

	async function submitAcknowledgedFees(acknowledgedFeesLocal: boolean): Promise<void> {
		await submitAcknowledgedFeesAPI(acknowledgedFeesLocal);
		acknowledgedFees.value = acknowledgedFeesLocal;
	}

	/* eslint-disable @typescript-eslint/no-non-null-assertion */

	async function submitRollover(): Promise<void> {
		if (flow.value === 'SIGN-UP') {
			await submitOnboardingRollover();
		} else {
			const params: IraSubmitRolloverParams = {
				amount: selectedAmount.value,
				rolloverMethod: rolloverMethod.value!,
				rolloverType: rolloverType.value!,
				sourceAccountType: sourceAccountType.value!
			};
			if (rolloverCheckDate.value) {
				params.indirectRolloverChequeDate = rolloverCheckDate.value;
			}
			if (reitOfferingVintage.value) {
				params.rolloverReitOfferingVintageId = reitOfferingVintage.value.reitOfferingVintageId;
			}
			await submitAddFundsRollover(params);
		}
	}
	/* eslint-enable @typescript-eslint/no-non-null-assertion */

	/* eslint-disable @typescript-eslint/no-non-null-assertion */

	async function submitOrderGroup(): Promise<void> {
		let reitInvestmentOrderGroupIdLocal = '';
		if (flow.value === 'SIGN-UP') {
			reitInvestmentOrderGroupIdLocal = await submitOnboardingOrderGroup();
		} else {
			const investmentParams = reitOfferingVintage.value
				? {
						reitOfferingVintageId: reitOfferingVintage.value.reitOfferingVintageId
					}
				: {
						riaPlanId: (plan.value as RiaPlan).id
					};

			if (fundingType.value === 'CONTRIBUTION') {
				const params: IraAddFundsNewContributionOrderGroupParams = {
					amount: selectedAmount.value,
					fundingType: fundingType.value,
					paymentMethodId: selectedPaymentMethod.value!.id,
					selectedTaxYear: selectedTaxYear.value,
					...investmentParams
				};
				reitInvestmentOrderGroupIdLocal = await submitAddFundsOrderGroup(params);
			} else if (fundingType.value === 'EXISTING') {
				const params: IraAddFundsExistingOrderGroupParams = {
					amount: selectedAmount.value,
					fundingType: fundingType.value,
					...investmentParams
				};
				reitInvestmentOrderGroupIdLocal = await submitAddFundsOrderGroup(params);
			} else {
				const params: IraAddFundsTransferOrderGroupParams = {
					amount: selectedAmount.value,
					fundingType: fundingType.value,
					sourceAccountNumber: sourceAccountNumber.value,
					sourceAccountType: sourceAccountType.value!,
					...investmentParams
				};

				if (sourceCustodianName.value && sourceDtcParticipantNumber.value) {
					params.sourceCustodianName = sourceCustodianName.value;
					params.sourceDtcParticipantNumber = sourceDtcParticipantNumber.value;
				} else if (sourceDtcParticipant.value) {
					params.sourceDtcParticipantId = sourceDtcParticipant.value.dtcParticipantId;
				}

				reitInvestmentOrderGroupIdLocal = await submitAddFundsOrderGroup(params);
			}
		}

		reitInvestmentOrderGroupId.value = reitInvestmentOrderGroupIdLocal;
	}
	/* eslint-enable @typescript-eslint/no-non-null-assertion */

	async function submitSourceAccountType(type: IraAccountType): Promise<string> {
		let dest = '';
		if (flow.value === 'ADD-FUNDS') {
			const eligibilityData = await checkSourceEligibilityAddFunds(type);
			const addFundsApiType = eligibilityData.sourceAccountType;
			updateSourceAccountType(addFundsApiType);
			if (eligibilityData.compatible) {
				dest = type === 'SIMPLE' ? 'ira-add-funds-account-age' : 'ira-add-funds-transfer-account-info';
			} else {
				dest = 'ira-add-funds-transfer-incompatible';
			}
		} else {
			const apiType = await submitSourceAccountTypeAPI(type);
			updateSourceAccountType(apiType);
			dest = type === 'SIMPLE' ? 'ira-account-age' : 'ira-target-account-type';
		}
		return editRedirect.value || dest;
	}

	async function submitRolloverType(type: IraRolloverTypeWithLabel): Promise<string> {
		let dest = '';
		if (flow.value === 'ADD-FUNDS') {
			if (type.iraRolloverType === 'DEFINED_BENEFIT' || type.iraRolloverType === 'PROFIT_SHARING') {
				const eligibilityData = await checkRolloverSourceEligibilityAddFunds(type.iraRolloverType);
				const addFundsApiType = eligibilityData.sourceAccountType;
				updateSourceAccountType(addFundsApiType);
				dest = eligibilityData.compatible
					? 'ira-add-funds-rollover-method'
					: 'ira-add-funds-transfer-incompatible';
			} else {
				dest = 'ira-add-funds-rollover-source-account-type';
			}
		} else {
			const iraMeta = await submitRolloverAccountType(type.iraRolloverType);
			if (type.iraRolloverType === 'DEFINED_BENEFIT' || type.iraRolloverType === 'PROFIT_SHARING') {
				// Defined Benefit and Profit Sharing are always traditional, so the source account type is already decided
				const sourceAccountTypeLbl = iraMeta.sourceAccountType;
				if (sourceAccountTypeLbl) {
					updateSourceAccountType(sourceAccountTypeLbl);
				}
				dest = 'ira-target-account-type';
			} else {
				dest = 'ira-rollover-source-account-type';
			}
		}

		updateRolloverType(type);

		return editRedirect.value || dest;
	}

	async function submitRolloverSourceAccountType(type: IraAccountType): Promise<string> {
		let dest = '';
		if (type === 'ROLLOVER_ROTH' || type === 'ROLLOVER_TRADITIONAL') {
			if (flow.value === 'ADD-FUNDS') {
				const eligibilityData = await checkRolloverSourceEligibilityAddFunds(type);
				const addFundsApiType = eligibilityData.sourceAccountType;
				updateSourceAccountType(addFundsApiType);
				dest = eligibilityData.compatible
					? 'ira-add-funds-rollover-method'
					: 'ira-add-funds-transfer-incompatible';
			} else {
				const apiType = await submitSourceAccountTypeAPI(type);
				updateSourceAccountType(apiType);
				dest = 'ira-target-account-type';
			}
		} else {
			updateSourceAccountType({
				iraType: 'MIXED',
				iraTypeLabel: 'Mixed'
			});
			dest = flow.value === 'ADD-FUNDS' ? 'ira-add-funds-rollover-mixed-error' : 'ira-rollover-mixed-error';
		}

		return editRedirect.value || dest;
	}

	async function submitRolloverMethod(method: RolloverMethod): Promise<string> {
		let dest = method === 'DIRECT' ? 'ira-add-funds-transfer-amount' : 'ira-add-funds-rollover-check-date';
		if (flow.value === 'SIGN-UP') {
			dest = method === 'DIRECT' ? 'ira-transfer-amount' : 'ira-rollover-check-date';
			await submitRolloverMethodAPI(method);
		}

		rolloverMethod.value = method;
		return editRedirect.value || dest;
	}

	async function submitRolloverCheckDate(date: string): Promise<string> {
		let dateValid = false;
		if (flow.value === 'SIGN-UP') {
			dateValid = await submitRolloverCheckDateAPI(date);
			rolloverCheckDate.value = date;
			rolloverCheckDateValid.value = dateValid;

			return dateValid ? 'ira-transfer-amount' : 'ira-rollover-check-not-eligible';
		} else {
			dateValid = await checkRolloverCheckDate(date);
			rolloverCheckDate.value = date;
			rolloverCheckDateValid.value = dateValid;
			return dateValid ? 'ira-add-funds-transfer-amount' : 'ira-add-funds-rollover-check-not-eligible';
		}
	}

	async function submitSimpleIraFirstContributionDate(date: string): Promise<string> {
		let dateValid = false;
		if (flow.value === 'SIGN-UP') {
			dateValid = await submitSimpleIraFirstContributionDateAPI(date);
			simpleIraFirstContributionDate.value = date;
			simpleIraFirstContributionDateValid.value = dateValid;
			return dateValid ? 'ira-target-account-type' : 'ira-account-not-eligible';
		} else {
			dateValid = await checkSimpleIraFirstContributionDateAddFunds(date);
			simpleIraFirstContributionDate.value = date;
			simpleIraFirstContributionDateValid.value = dateValid;
			return dateValid ? 'ira-add-funds-transfer-account-info' : 'ira-add-funds-account-not-eligible';
		}
	}

	async function submitSimpleIneligibleNextSteps(params: Record<'newIra' | 'emailMe', boolean>): Promise<string> {
		if (params.emailMe && !params.newIra) {
			await submitEmailRequest(simpleIraFirstContributionDate.value);
			if (flow.value === 'SIGN-UP') {
				await resetIra();
			}

			redirect('/ira/email-saved');
			return '';
		} else if (params.newIra && params.emailMe) {
			await submitEmailRequest(simpleIraFirstContributionDate.value);
			if (flow.value === 'SIGN-UP') {
				return resetIra();
			} else {
				return resetAddFunds();
			}
		} else if (flow.value === 'SIGN-UP') {
			return resetIra();
		} else {
			return resetAddFunds();
		}
	}

	async function submitTransferAccountInfo(info: SourceAccountTypeSubmission): Promise<string> {
		if (flow.value === 'SIGN-UP') {
			await submitTransferAccountInfoAPI(info);
		}
		if (info.sourceCustodianName && info.sourceDtcParticipantNumber) {
			sourceCustodianName.value = info.sourceCustodianName;
			sourceDtcParticipantNumber.value = info.sourceDtcParticipantNumber;
			sourceDtcParticipant.value = null;
		} else if (info.sourceDtcParticipant) {
			sourceDtcParticipant.value = {
				dtcParticipantId: info.sourceDtcParticipant.dtcParticipantId,
				dtcCustodianName: info.sourceDtcParticipant.dtcCustodianName
			};
			sourceCustodianName.value = '';
			sourceDtcParticipantNumber.value = '';
		}

		sourceAccountNumber.value = info.sourceAccountNumber;

		return flow.value === 'SIGN-UP' ? 'ira-transfer-amount' : 'ira-add-funds-transfer-amount';
	}

	async function submitVerificationDocuments(uploadForm: {
		[verificationType in MillenniumVerificationType]?: IraVerificationDocument | null;
	}): Promise<string> {
		const iraVerification = await uploadVerificationManual(uploadForm);
		verificationStatus.value = iraVerification.verificationStatus;
		if (fundingType.value === 'ROLLOVER' && flow.value === 'SIGN-UP') {
			await submitRollover();
		}
		return flow.value === 'VERIFY' ? 'ira-verify-success' : 'ira-success';
	}

	async function resetIraForSimpleIraReminderRedirect(): Promise<void> {
		await resetIra();
		await submitFundingType('TRANSFER');
		fundingType.value = 'TRANSFER';
	}

	async function getIraFeesAndContributionLimits(): Promise<void> {
		const nullContributionLimit = contributionLimit.value == null || seniorContributionLimit.value == null;
		const nullThresholdLimit = annualThresholdLimit.value == null || accountSizeThresholdLimit.value == null;
		if (taxYear.value == null || nullContributionLimit || feeAmount.value == null || nullThresholdLimit) {
			const iraFeesAndContributionLimits = await getIraFeesAndContributionLimitsAPI();
			updateIraFeesAndContributionLimits(iraFeesAndContributionLimits);
		}
	}

	function updateDripPreferenceByType(preferenceType: DripStatus | null): void {
		if (preferenceType === 'CASH_DISTRIBUTION') {
			dripPreference.value = false;
		} else if (preferenceType === 'RIA_REINVESTMENT') {
			dripPreference.value = true;
		} else {
			dripPreference.value = null;
		}
	}

	function updateSourceAccountType(type: IraTypeWithLabel | null): void {
		if (type === null) {
			sourceAccountType.value = null;
			sourceAccountTypeLabel.value = '';
		} else {
			sourceAccountType.value = type.iraType;
			sourceAccountTypeLabel.value = type.iraTypeLabel;
		}
	}

	function updateAccountType(type: IraTypeWithLabel | null): void {
		if (type === null) {
			sourceAccountType.value = null;
			sourceAccountTypeLabel.value = '';
		} else {
			accountType.value = type.iraType;
			accountTypeLabel.value = type.iraTypeLabel;
		}
	}

	function updateIraFeesAndContributionLimits(iraFeesAndContributionLimits: IraFeesAndContributionLimits): void {
		taxYear.value = iraFeesAndContributionLimits.taxYear;
		contributionLimit.value = iraFeesAndContributionLimits.contributionLimit;
		seniorContributionLimit.value = iraFeesAndContributionLimits.seniorContributionLimit;
		feeAmount.value = iraFeesAndContributionLimits.feeAmount;
		annualThresholdLimit.value = iraFeesAndContributionLimits.annualThresholdLimit;
		accountSizeThresholdLimit.value = iraFeesAndContributionLimits.accountSizeThresholdLimit;
	}

	function updateAddresses(Addresses: Pick<IraPersonalInformation, 'primaryAddress' | 'mailingAddress'>): void {
		personalInfo.value.primaryAddress = Addresses.primaryAddress;
		personalInfo.value.mailingAddress = Addresses.mailingAddress;
	}

	function updateRolloverType(type: IraRolloverTypeWithLabel | null): void {
		if (type === null) {
			rolloverType.value = null;
			rolloverTypeLabel.value = '';
		} else {
			rolloverType.value = type.iraRolloverType;
			rolloverTypeLabel.value = type.iraRolloverTypeLabel;
		}
	}

	function $reset(): void {
		stateDeterminerStep.value = null;
		dataRetrieved.value = false;
		personalInfo.value = {
			firstName: '',
			lastName: '',
			primaryAddress: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				zip: ''
			},
			mailingAddress: null
		};
		currentPhone.value = null;
		fundingType.value = 'CONTRIBUTION';
		accountType.value = 'TRADITIONAL_IRA';
		accountTypeLabel.value = 'Traditional';
		dripPreference.value = null;
		plan.value = null;
		reitOfferingVintage.value = null;
		iraBankAccounts.value = [];
		selectedPaymentMethod.value = null;
		acknowledgedFees.value = false;
		orderGroupAcknowledged.value = false;
		selectedAmount.value = '';
		selectedTaxYear.value = '';
		editRedirect.value = '';
		reitInvestmentOrderGroupId.value = '';
		contactDocument.value = null;
		flow.value = 'SIGN-UP';
		isFunded.value = false;
		legacyIra.value = false;
		verificationStatus.value = null;
		mtcContactCreated.value = false;
		addFundsPlanSelectionRequired.value = false;
		addFundsDripPreferenceRequired.value = false;
		addFundsEdirect.value = false;
		sourceAccountType.value = null;
		sourceAccountTypeLabel.value = '';
		simpleIraFirstContributionDate.value = '';
		simpleIraFirstContributionDateValid.value = null;
		sourceCustodianName.value = '';
		sourceDtcParticipantNumber.value = '';
		sourceDtcParticipant.value = null;
		sourceAccountNumber.value = '';
		maxContributionLimit.value = null;
		rolloverType.value = null;
		rolloverTypeLabel.value = '';
		rolloverMethod.value = null;
		rolloverCheckDate.value = '';
		rolloverCheckDateValid.value = null;
		rolloverPlanAlreadySet.value = false;
		rolloverDripAlreadySet.value = false;
		rolloverAgreementsSubmitted.value = null;
		initialRolloverAmount.value = '';
		rolloverInitiationDate.value = '';
		rolloverConfirmedThreshold.value = null;
		taxYear.value = null;
		contributionLimit.value = null;
		seniorContributionLimit.value = null;
		feeAmount.value = 'null';
		annualThresholdLimit.value = null;
		accountSizeThresholdLimit.value = null;
	}

	return {
		// State
		stateDeterminerStep,
		dataRetrieved,
		personalInfo,
		currentPhone,
		fundingType,
		accountType,
		accountTypeLabel,
		dripPreference,
		plan,
		reitOfferingVintage,
		iraBankAccounts,
		selectedPaymentMethod,
		acknowledgedFees,
		orderGroupAcknowledged,
		selectedAmount,
		selectedTaxYear,
		editRedirect,
		reitInvestmentOrderGroupId,
		contactDocument,
		flow,
		isFunded,
		legacyIra,
		verificationStatus,
		mtcContactCreated,
		addFundsPlanSelectionRequired,
		addFundsDripPreferenceRequired,
		addFundsEdirect,
		sourceAccountType,
		sourceAccountTypeLabel,
		simpleIraFirstContributionDate,
		simpleIraFirstContributionDateValid,
		sourceCustodianName,
		sourceDtcParticipantNumber,
		sourceDtcParticipant,
		sourceAccountNumber,
		maxContributionLimit,
		rolloverType,
		rolloverTypeLabel,
		rolloverMethod,
		rolloverCheckDate,
		rolloverCheckDateValid,
		rolloverPlanAlreadySet,
		rolloverDripAlreadySet,
		rolloverAgreementsSubmitted,
		initialRolloverAmount,
		rolloverInitiationDate,
		rolloverConfirmedThreshold,
		taxYear,
		contributionLimit,
		seniorContributionLimit,
		feeAmount,
		annualThresholdLimit,
		accountSizeThresholdLimit,
		isTransfer,
		isRollover,

		// Methods
		getEntryData,
		getIncompleteOrder,
		getIncompleteRollover,
		getPendingRollover,
		getVerifyFlowInfo,
		resetIra,
		resetAddFunds,
		resetAll,
		resetFlow,
		setEntryData,
		getIraInfoForRepeatInvestment,
		getBankAccounts,
		getContactDocuments,
		storeIraFundingType,
		submitPersonalInfo,
		submitSelectedNumber,
		submitPlan,
		submitConfirmation,
		submitCompleteRolloverConfirm,
		submitFundingType,
		placeOrder,
		submitRolloverAgreements,
		submitAgreements,
		getAddFundsAmountSubmitNextPage,
		submitAmountAndTaxYear,
		submitTransferAmount,
		submitTransferFees,
		submitAccountType,
		submitBankAccount,
		submitDripPreference,
		submitPaymentMethod,
		submitOtpDelivery,
		submitPhoneVerification,
		submitAcknowledgedFees,
		submitRollover,
		submitOrderGroup,
		submitSourceAccountType,
		submitRolloverType,
		submitRolloverSourceAccountType,
		submitRolloverMethod,
		submitRolloverCheckDate,
		submitSimpleIraFirstContributionDate,
		submitSimpleIneligibleNextSteps,
		submitTransferAccountInfo,
		submitVerificationDocuments,
		resetIraForSimpleIraReminderRedirect,
		getIraFeesAndContributionLimits,
		updateDripPreferenceByType,
		updateSourceAccountType,
		updateAccountType,
		updateIraFeesAndContributionLimits,
		updateAddresses,
		updateRolloverType,
		$reset
	};
});
