import { accountDetailsRoute, getNextRoute } from '@utils/checkout';
import { BankAccount, WirePaymentMethod } from 'types/payment';
import {
	CheckoutAccountFormStatus,
	CheckoutAutoInvestDetails,
	CheckoutOrderGroupDetails,
	EntityAccountDetails,
	JointAccountDetails,
	SignupCheckoutSelectionResponse,
	SignupStepName,
	TrustAccountDetails
} from 'types/checkout';
import {
	clearAccountType,
	clearOrderGroup,
	completeSignupStep,
	createOrderGroup,
	getSignupCheckoutSelections,
	isEligibleForAdditionalInvestment,
	placeFirstOrder,
	submitNewEntityAccountDetails,
	submitNewJointAccountDetails,
	submitNewTrustAccountDetails,
	updateAutoInvest,
	updateExistingEntityAccountDetails,
	updateExistingJointAccountDetails,
	updateExistingTrustAccountDetails,
	updateInvestmentSelections
} from '@api/checkout';
import { clearPlanAndProSelections, getAvailableSignupPlansV2, syncSignupFlow } from '@api/investment-entity';
import { computed, ref } from 'vue';
import { InvestmentEntity, InvestmentEntityType } from 'types/investment-entity';
import { RiaPlan, RiaPlanMarketingStrategy } from 'types/plan';
import { app } from '../store/modules/app';
import { attachPaymentMethod } from '@api/bank-accounts';
import { defineStore } from 'pinia';
import { EmailCaptureFields } from 'types/layout/lead';
import { getInvestFlowRouteLocation } from '@utils/invest';
import { getRiaPlanMarketingInfo } from '@api/ria';
import { investmentEntity } from '@store/modules/investment-entity';
import { Link } from 'types/layout';
import { RouteLocationRaw } from 'vue-router';
import { submitPlaidLayerPublicToken } from '@api/plaid-client';
import { useIraStore } from '@stores/ira';
import { user } from '@store/modules/user';

export const useSignupStore = defineStore('signup', () => {
	const resumeCheckoutRoute = 'checkout-resume';

	const acknowledgmentsDocIds = ref<Array<string> | null>(null);
	const allFunds = ref<Array<RiaPlan>>([]);
	const allOtherPlans = ref<Array<RiaPlan> | null>(null);
	const email = ref<string | undefined>(undefined);
	const emailCaptureFields = ref<EmailCaptureFields>({ existingUser: false, mixpanelId: '', newLead: false });
	const orderGroupId = ref<string | null>(null);
	const phoneNumber = ref('');
	const recommendedPlans = ref<Array<RiaPlan>>([]);
	const recommendedPlansMarketingInfo = ref<Array<RiaPlanMarketingStrategy>>([]);
	const signupCheckoutSelections = ref<SignupCheckoutSelectionResponse | null>(null);

	const isPresetOfferingFlow = computed((): boolean => {
		return signupCheckoutSelections.value?.signupFlowType === 'PRESET_OFFERING';
	});

	const isPresetPlanFlow = computed((): boolean => {
		return signupCheckoutSelections.value?.signupFlowType === 'PRESET_PLAN';
	});

	const isStandardFlow = computed((): boolean => {
		return signupCheckoutSelections.value?.signupFlowType === 'STANDARD';
	});

	const isIraCheckout = computed((): boolean => {
		return signupCheckoutSelections.value?.currentStep?.signupStepName === 'IRA_CHECKOUT_STEP';
	});

	const userSelectedIraAccount = computed((): boolean => {
		return signupCheckoutSelections.value?.iraCheckout ?? false;
	});

	async function completeStep(stepName?: SignupStepName): Promise<RouteLocationRaw> {
		let selections;

		if (stepName) {
			selections = await completeSignupStep(stepName);
		} else if (signupCheckoutSelections?.value?.currentStep) {
			selections = await completeSignupStep(signupCheckoutSelections.value.currentStep.signupStepName);
		} else {
			selections = await getSignupCheckoutSelections();
		}

		await storeCheckoutDetails(selections);
		return getNextRoute(selections.currentStep);
	}

	async function getUserCheckoutDetailsData(signupStepName?: SignupStepName): Promise<void> {
		if (!investmentEntity.isIraEntity) {
			const selections = await getSignupCheckoutSelections(signupStepName);
			await storeCheckoutDetails(selections);
		}
	}

	async function goToNextStep(isPreviousStep = false): Promise<RouteLocationRaw> {
		const stepName = isPreviousStep
			? signupCheckoutSelections.value?.previousStep?.signupStepName
			: signupCheckoutSelections.value?.currentStep?.signupStepName;

		await getUserCheckoutDetailsData(stepName);
		return getNextRoute(signupCheckoutSelections.value?.currentStep);
	}

	async function submitPassword(): Promise<string> {
		return resumeCheckoutRoute;
	}

	async function submitPlanSelection(plan: RiaPlan): Promise<RouteLocationRaw> {
		if (orderGroupId.value) {
			await clearOrderGroup();
		}

		await updateInvestmentSelections(plan);
		return completeStep();
	}

	async function getAvailableSignupRiaPlans(filter?: 'recommended'): Promise<void> {
		await clearPlanAndProSelections();

		const response = await getAvailableSignupPlansV2(filter ?? undefined);
		await getPlanMarketingInfo(response.riaPlans);
		recommendedPlans.value = response.riaPlans;
		allOtherPlans.value = response.otherPlans ?? null;
		allFunds.value = response.funds;
	}

	async function getPlanMarketingInfo(plans: Array<RiaPlan>): Promise<void> {
		const filters = plans.map((plan) => plan.investmentStrategy).join(',');
		const marketingInfo: Array<RiaPlanMarketingStrategy> = await getRiaPlanMarketingInfo(filters);
		recommendedPlansMarketingInfo.value = marketingInfo;
	}

	async function submitUserCountrySelections(payload: {
		citizenshipCountryCode: string;
		residenceCountryCode: string;
	}): Promise<RouteLocationRaw> {
		await user.setCountrySelections(payload);

		const route = await completeStep();
		if (payload.residenceCountryCode === 'US') {
			if (isIraCheckout.value) {
				return { name: 'ira-checkout-individual-details' };
			} else {
				return route;
			}
		} else {
			return { name: 'checkout-country-not-supported' };
		}
	}

	async function updatePaymentMethod(accountDetails: {
		newAccount: BankAccount | WirePaymentMethod;
	}): Promise<RouteLocationRaw> {
		await attachPaymentMethod(accountDetails.newAccount.id, 'ACH');
		return completeStep();
	}

	async function submitSelectedAccount(selectedInvestmentEntity: InvestmentEntity): Promise<void> {
		const { investmentEntityId, entityType } = selectedInvestmentEntity;
		const isRepeatInvestor = await isEligibleForAdditionalInvestment(investmentEntityId);
		let nextRoute;
		if (isRepeatInvestor) {
			nextRoute = getInvestFlowRouteLocation('plan');
		} else {
			nextRoute = await accountDetailsRoute(entityType, 'update');
		}
		await user.updateSelectedEntityWithoutReload({
			nextRoute: { route: nextRoute },
			investmentEntityId
		});
	}

	async function submitAccountDetails(submissionDetails: {
		investmentType: InvestmentEntityType;
		userRoute: CheckoutAccountFormStatus;
		payload: JointAccountDetails | TrustAccountDetails | EntityAccountDetails;
		settingsEntityCreation?: boolean;
	}): Promise<RouteLocationRaw | string> {
		let route;
		if (submissionDetails.userRoute === 'update') {
			let stepName: SignupStepName | undefined = undefined;
			switch (submissionDetails.investmentType) {
				case 'JOINT_ACCOUNT':
					stepName = 'JOINT_INFORMATION_STEP';
					await updateExistingJointAccountDetails(submissionDetails.payload as JointAccountDetails);
					break;
				case 'LLC':
					stepName = 'ENTITY_INFORMATION_STEP';
					await updateExistingEntityAccountDetails(submissionDetails.payload as EntityAccountDetails);
					break;
				case 'TRUST':
					stepName = 'TRUST_INFORMATION_STEP';
					await updateExistingTrustAccountDetails(submissionDetails.payload as TrustAccountDetails);
					break;
				default:
					break;
			}
			route = await completeStep(stepName);
		} else {
			let investmentEntityId;
			let stepName: SignupStepName | undefined = undefined;
			switch (submissionDetails.investmentType) {
				case 'JOINT_ACCOUNT':
					stepName = 'JOINT_INFORMATION_STEP';
					investmentEntityId = (
						await submitNewJointAccountDetails(submissionDetails.payload as JointAccountDetails)
					).investmentEntityId;
					break;
				case 'LLC':
					stepName = 'ENTITY_INFORMATION_STEP';
					investmentEntityId = (
						await submitNewEntityAccountDetails(submissionDetails.payload as EntityAccountDetails)
					).investmentEntityId;
					break;
				case 'TRUST':
					stepName = 'TRUST_INFORMATION_STEP';
					investmentEntityId = (
						await submitNewTrustAccountDetails(submissionDetails.payload as TrustAccountDetails)
					).investmentEntityId;
					break;
				default:
					break;
			}

			if (investmentEntityId && submissionDetails.settingsEntityCreation) {
				return investmentEntityId;
			} else if (investmentEntityId) {
				await user.updateInvestmentEntityAndTrackEvents(investmentEntityId);
				if (isPresetOfferingFlow.value) {
					await syncSignupFlow();
				}
			}

			route = await completeStep(stepName);
		}

		await user.getUser();

		return route;
	}

	async function submitSignupOrder(): Promise<RouteLocationRaw> {
		if (signupCheckoutSelections.value?.selections?.plan) {
			await updateInvestmentSelections(signupCheckoutSelections.value.selections.plan);
		}

		if (isIraCheckout.value) {
			return { name: 'ira-select-country' };
		} else {
			return completeStep();
		}
	}

	async function placeOrder(): Promise<Link> {
		await placeFirstOrder(acknowledgmentsDocIds.value as Array<string>);

		if (app.isVerified) {
			await user.getUser();
			await investmentEntity.refreshPositionsAndPerformance();
			return {
				router: isPresetOfferingFlow.value ? 'checkout-preset-investment-success' : 'reits-checkout-success'
			};
		} else {
			return { router: 'checkout-verify-email' };
		}
	}

	async function submitOrderGroup({
		initialContribution,
		reitOfferingVintageId
	}: {
		initialContribution: string;
		reitOfferingVintageId?: string;
	}): Promise<RouteLocationRaw> {
		const orderGroupPayload: CheckoutOrderGroupDetails = {
			amount: initialContribution
		};

		if (reitOfferingVintageId) {
			orderGroupPayload.reitOfferingVintageId = reitOfferingVintageId;
		} else {
			orderGroupPayload.riaPlanId =
				signupCheckoutSelections?.value?.selections?.plan.id ??
				signupCheckoutSelections?.value?.presetPlan?.id ??
				'';
		}

		const orderGroupResponse = await createOrderGroup(orderGroupPayload);
		orderGroupId.value = orderGroupResponse?.orderGroupId ?? '';

		return completeStep();
	}

	async function submitAutoInvestSelections(selections: {
		addAutoInvest: boolean;
		autoInvestAmount: string;
		frequency: string;
		isMonthlyFrequency: boolean;
		firstInvestmentDay: string;
		dayOfWeek: string;
		startDate: string;
	}): Promise<RouteLocationRaw> {
		const autoInvestSelections: CheckoutAutoInvestDetails = {
			addRecurringInvestmentSchedule: selections.addAutoInvest
		};

		if (selections.addAutoInvest) {
			autoInvestSelections.recurringAmount = selections.autoInvestAmount;
			autoInvestSelections.frequency = selections.frequency;

			if (selections.isMonthlyFrequency) {
				autoInvestSelections.firstInvestmentDayOfMonth = selections.firstInvestmentDay;
			} else {
				autoInvestSelections.investmentDayOfWeek = selections.dayOfWeek;
				autoInvestSelections.startDate = selections.startDate;
			}
		}
		await updateAutoInvest(autoInvestSelections);
		return completeStep();
	}

	async function submitPlaidLayerQuestion({
		publicToken,
		skipped
	}: {
		publicToken?: string;
		skipped?: boolean;
	}): Promise<string> {
		await submitPlaidLayerPublicToken({ publicToken, skipped });
		return resumeCheckoutRoute;
	}

	async function clearUserSelections(): Promise<string> {
		await clearAccountType();
		await clearPlanAndProSelections();
		return resumeCheckoutRoute;
	}

	async function storeCheckoutDetails(signupCheckoutResponse: SignupCheckoutSelectionResponse): Promise<void> {
		const ira = useIraStore();
		signupCheckoutSelections.value = signupCheckoutResponse;

		if (signupCheckoutResponse.selections?.plan && !user.isExistingInvestor) {
			ira.plan = signupCheckoutResponse.selections.plan;
		}
	}

	return {
		acknowledgmentsDocIds,
		allFunds,
		allOtherPlans,
		email,
		emailCaptureFields,
		isIraCheckout,
		isPresetOfferingFlow,
		isPresetPlanFlow,
		isStandardFlow,
		orderGroupId,
		phoneNumber,
		recommendedPlans,
		recommendedPlansMarketingInfo,
		signupCheckoutSelections,
		userSelectedIraAccount,
		clearUserSelections,
		completeStep,
		getAvailableSignupRiaPlans,
		getPlanMarketingInfo,
		getUserCheckoutDetailsData,
		goToNextStep,
		placeOrder,
		storeCheckoutDetails,
		submitAccountDetails,
		submitAutoInvestSelections,
		submitSignupOrder,
		submitOrderGroup,
		submitPassword,
		submitPlaidLayerQuestion,
		submitPlanSelection,
		submitSelectedAccount,
		submitUserCountrySelections,
		updatePaymentMethod
	};
});
