import { computed, ref } from 'vue';
import {
	deleteGoal as deleteGoalApi,
	getCurrentGoal,
	getGoalAmountOptions as getGoalAmountOptionsApi,
	getGoalIncomeAmountSelect,
	getGoalSuggestion as getGoalSuggestionApi,
	saveCurrentGoal as saveCurrentGoalApi,
	saveOneClickGoal as saveOneClickGoalApi,
	updateCurrentGoal as updateCurrentGoalApi
} from '@api/investment-goals';
import {
	GoalIncomeSelectAmount,
	GoalResponse,
	GoalSelectAmount,
	GoalSuggestion,
	SaveInvestorGoal,
	UpdateInvestorGoal
} from 'types/investment-goals';
import { AutoInvestSchedule } from 'types/auto-invest';
import { defineStore } from 'pinia';
import { getRecurringInvestmentSchedule } from '@api/auto-invest';
import { investmentEntity } from '@store/modules/investment-entity';
import { updateDrip } from '@api/drip';

export const useInvestmentGoalsStore = defineStore('investmentGoals', () => {
	const currentGoal = ref<GoalResponse | null>(null);

	const saveInvestorGoal = ref<SaveInvestorGoal>({
		goalDripEnabled: true,
		goalName: '',
		goalType: null,
		targetValue: '',
		targetIncome: '',
		years: ''
	});

	const minimumAmount = ref('0');
	const maxAmount = ref('0');
	const minimumMonthlyInvestment = ref('10');
	const maximumMonthlyInvestment = ref('20000');
	const autoInvestSchedule = ref<AutoInvestSchedule | null>(null);
	const autoInvestSuggestedAmount = ref('');
	const autoInvestScheduleUpdated = ref(false);

	const isAutoInvestGreaterThanSuggestedAmount = computed(
		(): boolean =>
			parseInt(autoInvestSchedule.value?.monthlyAmount ?? '0') >= parseInt(autoInvestSuggestedAmount.value)
	);

	async function getGoalAmountOptions(years: string): Promise<GoalSelectAmount> {
		const goalAmountOptions = await getGoalAmountOptionsApi(years);
		minimumAmount.value = goalAmountOptions.minimumTargetValue;
		maxAmount.value = goalAmountOptions.maxTargetValue;

		if (goalAmountOptions.maxMonthlyAmount) {
			maximumMonthlyInvestment.value = goalAmountOptions.maxMonthlyAmount;
		}

		return goalAmountOptions;
	}

	async function getIncomeGoalAmount(years: string): Promise<GoalIncomeSelectAmount> {
		const goalIncomeSelectAmount = await getGoalIncomeAmountSelect(years);
		minimumAmount.value = goalIncomeSelectAmount.minimumTargetIncome;
		maxAmount.value = goalIncomeSelectAmount.maxTargetIncome;

		return goalIncomeSelectAmount;
	}

	async function getGoalSuggestion(params: Partial<SaveInvestorGoal>): Promise<GoalSuggestion> {
		const goalSuggestion = await getGoalSuggestionApi(params);
		const minimum =
			goalSuggestion.goalCategory === 'GROWTH_GOAL'
				? goalSuggestion.minimumTargetValue
				: goalSuggestion.minimumTargetIncome;
		minimumAmount.value = minimum ?? '0';
		return goalSuggestion;
	}

	function submitGoalType(params: Partial<SaveInvestorGoal>): string {
		const updatedInvestorGoal = {
			...saveInvestorGoal.value,
			...params
		};
		saveInvestorGoal.value = updatedInvestorGoal;
		return 'investment-goal-time-horizon';
	}

	function submitTimeHorizon(years: string): string {
		const updatedInvestorGoal = {
			...saveInvestorGoal.value,
			years
		};
		saveInvestorGoal.value = updatedInvestorGoal;
		return 'investment-goal-amount';
	}

	function submitTargetAmount(targetValue: string): string {
		const updatedInvestorGoal = {
			...saveInvestorGoal.value,
			targetValue
		};
		saveInvestorGoal.value = updatedInvestorGoal;
		return 'investment-goal-confirm';
	}

	function submitTargetIncome(targetIncome: string): string {
		const updatedInvestorGoal = {
			...saveInvestorGoal.value,
			targetIncome
		};
		saveInvestorGoal.value = updatedInvestorGoal;
		return 'investment-goal-confirm';
	}

	async function submitDrip(): Promise<void> {
		await updateDrip('RIA_REINVESTMENT');
	}

	async function getAutoInvestSchedule(): Promise<void> {
		try {
			const schedule = await getRecurringInvestmentSchedule();
			autoInvestSchedule.value = schedule;
		} catch {}
	}

	async function fetchAndStoreCurrentGoal(): Promise<void> {
		if (investmentEntity.investorGoalExists) {
			const currentGoalResponse = await getCurrentGoal();
			currentGoal.value = currentGoalResponse;
		}
	}

	async function saveOneClickGoal(): Promise<GoalResponse> {
		const currentInvestorGoal = await saveOneClickGoalApi();
		currentGoal.value = currentInvestorGoal;
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);

		return currentInvestorGoal;
	}

	async function saveCurrentGoal(goal: SaveInvestorGoal): Promise<GoalResponse> {
		const currentInvestorGoal = await saveCurrentGoalApi(goal);
		currentGoal.value = currentInvestorGoal;
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);

		return currentInvestorGoal;
	}

	async function updateCurrentGoal(goal: UpdateInvestorGoal): Promise<GoalResponse> {
		const currentInvestorGoal = await updateCurrentGoalApi(goal);
		currentGoal.value = currentInvestorGoal;
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);

		return currentInvestorGoal;
	}

	async function deleteGoal(): Promise<void> {
		await deleteGoalApi();
		currentGoal.value = null;
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(false);
	}

	function updateDefaultGoalCreatedPreferences(monthlySuggestedAmount: string): void {
		autoInvestSuggestedAmount.value = monthlySuggestedAmount;
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);
	}

	function $reset(): void {
		const defaultSaveInvestorGoal: SaveInvestorGoal = {
			goalDripEnabled: true,
			goalName: '',
			goalType: null,
			targetValue: '',
			targetIncome: '',
			years: ''
		};

		saveInvestorGoal.value = defaultSaveInvestorGoal;
		autoInvestSchedule.value = null;
		autoInvestScheduleUpdated.value = false;
	}

	return {
		currentGoal,
		saveInvestorGoal,
		minimumAmount,
		maxAmount,
		minimumMonthlyInvestment,
		maximumMonthlyInvestment,
		autoInvestSchedule,
		autoInvestSuggestedAmount,
		autoInvestScheduleUpdated,
		isAutoInvestGreaterThanSuggestedAmount,
		getGoalAmountOptions,
		getIncomeGoalAmount,
		getGoalSuggestion,
		submitGoalType,
		submitTimeHorizon,
		submitTargetAmount,
		submitTargetIncome,
		submitDrip,
		getAutoInvestSchedule,
		fetchAndStoreCurrentGoal,
		saveOneClickGoal,
		saveCurrentGoal,
		updateCurrentGoal,
		deleteGoal,
		updateDefaultGoalCreatedPreferences,
		$reset
	};
});
