<template>
	<div ref="target" class="module mx-100-smo mt-50-md">
		<div class="module-section position-relative">
			<div
				class="waiver-banner position-absolute display-flex justify-content-center align-items-center body-sm font-weight-bold"
			>
				{{ moduleDetails?.rewardBanner }}
			</div>

			<div v-if="isError" class="display-flex justify-content-space-between">
				<div class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
				<ModularDashboardRetryButton @retry-clicked="loadModule()" />
			</div>

			<ModularDashboardPlaceholderLines
				v-else
				:placehold="loading"
				:use-animation="loading"
				:line-count="2"
				:section-count="1"
				:class="{ 'col-6': loading }"
			>
				<div
					class="display-flex justify-content-space-between-lg flex-direction-column-reverse-smo flex-direction-column-reverse-mdo gap-100-smo gap-100-md"
				>
					<div class="text-align-center-smo text-align-center-mdo">
						<h3 class="heading-5">{{ moduleDetails?.headline }}</h3>
						<p class="mt-25"
							>{{ moduleDetails?.subText }}
							<BaseTooltip class="ml-25-md" mixpanel-target="tooltip">
								<p class="body-xs">{{ moduleDetails?.tooltip }}</p>
							</BaseTooltip></p
						>
					</div>

					<ProgressBarSections
						:number-of-items="progressBlockCount"
						:number-of-complete-items="completeItemsCount"
					/>
				</div>
			</ModularDashboardPlaceholderLines>
		</div>

		<ul v-if="loading || isError" class="bt">
			<li v-for="i in 7" :key="i" class="module-section">
				<ModularDashboardPlaceholderLines
					:placehold="loading || isError"
					:use-animation="loading || isError"
					:line-count="1"
					:section-count="1"
					class="col-9"
				/>
			</li>
		</ul>
		<ul v-else class="bt">
			<li v-for="(checklistItem, i) in moduleDetails?.growthChecklistItems" :key="i" class="module-section py-0">
				<GrowthChecklistItem :item="checklistItem" />
			</li>
		</ul>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import GrowthChecklistItem from '@components/account/growth-checklist-item.vue';
import { GrowthChecklistModuleData } from 'types/account';
import { ModularDashboardModule } from 'types/modular-dashboard';
import ModularDashboardPlaceholderLines from '../../modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '../../modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import ProgressBarSections from '@components/account/progress-bar-sections.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';
import { useModularDashboardStore } from '@stores/modular-dashboard';

const modularDashboardStore = useModularDashboardStore();

interface Props {
	module: ModularDashboardModule;
}

const props = defineProps<Props>();

const loading = ref(true);
const isError = ref(false);
const target = ref<HTMLElement | null>(null);

const { setViewProperties, setActionProperties } = useAnalyticsProperties();

const progressBlockCount = computed((): number =>
	moduleDetails.value?.growthChecklistItems ? moduleDetails.value.growthChecklistItems.length : 0
);

const completeItemsCount = computed((): number => {
	return moduleDetails.value?.growthChecklistItems.filter((item) => item.complete).length ?? 0;
});

const moduleDetails = computed((): GrowthChecklistModuleData | null => modularDashboardStore.growthChecklistModuleData);

const checklistState = computed((): 'Incomplete' | 'Complete' => {
	if (moduleDetails.value?.growthChecklistItems.every((item) => item.complete)) {
		return 'Complete';
	} else {
		return 'Incomplete';
	}
});

const moduleName = computed((): string => props.module.name);

setViewProperties({ viewTitle: moduleName, viewContent: checklistState });
setActionProperties({ actionContext: moduleName });

async function loadModule(): Promise<void> {
	loading.value = true;
	isError.value = false;

	try {
		await modularDashboardStore.getGrowthChecklistModuleDetails();
	} catch {
		isError.value = true;
	} finally {
		loading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': moduleName.value,
			'View Content': checklistState.value
		});
	}
}

setup();
</script>
<style lang="scss" scoped>
@use '../../../../../styles/constants/colors' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.waiver-banner {
	width: auto;
	white-space: nowrap;
	height: 30px;
	border-radius: 35px 0px 0px 35px;
	border: 1px solid map-get($brand-colors, gold-50);
	border-bottom: 4px solid map-get($brand-colors, gold-50);
	border-right: 4px solid map-get($brand-colors, gold-50);
	background: map-get($brand-colors, gold-20);
	padding: 4px 12px;
	top: -18px;
	right: -8px;

	&:after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-left: 0px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid map-get($golds, 30);
		margin: 2rem;
		top: -3px;
		right: -36px;
	}
}
</style>
