import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { genericErrorToast } from '@constants/toasts';
import { investmentEntity } from '@store/modules/investment-entity';
import { useIraStore } from '@stores/ira';
import { user } from '@store/modules/user';

const getFirstPendingVerificationIraInvestmentEntityId = (): string => {
	const pendingVerificationIraEntities = user.user?.entityDetails?.investmentEntities.find(
		(entity) => entity.iraEntityMetadata?.verificationStatus === 'AWAITING_DOCUMENTATION'
	);
	return pendingVerificationIraEntities ? pendingVerificationIraEntities.investmentEntityId : '';
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraVerifyRoutes: FundriseRouteConfig = {
	path: `/ira/verify`,
	name: 'ira-verify',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	beforeEnter: async (to, from, next) => {
		const ira = useIraStore();
		const pendingVerificationInvestmentEntityId = getFirstPendingVerificationIraInvestmentEntityId();
		if (pendingVerificationInvestmentEntityId === '') {
			next({ name: 'account-overview', replace: true });
			app.ADD_TOAST({
				type: 'error',
				message: 'No IRA for verification is available.'
			});
		} else {
			if (investmentEntity.investmentEntityId !== pendingVerificationInvestmentEntityId) {
				await user.updateSelectedEntity(pendingVerificationInvestmentEntityId);
			}

			ira.flow = 'VERIFY';
			if (!ira.dataRetrieved) {
				try {
					await ira.getVerifyFlowInfo();
				} catch (error) {
					next({ name: 'account-overview', replace: true });
					app.ADD_TOAST(genericErrorToast);
				}
			}
			await next();
		}
	},
	component: () => import('@views/ira/verify/ira-verify-start.vue'),
	children: [
		{
			path: `documents`,
			name: 'ira-verify-documents',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const ira = useIraStore();
				if (ira.verificationStatus === 'AWAITING_DOCUMENTATION') {
					await next();
				} else {
					await next({ name: 'ira-verify-success', replace: true });
				}
			},
			component: () => import('@views/ira/signup/ira-documents.vue')
		},
		{
			path: `success`,
			name: 'ira-verify-success',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/ira/verify/ira-verify-success.vue')
		}
	]
};

export default iraVerifyRoutes;
