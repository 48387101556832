<template>
	<div class="position-relative">
		<div
			v-if="showLeftArrow"
			class="performance-context-carousel-arrow-container performance-context-carousel-arrow-container-left"
			@click="handleLeftArrowClick()"
			@keyup.enter="handleLeftArrowClick"
		>
			<BaseButtonIconOnly
				icon="chevron"
				icon-dir="left"
				aria-label="Carousel left button"
				:elevation="true"
				@click.stop="handleLeftArrowClick"
			/>
		</div>
		<div
			ref="carouselElem"
			class="performance-context-carousel overflow-scroll overflow-scroll-no-wrap py-25"
			@mouseenter="onMouseEnter"
			@mouseleave="onMouseLeave"
		>
			<div v-if="props.cards.length < 2" class="performance-context-carousel-placeholder">
				<BaseSvgIcon role="presentation" name="check-outline" width="24" height="24" />
				<div class="body-md font-weight-bold">All caught up</div>
				<div class="body-sm">You'll see more events in the future</div>
			</div>
			<div
				v-for="(card, i) in props.cards"
				ref="cardElems"
				:key="i"
				class="performance-context-carousel-card-container"
			>
				<PerformanceContextCard :card="card" :is-active="isCardActive(card)" @hovered-card="onCardHover" />
			</div>
		</div>
		<div
			v-if="showRightArrow"
			class="performance-context-carousel-arrow-container performance-context-carousel-arrow-container-right"
			@click="handleRightArrowClick()"
			@keyup.enter="handleRightArrowClick"
		>
			<BaseButtonIconOnly
				icon="chevron"
				icon-dir="right"
				aria-label="Carousel right button"
				:elevation="true"
				@click.stop="handleRightArrowClick"
			/>
		</div>
	</div>
</template>
<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import PerformanceContextCard from '@components/account/performance-context/performance-context-card.vue';
import { PerformanceContextCardData } from 'types/performance-context';
import { useEventListener } from '@vueuse/core';
import { useModularDashboardStore } from '@stores/modular-dashboard';

interface FocusEvent extends Event {
	target: HTMLButtonElement;
}

interface Props {
	cards: Array<PerformanceContextCardData>;
}

interface Emits {
	(e: 'carousel-scrolled'): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const modularDashboardStore = useModularDashboardStore();

const carouselElem = ref<HTMLElement | null>(null);
const cardElems = ref<Array<HTMLElement> | null>([]);
const showLeftArrow = ref(false);
const showRightArrow = ref(false);
const isHovered = ref(false);
const ignoreScrollEvents = ref(false);

watch(
	() => modularDashboardStore.accountValuePerformanceContext.activeEventIds,
	(eventIds: Array<string>) => {
		// TODO - explore using scroll offset instead of tracking hover state
		if (eventIds.length && !isHovered.value) {
			const latest = eventIds[eventIds.length - 1];
			const index = props.cards.findIndex((card) => card.eventName === latest);
			const card = carouselElem.value?.querySelectorAll<HTMLLinkElement>(
				'.performance-context-carousel-card-container'
			)[index];

			if (card && carouselElem.value) {
				const scrollPadding = 57;
				const scrollLeft = carouselElem.value.scrollLeft;
				const containerWidth = carouselElem.value.clientWidth;
				const elementLeft = card.offsetLeft;
				const elementWidth = card.clientWidth;
				const leftAligned = elementLeft - scrollPadding;
				const rightAligned = elementLeft - containerWidth + elementWidth + scrollPadding;

				// if element is to the left and is only active event, position it to left, otherwise position it to right (to simulate scroll to)
				if (scrollLeft - elementLeft > 0) {
					// if multiple active events, align element to right of scroll container, otherwise align element to left
					const leftPosition = eventIds.length > 1 ? rightAligned : leftAligned;
					carouselElem.value.scrollTo({ left: leftPosition, behavior: 'smooth' });
				} else if (elementLeft - scrollLeft + elementWidth > containerWidth) {
					// align element to right of scroll container
					carouselElem.value.scrollTo({
						left: rightAligned,
						behavior: 'smooth'
					});
				} else if (scrollLeft - elementLeft < 0 && eventIds.length > 1) {
					// if multiple active events and latest is already in view, align element to right of scroll container to reveal other active events
					carouselElem.value.scrollTo({
						left: rightAligned,
						behavior: 'smooth'
					});
				}
			}
		}
	},
	{ immediate: true }
);

watch(
	() => modularDashboardStore.accountValueChartActiveTimeHorizon,
	() => {
		// nextTick to ensure the DOM is updated before calling updateArrows
		nextTick(() => {
			if (carouselElem.value) {
				ignoreScrollEvents.value = true;
				carouselElem.value.scrollLeft = carouselElem.value.scrollWidth;
				updateArrows();
			}
		});
	},
	{ immediate: true }
);

onMounted(() => {
	if (carouselElem.value) {
		ignoreScrollEvents.value = true;
		carouselElem.value.scrollLeft = carouselElem.value.scrollWidth;
		useEventListener(carouselElem, 'scroll', handleScroll, { passive: true });
		useEventListener(window, 'resize', updateArrows, { passive: true });

		if (cardElems.value) {
			cardElems.value.forEach((cardElem) =>
				useEventListener(cardElem, 'focus', updateTabPosition, { passive: true })
			);
		}

		updateArrows();
	}
});

function handleScroll(): void {
	if (!ignoreScrollEvents.value) {
		emits('carousel-scrolled');
	}
	updateArrows();
	ignoreScrollEvents.value = false;
}

function updateArrows(): void {
	if (carouselElem.value) {
		showLeftArrow.value = carouselElem.value.scrollLeft > 0;
		showRightArrow.value = !!getRightOverflowedCard(carouselElem.value);
	}
}

function updateTabPosition({ target }: FocusEvent): void {
	if (!carouselElem.value || !target) return;

	if (isLeftOverflowed(carouselElem.value, target)) {
		handleLeftArrowClick();
	} else if (isRightOverflowed(carouselElem.value, target)) {
		handleRightArrowClick();
	}
}

function isLeftOverflowed(carousel: HTMLElement, card: Element): boolean {
	return carousel.getBoundingClientRect().left > card.getBoundingClientRect().left;
}

function isRightOverflowed(carousel: HTMLElement, card: Element): boolean {
	return carousel.getBoundingClientRect().right < card.getBoundingClientRect().right;
}

function getLeftOverflowedCard(carousel: HTMLElement): Element | undefined {
	return Array.from(carousel.children)
		.reverse()
		.find((card) => isLeftOverflowed(carousel, card));
}

function getRightOverflowedCard(carousel: HTMLElement): Element | undefined {
	return Array.from(carousel.children).find((card) => isRightOverflowed(carousel, card));
}

function getLeftScrollPosition(carousel: HTMLElement, card: Element): number {
	const { left: carouselLeft } = carousel.getBoundingClientRect();
	const { right: cardRight } = card.getBoundingClientRect();

	return carousel.scrollLeft - (carousel.offsetWidth - (cardRight - carouselLeft));
}

function getRightScrollPosition(carousel: HTMLElement, card: Element): number {
	const { right: carouselRight, width: carouselWidth } = carousel.getBoundingClientRect();
	const { right: cardRight, width: cardWidth } = card.getBoundingClientRect();

	return carousel.scrollLeft + (carouselWidth - (cardWidth - (cardRight - carouselRight)));
}

function handleLeftArrowClick(): void {
	if (!carouselElem.value) return;

	const leftOverflowedCard = getLeftOverflowedCard(carouselElem.value);

	if (leftOverflowedCard) {
		const scrollPosition = getLeftScrollPosition(carouselElem.value, leftOverflowedCard);
		carouselElem.value.scrollTo({ left: scrollPosition, behavior: 'smooth' });
	}
}

function handleRightArrowClick(): void {
	if (!carouselElem.value) return;

	const rightOverflowedCard = getRightOverflowedCard(carouselElem.value);

	if (rightOverflowedCard) {
		const scrollPosition = getRightScrollPosition(carouselElem.value, rightOverflowedCard);
		carouselElem.value.scrollTo({ left: scrollPosition, behavior: 'smooth' });
	}
}

function isCardActive(card: PerformanceContextCardData): boolean {
	return modularDashboardStore.accountValuePerformanceContext.activeEventIds.includes(card.eventName);
}

function onCardHover(cardId: string): void {
	modularDashboardStore.setAccountValueChartActivePerformanceContextEvents(cardId ? [cardId] : []);
}

function onMouseEnter(): void {
	isHovered.value = true;
}

function onMouseLeave(): void {
	isHovered.value = false;
}
</script>
<style lang="scss" scoped>
@use '../../../styles/constants/_colors.scss' as *;

.performance-context-carousel {
	position: relative;
	display: flex;
	scroll-padding: 8px;

	&-arrow-container {
		position: absolute;
		top: 0;
		height: 100%;
		width: 2rem;
		z-index: 2;
		pointer-events: auto;
		cursor: pointer;

		&-left {
			left: -1px;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
		}

		&-right {
			right: -1px;
			background: linear-gradient(270deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
		}

		.button-icon-only {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 3;
		}
	}

	&-placeholder {
		width: 320px;
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
		align-items: center;
		justify-content: center;
		color: token('content-secondary');
		background-color: token('greige');
	}

	&-card-container {
		display: flex;
		padding: 0 1rem;
		position: relative;

		&::before {
			position: absolute;
			left: 0;
			top: 1rem;
			bottom: 1rem;
			content: '';
			width: 1px;
			background-color: gray(20);
		}

		&:last-of-type::after {
			position: absolute;
			right: 0;
			top: 1rem;
			bottom: 1rem;
			content: '';
			width: 1px;
			background-color: gray(20);
		}
	}
}
</style>
