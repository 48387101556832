export const copyToClipboard = async (copyText: string): Promise<boolean> => {
	if (navigator.clipboard) {
		await navigator.clipboard.writeText(copyText);
		return true;
	}
	const textArea = document.createElement('textarea');
	textArea.value = copyText;

	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	try {
		return document.execCommand('copy');
	} catch (e) {
		return false;
	} finally {
		if (textArea?.parentElement) {
			textArea.parentElement.removeChild(textArea);
		}
	}
};
