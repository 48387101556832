import {
	IraRequiredValue,
	IraSignupRequiredState,
	IraSignupRoute,
	IraStateDeterminerStep,
	IraVerificationStatus
} from 'types/ira';
import { useIraStore } from '@stores/ira';
import { user } from '@store/modules/user';

const isContribution = (): boolean => {
	const ira = useIraStore();
	return ira.fundingType === 'CONTRIBUTION';
};
const defaultStatePage = 'ira-personal-information';

const personalInfoSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (
		ira.personalInfo.primaryAddress.address1 &&
		ira.personalInfo.primaryAddress.city &&
		ira.personalInfo.primaryAddress.state &&
		ira.personalInfo.primaryAddress.zip
	) {
		return null;
	} else {
		return 'ira-personal-information';
	}
};

const currentPhoneSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.currentPhone) {
		return null;
	} else {
		return 'ira-mobile';
	}
};

const fundingTypeSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.fundingType) {
		return null;
	} else {
		return 'ira-funding';
	}
};

export const getAccountTypeRoute = (): IraSignupRoute =>
	isContribution() ? 'ira-account-type' : 'ira-target-account-type';

const accountTypeSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.accountType && ira.accountTypeLabel) {
		return null;
	} else {
		return getAccountTypeRoute();
	}
};

const sourceAccountTypeSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.sourceAccountType && ira.sourceAccountTypeLabel) {
		return null;
	} else {
		return 'ira-transfer-type';
	}
};

const dripPreferenceSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.dripPreference !== null) {
		return null;
	} else {
		return 'ira-drip';
	}
};

const planSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.plan) {
		return null;
	} else {
		return 'ira-plan';
	}
};

export const getPaymentMethodRoute = (): IraSignupRoute =>
	isContribution() ? 'ira-funding-source' : 'ira-transfer-fees';

const selectedPaymentMethodSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.selectedPaymentMethod) {
		return null;
	} else {
		return getPaymentMethodRoute();
	}
};

export const getFeeScheduleRoute = (): IraSignupRoute => (isContribution() ? 'ira-fee-schedule' : 'ira-transfer-fees');

const acknowledgedFeesSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.acknowledgedFees) {
		return null;
	} else {
		return getFeeScheduleRoute();
	}
};

const orderGroupAcknowledgedSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.orderGroupAcknowledged) {
		return null;
	} else {
		return 'ira-agreements';
	}
};

const rolloverAgreementsSubmittedSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.rolloverAgreementsSubmitted) {
		return null;
	} else {
		return 'ira-agreements';
	}
};

export const getAmountRoute = (): IraSignupRoute => (isContribution() ? 'ira-amount' : 'ira-transfer-amount');

const selectedAmountSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.selectedAmount) {
		return null;
	} else {
		return getAmountRoute();
	}
};

const selectedTaxYearSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.selectedTaxYear) {
		return null;
	} else {
		return 'ira-amount';
	}
};

const reitInvestmentOrderGroupIdSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.reitInvestmentOrderGroupId) {
		return null;
	} else {
		return 'ira-confirm';
	}
};

const contributionSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.fundingType === 'CONTRIBUTION') {
		return null;
	} else {
		return 'ira-funding';
	}
};

const transferSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.fundingType === 'TRANSFER') {
		return null;
	} else {
		return 'ira-funding';
	}
};

const rolloverSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.fundingType === 'ROLLOVER') {
		return null;
	} else {
		return 'ira-funding';
	}
};

const rolloverOrTransferSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.fundingType === 'TRANSFER' || ira.fundingType === 'ROLLOVER') {
		return null;
	} else {
		return 'ira-funding';
	}
};

const simpleSourceAccountTypeSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.sourceAccountType === 'SIMPLE') {
		return null;
	} else {
		return 'ira-transfer-type';
	}
};

const rolloverTypeSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.rolloverType) {
		return null;
	} else {
		return 'ira-rollover-type';
	}
};

const rolloverMethodSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.rolloverMethod) {
		return null;
	} else {
		return 'ira-rollover-method';
	}
};

const rolloverMethodIndirectSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.rolloverMethod === 'INDIRECT') {
		return null;
	} else {
		return 'ira-rollover-method';
	}
};

const rolloverCheckDateSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.rolloverCheckDate) {
		return null;
	} else {
		return 'ira-account-age';
	}
};

const simpleIraFirstContributionDateSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.simpleIraFirstContributionDate) {
		return null;
	} else {
		return 'ira-account-age';
	}
};

const simpleIraFirstContributionDateValidFalseSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.simpleIraFirstContributionDateValid !== null && !ira.simpleIraFirstContributionDateValid) {
		return null;
	} else if (ira.simpleIraFirstContributionDateValid) {
		return 'ira-target-account-type';
	} else {
		return 'ira-account-age';
	}
};

const sourceCustodianDtcInfoSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.sourceCustodianName && ira.sourceDtcParticipantNumber) {
		return null;
	} else if (ira.sourceDtcParticipant !== null) {
		return null;
	} else {
		return 'ira-transfer-account-info';
	}
};

const sourceAccountNumberSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.sourceAccountNumber) {
		return null;
	} else {
		return 'ira-transfer-account-info';
	}
};

const mtcContactCreatedFalseSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (!ira.mtcContactCreated) {
		return null;
	} else {
		return 'ira-funding';
	}
};

const contactCreatedFalseAndNoReitInvestmentOrderGroupSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.mtcContactCreated && ira.reitInvestmentOrderGroupId) {
		return 'ira-agreements';
	} else {
		return null;
	}
};

const contactCreatedTrue = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.mtcContactCreated) {
		return null;
	} else {
		return 'ira-confirm';
	}
};

export const getVerificationRoute = (status: IraVerificationStatus | null): IraSignupRoute => {
	switch (status) {
		case 'ERROR':
		case 'DELIVERY_FAILURE':
			return 'ira-verification-error';
		case 'AWAITING_DOCUMENTATION':
			return 'ira-documents';
		case null:
			return 'ira-otp-type';
		case 'VERIFIED':
			return 'ira-success';
		default:
			return 'ira-mobile-verify';
	}
};

// to view success page
const userVerifiedSet = (): IraSignupRoute | null => {
	const verifiedStatuses: Array<IraVerificationStatus | null> = ['IN_REVIEW', 'VERIFIED', 'DECLINED'];
	const ira = useIraStore();
	if (verifiedStatuses.includes(ira.verificationStatus)) {
		return null;
	} else {
		return getVerificationRoute(ira.verificationStatus);
	}
};

// confirm that OTP page was completed
const userOtpSet = (): IraSignupRoute | null => {
	const otpCompletedStatuses: Array<IraVerificationStatus | null> = [
		'AWAITING_DOCUMENTATION',
		'DELIVERY_FAILURE',
		'ERROR',
		'OTP_SENT',
		'OTP_INCORRECT'
	];
	const ira = useIraStore();
	if (otpCompletedStatuses.includes(ira.verificationStatus)) {
		return null;
	} else {
		return 'ira-otp-type';
	}
};

// Allow entry to docs page
const documentationNeededVerificationSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.verificationStatus === 'AWAITING_DOCUMENTATION') {
		return null;
	} else {
		return getVerificationRoute(ira.verificationStatus);
	}
};

// Allow entry to phone verify page
const phoneVerificationNeededSet = (): IraSignupRoute | null => {
	const phoneStatuses: Array<IraVerificationStatus | null> = ['OTP_SENT', 'OTP_INCORRECT'];
	const ira = useIraStore();
	if (phoneStatuses.includes(ira.verificationStatus)) {
		return null;
	} else {
		return getVerificationRoute(ira.verificationStatus);
	}
};

// to view the otp page
const userVerificationNotStartedSet = (): IraSignupRoute | null => {
	const ira = useIraStore();
	if (ira.verificationStatus === null) {
		return null;
	} else {
		return getVerificationRoute(ira.verificationStatus);
	}
};

const getFunction = (requiredVal: IraRequiredValue): (() => IraSignupRoute | null) => {
	switch (requiredVal) {
		case 'personalInfo':
			return personalInfoSet;
		case 'currentPhone':
			return currentPhoneSet;
		case 'fundingType':
			return fundingTypeSet;
		case 'accountType':
			return accountTypeSet;
		case 'sourceAccountType':
			return sourceAccountTypeSet;
		case 'dripPreference':
			return dripPreferenceSet;
		case 'plan':
			return planSet;
		case 'selectedPaymentMethod':
			return selectedPaymentMethodSet;
		case 'acknowledgedFees':
			return acknowledgedFeesSet;
		case 'orderGroupAcknowledged':
			return orderGroupAcknowledgedSet;
		case 'rolloverAgreementsSubmitted':
			return rolloverAgreementsSubmittedSet;
		case 'selectedAmount':
			return selectedAmountSet;
		case 'selectedTaxYear':
			return selectedTaxYearSet;
		case 'reitInvestmentOrderGroupId':
			return reitInvestmentOrderGroupIdSet;
		case 'contributionFlow':
			return contributionSet;
		case 'transferFlow':
			return transferSet;
		case 'rolloverFlow':
			return rolloverSet;
		case 'rolloverType':
			return rolloverTypeSet;
		case 'rolloverMethod':
			return rolloverMethodSet;
		case 'rolloverMethodIndirect':
			return rolloverMethodIndirectSet;
		case 'rolloverCheckDate':
			return rolloverCheckDateSet;
		case 'rolloverOrTransferFlow':
			return rolloverOrTransferSet;
		case 'simpleSourceAccountType':
			return simpleSourceAccountTypeSet;
		case 'simpleIraFirstContributionDate':
			return simpleIraFirstContributionDateSet;
		case 'simpleIraFirstContributionDateValidFalse':
			return simpleIraFirstContributionDateValidFalseSet;
		case 'sourceCustodianDtcInfo':
			return sourceCustodianDtcInfoSet;
		case 'sourceAccountNumber':
			return sourceAccountNumberSet;
		case 'editingAllowed':
			return mtcContactCreatedFalseSet;
		case 'needsConfirmation':
			return contactCreatedFalseAndNoReitInvestmentOrderGroupSet;
		case 'contactSet':
			return contactCreatedTrue;
		case 'userVerificationCompleted':
			return userVerifiedSet;
		case 'userOtpSelected':
			return userOtpSet;
		case 'verificationNotStarted':
			return userVerificationNotStartedSet;
		case 'documentVerificationFlow':
			return documentationNeededVerificationSet;
		case 'phoneVerificationflow':
			return phoneVerificationNeededSet;
		default:
			return personalInfoSet;
	}
};

const getFirstRequiredRoute = (requiredValsPriorityOrder: Array<IraRequiredValue>): IraSignupRoute | null => {
	const requiredRoutes = requiredValsPriorityOrder.reduce(
		(result, requiredVal) => {
			const redirect = getFunction(requiredVal)();

			if (redirect) {
				result.push(redirect);
			}

			return result;
		},
		[] as Array<IraSignupRoute | null>
	);

	return requiredRoutes[0];
};

export const handleRequiredValues = async (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	next: any,
	requiredValsPriorityOrder: Array<IraRequiredValue>,
	nextRoute?: IraSignupRoute
): Promise<void> => {
	const requiredRoute = getFirstRequiredRoute(requiredValsPriorityOrder);
	if (requiredRoute) {
		if (nextRoute && nextRoute === requiredRoute) {
			try {
				await next();
			} catch {}
		} else {
			try {
				await next({ name: requiredRoute });
			} catch {}
		}
	} else {
		try {
			await next();
		} catch {}
	}
};

const getMatchingDeterminerRoute = (requiredVal: IraStateDeterminerStep | null): IraSignupRoute => {
	switch (requiredVal) {
		case 'MISSING_PERSONAL_INFO':
			return 'ira-personal-information';
		case 'MISSING_PHONE_NUMBER':
			return 'ira-mobile';
		case 'MISSING_FUNDING_TYPE':
			return 'ira-funding';
		case 'MISSING_SOURCE_ACCOUNT_TYPE':
			return 'ira-transfer-type';
		case 'MISSING_SIMPLE_FIRST_CONTRIBUTION_DATE':
			return 'ira-account-age';
		case 'MISSING_IRA_TYPE':
			return getAccountTypeRoute();
		case 'MISSING_SOURCE_ACCOUNT_INFO':
			return 'ira-transfer-account-info';
		case 'MISSING_AMOUNT':
			return getAmountRoute();
		case 'MISSING_RIA_PLAN':
			return 'ira-plan';
		case 'MISSING_ROLLOVER_TYPE':
			return 'ira-rollover-type';
		case 'MISSING_ROLLOVER_METHOD':
			return 'ira-rollover-method';
		case 'MISSING_ROLLOVER_INDIRECT_CHEQUE_DATE':
			return 'ira-rollover-check-date';
		case 'MISSING_BANK_INFO':
			return getPaymentMethodRoute();
		case 'MISSING_FEES_ACKNOWLEDGEMENT':
			return getFeeScheduleRoute();
		case 'MISSING_DRIP_PREFERENCE':
			return 'ira-drip';
		case 'MISSING_ACKNOWLEDGEMENTS':
			return 'ira-agreements';
		case 'MISSING_MTC_CONTACT_VERIFICATION':
			return 'ira-otp-type';
		case 'MTC_VERIFICATION_IN_REVIEW':
		case 'MTC_ONBOARDING_COMPLETE':
			return 'ira-success';
		case 'MTC_VERIFICATION_ERROR':
			return 'ira-verification-error';
		case 'MISSING_MTC_VERIFICATION_DOCUMENTATION':
			return 'ira-documents';
		case 'MISSING_MTC_CONTACT':
			return 'ira-confirm';
		case 'MISSING_MTC_CONTACT_ROLE':
		case 'MISSING_MTC_ACCOUNT':
		case 'MISSING_MTC_CONTRIBUTION':
		case 'MISSING_MTC_TRANSFER':
		case 'MISSING_MTC_INVESTMENT':
			return 'ira-success';
		case null:
		default:
			return defaultStatePage;
	}
};

export const getStateDeterminerRoute = (determinerState: IraStateDeterminerStep | null): IraSignupRoute => {
	return getMatchingDeterminerRoute(determinerState);
};

const getValidIraSignupStep = (): string => {
	if (!user.hasFirstLastName) {
		return 'ira-account-creation';
	} else if (!user.countriesSubmitted) {
		return 'ira-select-country';
	} else if (!user.residenceValid) {
		return 'ira-country-not-supported';
	} else if (user.hasIraEntity) {
		return 'ira-start';
	} else {
		return 'ira-checkout-individual-details';
	}
};

const checkState = async (requiredState: IraSignupRequiredState): Promise<boolean> => {
	switch (requiredState) {
		case 'userNotCreated':
			return !user.hasFirstLastName;
		case 'userCreated':
			return user.hasFirstLastName;
		case 'residenceInvalidOrNotSubmitted':
			return !user.countriesSubmitted || !user.residenceValid;
		case 'residenceValid':
			return user.residenceValid;
		default:
			return user.hasFirstLastName;
	}
};

const checkRequiredStates = async (requiredStates: Array<IraSignupRequiredState>): Promise<boolean> => {
	for (const requiredState of requiredStates) {
		if (!(await checkState(requiredState))) {
			return false;
		}
	}
	return true;
};

export const verifyRequiredIraStates = async (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	next: any,
	requiredStates: Array<IraSignupRequiredState>
): Promise<void> => {
	const isValidStep = await checkRequiredStates(requiredStates);

	if (isValidStep) {
		await next();
	} else {
		const stepName = getValidIraSignupStep();
		await next({ name: stepName, replace: true });
	}
};
