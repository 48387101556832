<template>
	<ChartPlaceholder :class="{ 'p-100 mb-100-smo': loading }" :loading="loading">
		<div
			class="display-flex justify-content-space-between flex-direction-column-smo flex-direction-column-mdo gap-100 gap-50-lg mb-200"
		>
			<div class="display-flex flex-direction-column">
				<div class="body-md font-weight-bold text-color-content-primary">Account value</div>
				<BaseLink
					classes="value-chart-module-account-value heading-1 display-flex align-items-center gap-25 text-color-current-color"
					:link="{ router: 'account-portfolio' }"
					data-test="value-chart-module-account-value"
					><span data-test="account-value">{{ accountValue }}</span
					><BaseSvgIcon name="chevron" dir="right" class="mr-50" width="24" height="24"
				/></BaseLink>

				<div class="display-flex align-items-center gap-50" data-test="value-chart-module-net-returns">
					<span :class="returnIndicator"></span>
					<span :class="[returnsColor, 'display-flex']">
						<span class="font-weight-bold mr-25" :aria-label="returnsValueAriaLabel">{{
							returnsValueFormatted
						}}</span>
						<transition mode="out-in" name="transition--fade">
							<span v-if="displayReturnAndTimeHorizonLabel" class="display-flex gap-50">
								<span class="font-weight-bold" :aria-label="returnsValuePercentageAriaLabel">{{
									returnsInPercentageFormatted
								}}</span>
								<BaseButton
									v-if="activeTimeHorizonLabel"
									classes="value-chart-module-range-label display-flex align-items-center gap-25 text-color-content-secondary"
									data-test="value-chart-module-net-returns-time-horizon"
									mixpanel-target="Return tooltip"
									@click="openReturnCalculationModal()"
								>
									<span data-test="active-time-horizon-label">{{ activeTimeHorizonLabel }}</span>
									<BaseSvgIcon name="info" class="mr-50" width="16" height="16" />
								</BaseButton>
							</span>
						</transition>
					</span>
				</div>
			</div>
			<SegmentedControl
				v-if="chartTimeHorizons.length > 1"
				:controls="chartTimeHorizons"
				:selected="selectedTimeHorizonKey"
				:style="{ height: 'fit-content', width: 'fit-content' }"
				class="align-self-flex-start-lg"
				@selected-segmented-control-key="handleTimeHorizonSelection"
			/>
		</div>
		<ChartPlaceholder :loading="recalculating" :style="`height: ${chartHeight}px`">
			<ValueOverTimeChart
				:custom-options="customOptions"
				:data="activeChartData"
				:default-to-last-point="true"
				:sets="sets"
				:annotations="props.chartAnnotations"
				:custom-y-axis-bounds="customYAxisBounds"
				:active-annotations="props.activeAnnotations"
				:show-marker="true"
				@annotations-focused="onAnnotationFocused"
				@mouse-over="onMouseOver"
				@mouse-out="onMouseOut"
				@mouseleave="onChartBlurred"
				@mouseenter="onChartFocused"
			></ValueOverTimeChart>
		</ChartPlaceholder>
	</ChartPlaceholder>
</template>

<script setup lang="ts">
import {
	AccountValueChartTimeHorizonType,
	NetReturnsOverTimeByTimeHorizon,
	NetReturnsOverTimeByTimeHorizonChartDataPoint
} from 'types/account';
import { computed, ref, Ref, watch } from 'vue';
import { currency, percentage } from '@filters/shared-filters';
import { getPerformanceReturnIndicator, getReturnsColor } from '@utils/performance-portfolio';
import Highcharts, { Options } from 'highcharts';
import {
	ValueOverTimeAnnotation,
	ValueOverTimeChartCustomYAxisBounds,
	ValueOverTimeChartPoint,
	ValueOverTimeChartSeries
} from 'types/charts/value-over-time-chart';
import AccountValueReturnCalculationModal from '@components/account/account-value-return-calculation-modal.vue';
import { app } from '@store/modules/app';
import ChartPlaceholder from '@charts/chart-placeholder.vue';
import { isMobile } from '@utils/composables';
import SegmentedControl from '@components/segmented-control/segmented-control.vue';
import { SegmentedControlButton } from 'types/layout';
import spacetime from 'spacetime';
import { useModularDashboardStore } from '@stores/modular-dashboard';
import ValueOverTimeChart from '@charts/value-over-time-chart.vue';

interface Props {
	chartData?: NetReturnsOverTimeByTimeHorizon | null;
	chartAnnotations?: Array<ValueOverTimeAnnotation>;
	activeAnnotations?: Array<string>;
}
const props = withDefaults(defineProps<Props>(), {
	chartData: null,
	chartAnnotations: () => [],
	activeAnnotations: () => []
});
interface Emits {
	(e: 'active-point', value: ValueOverTimeChartPoint): void;
	(e: 'active-annotations', value: Array<ValueOverTimeAnnotation>): void;
}
const emit = defineEmits<Emits>();

interface UpdateActivePoint {
	hoveredPoint: ValueOverTimeChartPoint | null;
	lastPoint: ValueOverTimeChartPoint | null;
}

const modularDashboardStore = useModularDashboardStore();

const hoveredPoint: Ref<ValueOverTimeChartPoint | null> = ref(null);
const loading = ref(true);
const recalculating = ref(false);

const displayReturnAndTimeHorizonLabel = ref(true);

const chartHeight = computed((): number => {
	return isMobile.value ? 250 : 350;
});

const customOptions = computed((): Options => {
	return {
		chart: {
			height: chartHeight.value,
			marginBottom: 50,
			marginTop: 28
		}
	};
});

const activeChartData = computed((): Array<ValueOverTimeChartPoint> => {
	return props.chartData && props.chartData.returnsOnDateDtos
		? props.chartData.returnsOnDateDtos.map(formatPoint)
		: [];
});

const chartTimeHorizons = computed((): Array<SegmentedControlButton> => {
	return (modularDashboardStore.accountValueChartAvailableTimeHorizons ?? []).map((range) => ({
		key: range.name,
		label: range.label
	}));
});

const selectedTimeHorizon = computed((): AccountValueChartTimeHorizonType | undefined => {
	return modularDashboardStore.accountValueChartActiveTimeHorizon?.name;
});

const selectedTimeHorizonKey = computed((): string => {
	if (chartTimeHorizons.value.length) {
		const timeHorizon = chartTimeHorizons.value.find((option) => option.key === selectedTimeHorizon.value);
		return timeHorizon?.key ?? chartTimeHorizons.value[0].key;
	}
	return '';
});

const lastPoint = computed((): ValueOverTimeChartPoint | null => {
	if (activeChartData.value) {
		return activeChartData.value[activeChartData.value.length - 1];
	}

	return null;
});

const accountValue = computed((): string => {
	return currency(hoveredPoint.value?.data?.accountValue.toString() ?? '');
});

const returnsValue = computed((): string => {
	return hoveredPoint.value?.data?.netReturns.toString() ?? '';
});

const returnsInPercentage = computed((): number | null => {
	return lastPoint.value?.data?.returnsPercentageInPeriod ?? null;
});

const returnsInPercentageFormatted = computed((): string => {
	return returnsInPercentage.value ? `(${percentage(Math.abs(returnsInPercentage.value), true)})` : '';
});

const returnsValueFormatted = computed((): string => {
	return currency(Math.abs(Number(returnsValue.value)));
});

const returnIndicator = computed((): string => {
	return getPerformanceReturnIndicator(returnsValue.value);
});

const returnsColor = computed((): string => {
	return getReturnsColor(returnsValue.value);
});

const returnsValueAriaLabel = computed((): string => {
	return currency(returnsValue.value);
});

const returnsValuePercentageAriaLabel = computed((): string => {
	return returnsInPercentage.value ? percentage(returnsInPercentage.value, true) : '';
});

const activeTimeHorizonLabel = computed((): string => {
	switch (modularDashboardStore.accountValueChartActiveTimeHorizon?.name) {
		case 'THREE_MONTH':
			return 'past 3 months';
		case 'SIX_MONTH':
			return 'past 6 months';
		case 'ONE_YEAR':
			return 'past year';
		case 'YTD':
			return 'year to date';
		case 'ALL_TIME':
			return 'all time';
		default:
			return '';
	}
});

const customYAxisBounds = computed((): ValueOverTimeChartCustomYAxisBounds => {
	switch (selectedTimeHorizon.value) {
		case 'THREE_MONTH':
		case 'SIX_MONTH':
		case 'ONE_YEAR':
		case 'YTD':
			return { yAxisMinGtZero: 0.9, yAxisMinLtZero: 1.1, yAxisMaxGtZero: 1.1, yAxisMaxLtZero: 0.9 };
		case 'ALL_TIME':
		default:
			return { yAxisMinGtZero: null, yAxisMinLtZero: null, yAxisMaxGtZero: null, yAxisMaxLtZero: null };
	}
});

const sets = computed((): Array<ValueOverTimeChartSeries> => {
	const lineColor = '#C5973D';
	const areaColor = '#EBD4A8';
	return [
		{
			color: lineColor,
			name: 'Account Value',
			xKey: 'date',
			yKey: 'accountValue',
			enableMouseTracking: true,
			fillColor: {
				linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
				stops: [
					[0, new Highcharts.Color(areaColor).setOpacity(0.6).get('rgba').toString()],
					[1, new Highcharts.Color(areaColor).setOpacity(0.1).get('rgba').toString()]
				]
			}
		},
		{
			color: lineColor,
			name: 'Account Value Line',
			xKey: 'date',
			yKey: 'accountValue',
			fillColor: {
				stops: []
			}
		}
	];
});

const updateActivePoint = computed((): UpdateActivePoint => {
	return { hoveredPoint: hoveredPoint.value, lastPoint: lastPoint.value };
});

watch(
	() => activeChartData.value,
	() => {
		if (activeChartData.value && activeChartData.value.length) {
			hoveredPoint.value = lastPoint.value;
			loading.value = false;
		}
	},
	{ immediate: true }
);

watch(
	() => updateActivePoint.value,
	(newActivePoint) => {
		if (newActivePoint) {
			emit('active-point', newActivePoint.hoveredPoint ?? newActivePoint.lastPoint ?? { data: {} });
		}
	},
	{ immediate: true }
);

async function handleTimeHorizonSelection(timeHorizonKey: string) {
	const timeHorizon = modularDashboardStore.accountValueChartAvailableTimeHorizons?.find(
		(horizon) => horizon.name === timeHorizonKey
	);

	if (timeHorizon) {
		recalculating.value = true;
		await modularDashboardStore.updateAccountValueChartOnTimeHorizonSelection(timeHorizon);
		recalculating.value = false;
	}
}

function onChartBlurred(): void {
	displayReturnAndTimeHorizonLabel.value = true;
}

function onChartFocused(): void {
	displayReturnAndTimeHorizonLabel.value = false;
}

function openReturnCalculationModal(): void {
	app.UPDATE_CURRENT_MODAL({
		modal: AccountValueReturnCalculationModal
	});
}

function formatPoint(point: NetReturnsOverTimeByTimeHorizonChartDataPoint): ValueOverTimeChartPoint {
	return {
		data: {
			accountValue: Number(point.totalAccountValue),
			netReturns: Number(point.netReturnsInPeriod),
			date: spacetime(point.date, 'UTC').epoch,
			returnsPercentageInPeriod: Number(point.returnsPercentageInPeriod)
		}
	};
}

function onMouseOver(point: ValueOverTimeChartPoint): void {
	hoveredPoint.value = point;
}

function onMouseOut(): void {
	hoveredPoint.value = lastPoint.value;
}

function onAnnotationFocused(annotations: Array<ValueOverTimeAnnotation>): void {
	emit('active-annotations', annotations);
}
</script>

<style lang="scss" scoped>
@use '../../styles/components/investor-dashboard';
</style>
