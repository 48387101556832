import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { clearCookie, getCookieValue, setItemInSessionStorage } from '@utils/web-storage';
import { CurrentBanner, DefaultBanner } from 'types/banner';
import { getAccessTokenFromCornice, verifyPassword } from '@api/login';
import { getLDUserContext, recordLD } from '@utils/launch-darkly';
import { initialize, LDClient, LDContext, LDEvaluationDetail, LDOptions } from 'launchdarkly-js-client-sdk';
import { OauthTokenResponse, TokenScope } from 'types/security';
import { RouteTransition, Toast } from 'types/layout';
import { checkThirdParty } from '@utils/bot-detection';
import { CurrentModal } from 'types/modal';
import { datadogRum } from '@datadog/browser-rum';
import eoy2024banner from '@components/marketing-site/banners/eoy-2024-banner.vue';
import { getDistinctId } from '@utils/analytics';
import { getUserEntityById } from '@utils/user';
import { investmentEntity } from './investment-entity';
import { LDFeatureFlagGroup } from 'types/launch-darkly';
import { logError } from '@utils/error-tracking';
import { markRaw } from 'vue';
import mixpanel from 'mixpanel-browser';
import { revokeToken } from '@api/logout';
import { RouteLocationNormalized } from 'vue-router';
import router from '@router';
import { ScreenSizeType } from 'types/client';
import { storageKeyLoginRedirect } from '@constants/storage-keys';
import store from '..';
import { user } from './user';

export interface AppState {
	currentModal: CurrentModal | null;
	isAuthenticated: boolean;
	screenSize: ScreenSizeType;
	oauthToken: string;
	toasts: Array<Toast>;
}
@Module({
	namespaced: true,
	name: 'app',
	store
})
class AppModule extends VuexModule {
	currentModal: CurrentModal | null = null;
	currentBanner: CurrentBanner | null = null;
	defaultBanner: DefaultBanner | null = { banner: markRaw(eoy2024banner) };

	intercomEnabled = false;
	ldClient: LDClient | null = null;

	userId = '';
	oauthToken = '';
	tokenScope: TokenScope = 'UNAUTHENTICATED';
	refreshTokenTimeout: undefined | number = undefined;
	isCorniceSubstituteUser = false;
	isMobileWebview = false;
	mixpanelInitialized = false;
	userIsIdle = false;
	wafIsLoaded = false;
	isMultipleAumMilestonesEnabled = false;

	routeTransition: RouteTransition = {
		scope: 'fullpage',
		name: 'fade'
	};

	screenSize: ScreenSizeType = 'DESKTOP';

	subPageHeight: number | null = null;

	toasts: Array<Toast> = [];

	get isAdvisor(): boolean {
		return (
			!!user.user?.isInvestmentAdvisor ||
			this.tokenScope.includes('ADVISOR_ACCESS') ||
			this.tokenScope === 'ADVISOR_PRIVILEGED_ACCESS' ||
			this.tokenScope === 'ADVISOR_READ_ACCESS'
		);
	}

	get isVerified(): boolean {
		return this.isAuthenticated && !this.tokenScope.includes('UNVERIFIED');
	}

	get isThirdPartyLogin(): boolean {
		return this.isAuthenticated && this.tokenScope === 'THIRD_PARTY_ACCESS';
	}

	get isAuthenticated(): boolean {
		const unAuthenticatedScopes: TokenScope[] = ['REQUIRES_ADDITIONAL_AUTH', 'UNAUTHENTICATED'];
		return !!this.oauthToken && !unAuthenticatedScopes.includes(this.tokenScope);
	}

	get isReadOnlyAccess(): boolean {
		return this.tokenScope === 'READ_ACCESS' || this.tokenScope === 'ADVISOR_READ_ACCESS';
	}

	get currentRefreshTokenTimeout(): number | undefined {
		return this.refreshTokenTimeout;
	}

	@Action({ rawError: true })
	setRefreshTokenTimeout(timeout: number): void {
		this.clearRefreshTokenTimeout();

		const refreshTimeout = setTimeout(async () => {
			if (this.userIsIdle && this.isAuthenticated) {
				setItemInSessionStorage(storageKeyLoginRedirect, router.currentRoute.value.fullPath);
				this.resetAuthData();
				await router.push({ name: 'login', query: { message: 'expired-session' } });
			}
		}, timeout);

		this.SET_REFRESH_TOKEN_TIMEOUT(refreshTimeout as unknown as number);
	}

	@Action({ rawError: true })
	clearRefreshTokenTimeout(): void {
		clearTimeout(this.currentRefreshTokenTimeout);
		this.SET_REFRESH_TOKEN_TIMEOUT(undefined);
	}

	@Action({ rawError: true })
	public async setUserAuthData(loginResponse: OauthTokenResponse): Promise<void> {
		this.SET_USER_ID(loginResponse.user_id);
		this.SET_OAUTH_TOKEN(loginResponse.access_token);
		this.UPDATE_TOKEN_SCOPE(loginResponse.scope);
		const refreshTokenTimeoutInMs = Number(loginResponse.refresh_expires_in) * 1000;
		this.setRefreshTokenTimeout(refreshTokenTimeoutInMs);
		this.initializeLaunchDarklyInstance();

		if (loginResponse.scope === 'THIRD_PARTY_ACCESS') {
			mixpanel.disable();
		}
	}

	@Action({ rawError: true })
	public async authenticateFromMobileWebview(): Promise<void> {
		const accessToken = getCookieValue('access_token');
		const investmentEntityId = getCookieValue('mobileInvestmentEntityId');

		if (accessToken && this.isMobileWebview) {
			this.SET_OAUTH_TOKEN(accessToken);
			this.UPDATE_TOKEN_SCOPE('FULL_ACCESS');
			this.SET_IS_MOBILE_WEBVIEW(true);
			clearCookie('access_token');
			clearCookie('mobileInvestmentEntityId');
			if (!user.user) {
				await user.getUser();
			}

			if (investmentEntityId) {
				const entity = getUserEntityById(investmentEntityId);
				if (entity) await investmentEntity.setInvestmentEntity(entity);
			}
		} else {
			return Promise.reject();
		}
	}

	@Action({ rawError: true })
	public async revokeMobileWebviewToken(): Promise<void> {
		clearCookie('access_token');
		this.SET_OAUTH_TOKEN('');
		this.UPDATE_TOKEN_SCOPE('UNAUTHENTICATED');
	}

	@Action({ rawError: true })
	public async authenticateFromCornice(): Promise<void> {
		const corniceResponse = await getAccessTokenFromCornice();
		if (corniceResponse.accessToken) {
			this.SET_OAUTH_TOKEN(corniceResponse.accessToken);
			this.UPDATE_TOKEN_SCOPE(corniceResponse.scope);
			this.SET_CORNICE_SUBSTITUTE_USER(true);
		} else {
			this.SET_OAUTH_TOKEN('');
			this.UPDATE_TOKEN_SCOPE('UNAUTHENTICATED');
			this.SET_CORNICE_SUBSTITUTE_USER(false);
			return Promise.reject();
		}
	}

	@Action({ rawError: true })
	public async revokeUserToken(): Promise<void> {
		try {
			await revokeToken();
		} catch {
			// This failure is usually a 401 error where token is already expired.
		} finally {
			this.resetAuthData();
		}
	}

	@Action({ rawError: true })
	public async isFeatureEnabled(featureFlag: string): Promise<boolean> {
		if (!this.ldClient) {
			await app.initializeLaunchDarklyInstance();
		}

		if (this.ldClient) {
			await this.ldClient.waitUntilReady();
			return this.ldClient.variation(featureFlag, false) as boolean;
		}

		return false;
	}

	@Action({ rawError: true })
	public async getLdAbTestGroup(featureFlag: string): Promise<string> {
		if (!this.ldClient) {
			await app.initializeLaunchDarklyInstance();
		}

		if (!getDistinctId()) {
			return '';
		}

		if (this.ldClient) {
			await this.ldClient.waitUntilReady();
			await recordLD(featureFlag);
			const variation = this.ldClient.variation(featureFlag) as string;

			if (!variation) {
				const details = this.ldClient.variationDetail(featureFlag);

				logError(`Launch Darkly Test Group Config Issue: ${featureFlag}`, 'info', {
					key: 'Error details',
					data: details
				});
			}

			return variation;
		}

		return '';
	}

	@Action({ rawError: true })
	public async isUserInLDTestGroup(params: LDFeatureFlagGroup): Promise<boolean> {
		if (checkThirdParty()) {
			return false;
		}

		const testGroup = await this.getLdAbTestGroup(params.featureFlag);
		return testGroup === params.testGroup;
	}

	@Action({ rawError: true })
	public async initializeLaunchDarklyInstance(): Promise<void> {
		if (!this.ldClient) {
			const mixpanelId = getDistinctId();
			const clientId = `${import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}`;
			const ldContext: LDContext = getLDUserContext(mixpanelId);
			const ldOptions: LDOptions = {
				inspectors: [
					{
						name: 'dd-inspector',
						type: 'flag-used',
						method: (flagKey: string, flagDetail: LDEvaluationDetail) => {
							datadogRum.addFeatureFlagEvaluation(flagKey, flagDetail.value);
						}
					}
				]
			};

			if (mixpanelId) {
				const ldClient = initialize(clientId, ldContext, ldOptions);
				this.SET_LD_INSTANCE(ldClient);
			}
		} else {
			const ldUserContext: LDContext = getLDUserContext(getDistinctId());

			const ldIeContext: LDContext = {
				kind: 'investment_entity',
				key: investmentEntity.externalId,
				client_type: 'web'
			};
			if (this.isAuthenticated && investmentEntity.externalId) {
				const multiContext: LDContext = {
					kind: 'multi',
					user: ldUserContext,
					investment_entity: ldIeContext
				};
				this.ldClient.identify(multiContext);
			} else {
				this.ldClient.identify(ldUserContext);
			}
		}
	}

	@Action({ rawError: true })
	public async getCurrentBanner(routeKey = ''): Promise<void> {
		const hideBannerList = [
			'/acq-plus/start',
			'/campaigns/fund/flagship',
			'/campaigns/fund/income',
			'/campaigns/fund/innovation',
			'/partner',
			'/data-visualizations/',
			'/login',
			'/reits/26/view',
			'/offerings/26/view',
			'/fundriseintervalfund-iframe-only/24',
			'/fundriseintervalfund-iframe-only/25',
			'/fundriseintervalfund-iframe-only/26',
			'/connect-api',
			'/404',
			'/forbidden',
			'/maintenance',
			'/questionnaire',
			'/forgotpassword',
			'/education/2024-year-end-letter-to-investors'
		];
		const hideBanner = hideBannerList.filter((hideBannerPath) => {
			return routeKey.includes(hideBannerPath);
		});

		if (hideBanner.length > 0 || this.isMobileWebview) {
			this.UPDATE_CURRENT_BANNER(null);
		} else {
			this.UPDATE_CURRENT_BANNER(this.defaultBanner);
		}
	}

	@Action({ rawError: true })
	public async getPrivilegedToken(password: string): Promise<void> {
		const oauthResponse = await verifyPassword(password);
		this.setUserAuthData(oauthResponse);
	}

	@Action({ rawError: true })
	public toggleIntercom(enabled: boolean): void {
		this.SET_INTERCOM_ENABLED(enabled);
	}

	@Action({ rawError: true })
	public async storeIsMultipleAumMilestonesEnabled(): Promise<void> {
		const isFeatureEnabled = await app.isFeatureEnabled('multiple-aum-milestones');
		this.SET_IS_MULTIPLE_AUM_MILESTONES_ENABLED(isFeatureEnabled);
	}

	@Mutation
	public UPDATE_CURRENT_MODAL(currentModal: CurrentModal | null): void {
		if (currentModal && currentModal.modal) {
			currentModal.modal = markRaw(currentModal.modal);
		}

		this.currentModal = currentModal;
	}

	@Mutation
	public UPDATE_CURRENT_BANNER(currentBanner: CurrentBanner | null): void {
		if (currentBanner && currentBanner.banner) {
			currentBanner.banner = markRaw(currentBanner.banner);
		}
		this.currentBanner = currentBanner;
	}

	@Mutation
	public SET_OAUTH_TOKEN(token: string): void {
		this.oauthToken = token;
	}

	@Mutation
	public SET_LD_INSTANCE(ldClient: LDClient) {
		this.ldClient = ldClient;
	}

	@Mutation
	public ADD_TOAST(toastToAdd: Toast): void {
		const newToast = this.toasts.filter((toast) => toast.message === toastToAdd.message).length <= 0;

		if (newToast) {
			this.toasts.push(toastToAdd);
		}
	}

	@Mutation
	public DISMISS_TOAST(dismissed: Toast): void {
		this.toasts = this.toasts.filter((toast) => toast.message !== dismissed.message);
	}

	@Mutation
	public UPDATE_ROUTE_TRANSITION(payload: { to: RouteLocationNormalized; from: RouteLocationNormalized }): void {
		let toStepIndex = undefined;
		if (typeof payload?.to?.meta?.step === 'function') {
			toStepIndex = payload.to.meta.step(payload.to);
		} else if (typeof payload?.to?.meta?.step === 'number') {
			toStepIndex = payload.to.meta.step;
		}

		let fromStepIndex = undefined;
		if (typeof payload?.from?.meta?.step === 'function') {
			fromStepIndex = payload.from.meta.step(payload.from);
		} else if (typeof payload?.from?.meta?.step === 'number') {
			fromStepIndex = payload.from.meta.step;
		}

		if (toStepIndex && fromStepIndex) {
			this.routeTransition.scope = 'subpage';
			if (fromStepIndex < toStepIndex) {
				this.routeTransition.name = 'slide-left';
			} else if (fromStepIndex > toStepIndex) {
				this.routeTransition.name = 'slide-right';
			} else {
				this.routeTransition.name = 'fade';
			}
		} else {
			this.routeTransition.scope = 'fullpage';
			this.routeTransition.name = 'fade';
		}
	}

	@Mutation
	public UPDATE_SCREEN_SIZE(screenSize: ScreenSizeType): void {
		if (screenSize !== this.screenSize) {
			this.screenSize = screenSize;
		}
	}

	@Mutation
	public UPDATE_SUB_PAGE_HEIGHT(height: number): void {
		if (height !== this.subPageHeight) {
			this.subPageHeight = height;
		}
	}

	@Mutation
	public SET_USER_ID(id: string): void {
		this.userId = id;
	}

	@Mutation
	public UPDATE_TOKEN_SCOPE(scope: TokenScope): void {
		this.tokenScope = scope;
	}

	@Mutation
	public SET_CORNICE_SUBSTITUTE_USER(isCorniceSubstituteUser: boolean): void {
		this.isCorniceSubstituteUser = isCorniceSubstituteUser;
	}

	@Mutation
	public SET_IS_MOBILE_WEBVIEW(isMobileWebview: boolean): void {
		this.isMobileWebview = isMobileWebview;
	}

	@Mutation
	public SET_MIXPANEL_INITIALIZED(mixpanelInitialized: boolean): void {
		this.mixpanelInitialized = mixpanelInitialized;
	}

	@Mutation
	public SET_INTERCOM_ENABLED(enabled: boolean): void {
		this.intercomEnabled = enabled;
	}

	@Mutation
	public SET_REFRESH_TOKEN_TIMEOUT(timeout: number | undefined): void {
		this.refreshTokenTimeout = timeout;
	}

	@Mutation
	public SET_USER_IS_IDLE(isIdle: boolean): void {
		this.userIsIdle = isIdle;
	}

	@Mutation
	public SET_WAF_IS_LOADED(isLoaded: boolean): void {
		this.wafIsLoaded = isLoaded;
	}

	@Mutation
	public SET_IS_MULTIPLE_AUM_MILESTONES_ENABLED(isMultipleAumMilestonesEnabled: boolean): void {
		this.isMultipleAumMilestonesEnabled = isMultipleAumMilestonesEnabled;
	}

	@Action({ rawError: true })
	public resetAuthData(): void {
		this.SET_USER_ID('');
		this.SET_OAUTH_TOKEN('');
		this.UPDATE_TOKEN_SCOPE('UNAUTHENTICATED');
		this.SET_CORNICE_SUBSTITUTE_USER(false);
		this.clearRefreshTokenTimeout();
		this.UPDATE_CURRENT_MODAL(null);
		user.UPDATE_USER(null);
		clearCookie('userLoggedIn');
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_CURRENT_MODAL(null);
		this.UPDATE_CURRENT_BANNER(null);
		this.SET_USER_ID('');
		this.SET_OAUTH_TOKEN('');
		this.SET_CORNICE_SUBSTITUTE_USER(false);
		this.SET_IS_MOBILE_WEBVIEW(false);
		this.UPDATE_SCREEN_SIZE('DESKTOP');
		this.UPDATE_TOKEN_SCOPE('UNAUTHENTICATED');
		this.SET_REFRESH_TOKEN_TIMEOUT(undefined);
		this.SET_USER_IS_IDLE(false);
		this.clearRefreshTokenTimeout();
		this.SET_WAF_IS_LOADED(false);
	}
}

if (!store.hasModule('app')) {
	store.registerModule('app', AppModule);
}

export const app = getModule(AppModule);
