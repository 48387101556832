<template>
	<div class="progress-module-multiple-milestones-content">
		<div v-if="progressModuleDetails.animationUrl" class="display-flex justify-content-space-between gap-100">
			<div class="text-align-left">
				<h2 class="body-md-md font-weight-bold-md heading-6-smo text-color-content-primary"
					>{{ progressModuleDetails?.title }}
				</h2>
				<BaseButton
					v-if="progressModuleDetails?.dismissible"
					:classes="`cta-link ${dismissClass}`"
					mixpanel-element="Module"
					mixpanel-target="Dismiss"
					:mixpanel-context="progressModuleDetails?.title"
					@click.prevent="dismiss()"
				>
					<BaseSvgIcon name="close-light" height="16" width="16" />
				</BaseButton>

				<p class="mt-75">{{ progressModuleDetails?.subText }}</p>
			</div>
			<LottieAnimation
				:loop="false"
				:lottie-file="progressModuleDetails.animationUrl"
				class="animation-wrapper"
			/>
		</div>
		<div v-else class="display-flex flex-direction-column justify-content-space-between">
			<h2 class="body-md-md font-weight-bold-md heading-6-smo text-color-content-primary"
				>{{ progressModuleDetails?.title }}
			</h2>
			<BaseButton
				v-if="progressModuleDetails?.dismissible"
				:classes="`cta-link ${dismissClass}`"
				mixpanel-element="Module"
				mixpanel-target="Dismiss"
				:mixpanel-context="progressModuleDetails?.title"
				@click.prevent="dismiss()"
			>
				<BaseSvgIcon name="close-light" height="16" width="16" />
			</BaseButton>

			<p class="mt-50">{{ progressModuleDetails?.subText }}</p>
		</div>
		<ProgressMeter
			:bar-class="barClass"
			:progress-percent="progressPercentageString"
			:is-animated="true"
			:add-sheen-effect="progressPercentage === 100 ? true : false"
			class="mt-100"
			height="8"
		>
			<template #left-side>
				<span class="text-color-content-secondary body-xs">
					{{ progressModuleDetails?.leftProgressText ?? '' }}
				</span>
			</template>
			<template #right-side>
				<span class="text-color-content-secondary body-xs">
					{{ progressModuleDetails?.rightProgressText ?? '' }}
				</span>
			</template>
		</ProgressMeter>

		<BaseButtonGroup v-if="investLink || trackRewardsButton || nextMilestoneButton">
			<template #primary>
				<BaseLink
					v-if="investLink"
					:mixpanel-context="progressModuleDetails?.title"
					data-test="milestone-invest-button"
					class="button button-mini button-outline"
					:link="investLink"
				/>
				<BaseButton
					v-if="nextMilestoneButton && !investLink"
					:mixpanel-context="progressModuleDetails?.title"
					class="button button-mini button-outline"
					@click="loadNextMilestone"
				>
					{{ nextMilestoneButton.text }}
				</BaseButton>
			</template>
			<template #secondary>
				<BaseButton
					v-if="trackRewardsButton"
					:mixpanel-context="progressModuleDetails?.title"
					classes="track-rewards-button button button-mini button-ghost-default"
					:class="[
						{
							'track-rewards-button--no-padding': !investLink && !nextMilestoneButton
						}
					]"
					@click="openTrackRewardsModal"
				>
					{{ trackRewardsButton.text }}
				</BaseButton>
			</template>
		</BaseButtonGroup>
	</div>
</template>

<script setup lang="ts">
import { app } from '@store/modules/app';
import { computed } from 'vue';
import { getLinkFromRoute } from '@utils/request';
import { Link } from 'types/layout';
import LottieAnimation from '@components/layout/lottie-animation.vue';
import { ModularDashboardCta } from 'types/modular-dashboard';
import { ModularDashboardProgressModule } from 'types/modular-dashboard/custom';
import ProgressMeter from '@components/investment-goals/progress-meter.vue';
import TrackRewardsModal from '@components/account/modular-dashboard/modals/track-rewards-modal.vue';
import { useRouter } from 'vue-router';

interface Props {
	progressModuleDetails: ModularDashboardProgressModule;
}

interface Emits {
	(e: 'dismiss'): void;
	(e: 'loadNextMilestone'): void;
}

const props = defineProps<Props>();

const router = useRouter();

const emits = defineEmits<Emits>();

const investLink = computed((): Link | undefined => {
	if (!props.progressModuleDetails.cta) {
		return;
	} else {
		return getLinkFromRoute(
			router,
			`${props.progressModuleDetails.cta?.targetUrl}?useDefaultAmount=true`,
			props.progressModuleDetails.cta?.text ?? ''
		);
	}
});

const nextMilestoneButton = computed((): ModularDashboardCta | undefined => {
	return props.progressModuleDetails.milestoneCta;
});

const trackRewardsButton = computed((): ModularDashboardCta | undefined => {
	return props.progressModuleDetails.modalData?.cta;
});

const barClass = computed((): string => {
	if (progressPercentage.value >= 100) {
		return 'bg-color-success';
	} else {
		return 'bg-color-data-secondary-dark';
	}
});

const dismissClass = computed((): string => {
	return props.progressModuleDetails.dismissButtonStyle === 'LIGHT' ? 'text-color-white' : 'dark';
});

const progressPercentage = computed((): number => Number(props.progressModuleDetails.percentage) * 100);

const progressPercentageString = computed((): string => progressPercentage.value.toString());

function dismiss(): void {
	emits('dismiss');
}

function loadNextMilestone(): void {
	emits('loadNextMilestone');
}

function openTrackRewardsModal(): void {
	app.UPDATE_CURRENT_MODAL({
		modal: TrackRewardsModal,
		props: {
			content: props.progressModuleDetails.modalData?.content
		}
	});
}
</script>
<style lang="scss">
@use '../../styles/utilities/respond-to.scss' as *;

.progress-module-multiple-milestones-content {
	.animation-wrapper {
		@include respond-to('smo') {
			width: 10rem;
		}
	}

	.button-group {
		margin-top: 1rem;

		.track-rewards-button {
			margin: 0;

			&--no-padding {
				padding: 0;
			}
		}

		@include respond-to('smo') {
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	.dismiss-button-container {
		top: 0.8rem;
		right: 1rem;
	}
}
</style>
