import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { app } from '@store/modules/app';
import { genericErrorToast } from '@constants/toasts';
import { investmentEntity } from '@store/modules/investment-entity';
import { redirect } from '@utils/client';
import { useAutoInvestStore } from '@stores/auto-invest';
import { useInvestmentGoalsStore } from '@stores/investment-goals';

const sameSessionCheck: NavigationGuard = async (to, from, next) => {
	const investmentGoalsStore = useInvestmentGoalsStore();
	if (investmentGoalsStore.saveInvestorGoal.goalType === null) {
		next({ name: 'investment-goal-type' });
	} else {
		next();
	}
};

const sameAutoInvestSessionCheck: NavigationGuard = async (to, from, next) => {
	const investmentGoalsStore = useInvestmentGoalsStore();
	const isPlaidRedirect = to.query['oauth_state_id'];
	if (isPlaidRedirect && investmentEntity.investorGoalExists) {
		await investmentGoalsStore.fetchAndStoreCurrentGoal();
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const goal = investmentGoalsStore.currentGoal!;
		investmentGoalsStore.autoInvestSuggestedAmount = goal.monthlySuggestedAmount;
		const autoInvestStore = useAutoInvestStore();

		await Promise.all([
			investmentEntity.storeBankAccounts(),
			autoInvestStore.fetchFrequenciesAndAmounts(),
			autoInvestStore.fetchAgreements()
		]);

		const plaidOauthId = to.query['oauth_state_id'];
		next({ query: { oauth_state_id: plaidOauthId } });
	} else if (!investmentEntity.investorGoalExists) {
		next({ name: 'investment-goal-type' });
	} else if (investmentEntity.autoInvestSchedule || !investmentGoalsStore.autoInvestSuggestedAmount) {
		next({ name: 'investment-goal-edit' });
	} else {
		next();
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const investmentGoalRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/investment-goal',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: { name: 'investment-goal-edit', replace: true }
	},
	{
		path: `/investment-goal/create`,
		name: 'investment-goal',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.eligibleForInvestorGoal) {
				next();
			} else {
				redirect('/account/overview');
			}
		},
		component: () => import('@views/investment-goals/investment-goal-start.vue'),
		redirect: { name: 'investment-goal-type' },
		children: [
			{
				path: 'type',
				name: 'investment-goal-type',
				meta: {
					step: 1,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				component: () => import('@views/investment-goals/investment-goal-type.vue')
			},
			{
				path: `time-horizon`,
				name: 'investment-goal-time-horizon',
				meta: {
					step: 2,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: sameSessionCheck,
				component: () => import('@views/investment-goals/investment-goal-time-horizon.vue')
			},
			{
				path: `amount`,
				name: 'investment-goal-amount',
				meta: {
					step: 3,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					const investmentGoalsStore = useInvestmentGoalsStore();
					if (investmentGoalsStore.saveInvestorGoal.goalType === null) {
						next({ name: 'investment-goal-type' });
					}
					next();
				},
				component: () => import('@views/investment-goals/investment-goal-amount.vue')
			},
			{
				path: `confirm`,
				name: 'investment-goal-confirm',
				meta: {
					step: 4,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: sameSessionCheck,
				component: () => import('@views/investment-goals/investment-goal-confirm.vue')
			},
			{
				path: `drip`,
				name: 'investment-goal-drip',
				meta: {
					step: 5,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: sameSessionCheck,
				component: () => import('@views/investment-goals/investment-goal-drip.vue')
			}
		]
	},
	{
		path: `/investment-goal/auto-invest`,
		name: 'investment-goal-auto-invest',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentGoalsStore = useInvestmentGoalsStore();
			if (!investmentGoalsStore.autoInvestSuggestedAmount) {
				next({ name: 'investment-goal' });
			} else {
				await investmentGoalsStore.getAutoInvestSchedule();

				if (investmentGoalsStore.autoInvestSchedule) {
					if (investmentGoalsStore.isAutoInvestGreaterThanSuggestedAmount) {
						next({ name: 'investment-goal-success' });
					} else {
						next();
					}
				} else {
					next();
				}
			}
		},
		component: () => import('@views/investment-goals/investment-goal-auto-invest.vue')
	},
	{
		path: `/investment-goal/auto-invest-enable`,
		name: 'investment-goal-auto-invest-enable',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentGoalsStore = useInvestmentGoalsStore();
			const isPlaidRedirect = to.query['oauth_state_id'];
			if (isPlaidRedirect) {
				const plaidOauthId = to.query['oauth_state_id'];
				next({ query: { oauth_state_id: plaidOauthId } });
			} else if (!investmentEntity.investorGoalExists) {
				next({ name: 'investment-goal-type' });
			} else if (investmentGoalsStore.autoInvestSchedule) {
				next({ name: 'investment-goal-edit' });
			} else {
				const autoInvestStore = useAutoInvestStore();
				await Promise.all([
					investmentEntity.storeBankAccounts(),
					autoInvestStore.fetchFrequenciesAndAmounts(),
					autoInvestStore.fetchAgreements()
				]);
				next();
			}
		},
		component: () => import('@views/investment-goals/investment-goal-auto-invest-enable.vue'),
		redirect: { name: 'investment-goal-auto-invest-new' },
		children: [
			{
				path: `new`,
				name: 'investment-goal-auto-invest-new',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: sameAutoInvestSessionCheck,
				component: () => import('@views/investment-goals/investment-goal-auto-invest-new.vue')
			},
			{
				path: `agreements`,
				name: 'investment-goal-auto-invest-agreements',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: sameAutoInvestSessionCheck,
				component: () => import('@views/investment-goals/investment-goal-auto-invest-agreements.vue')
			},
			{
				path: `review`,
				name: 'investment-goal-auto-invest-review',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: sameAutoInvestSessionCheck,
				component: () => import('@views/investment-goals/investment-goal-auto-invest-review.vue')
			}
		]
	},
	{
		path: `/investment-goal/auto-invest-edit`,
		name: 'investment-goal-auto-invest-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentGoalsStore = useInvestmentGoalsStore();
			if (!investmentEntity.investorGoalExists) {
				next({ name: 'investment-goal' });
			} else if (!investmentGoalsStore.autoInvestSuggestedAmount) {
				next({ name: 'investment-goal-edit' });
			} else {
				await investmentEntity.storeBankAccounts();
				next();
			}
		},
		component: () => import('@views/investment-goals/investment-goal-auto-invest-edit.vue')
	},
	{
		path: `/investment-goal/success`,
		name: 'investment-goal-success',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (!investmentEntity.investorGoalExists) {
				next({ name: 'investment-goal' });
			} else {
				next();
			}
		},
		component: () => import('@views/investment-goals/investment-goal-success.vue')
	},
	{
		path: `/investment-goal/view`,
		name: 'investment-goal-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (!investmentEntity.investorGoalExists) {
				next({ name: 'investment-goal' });
			} else {
				next();
			}
		},
		component: () => import('@views/investment-goals/investment-goal-edit.vue')
	},
	{
		path: `/investment-goal/deleted/:goalId`,
		name: 'investment-goal-delete-success',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/investment-goals/investment-goal-delete-success.vue'),
		props: true
	},
	{
		path: '/investment-goal/recurring-investment',
		alias: '/investment-goal/update/recurring-investment',
		name: 'investment-goal-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: { name: 'investment-goal-edit', replace: true }
	},
	{
		path: '/investment-goal/one-click-create',
		name: 'investment-goal-one-click-create',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			try {
				const investmentGoalsStore = useInvestmentGoalsStore();
				await investmentGoalsStore.saveOneClickGoal();
				/* eslint-disable @typescript-eslint/no-non-null-assertion */
				const goal = investmentGoalsStore.currentGoal!;
				investmentGoalsStore.updateDefaultGoalCreatedPreferences(goal.monthlySuggestedAmount);
				await next({ name: 'investment-goal-success', replace: true });
			} catch {
				await next({ name: 'account-overview', replace: true });
				app.ADD_TOAST(genericErrorToast);
			}
		},
		// revisit using redirect
		component: () => import('@views/investment-goals/investment-goal-success.vue')
	}
];

export default investmentGoalRoutes;
