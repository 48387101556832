import { IraAccountType, IraVerificationStatus } from 'types/ira';
import { AdditionalInvestmentEligibility } from 'types/invest';
import { InvestmentStrategy } from 'types/plan';

export type UserReferralEligibilityStatus = 'ELIGIBLE' | 'ELIGIBLE_UPON_SETTLEMENT' | 'INELIGIBLE';

export type InvestmentEntityType = 'JOINT_ACCOUNT' | 'INDIVIDUAL' | 'IRA' | 'LLC' | 'TRUST';

export type DripStatus = 'CASH_DISTRIBUTION' | 'BASIC_REINVESTMENT' | 'RIA_REINVESTMENT';

export enum DripStatuses {
	CASH_DISTRIBUTION = 'CASH_DISTRIBUTION',
	BASIC_REINVESTMENT = 'BASIC_REINVESTMENT',
	RIA_REINVESTMENT = 'RIA_REINVESTMENT'
}

export type FeatureEligibilityFeature = 'advancedReporting' | 'customAllocation' | 'directInvest';

export type FeatureEligibilityFeatureStatus = 'DISABLED' | 'ELIGIBLE' | 'INELIGIBLE_REQUIRES_PRO';

export type FeatureEligibility = Record<FeatureEligibilityFeature, FeatureEligibilityFeatureStatus>;

export interface ReinvestmentPreference {
	reinvestmentPreference: DripStatus;
	reinvestmentPreferenceLabel: string;
}

export interface InvestmentEntity {
	additionalInvestmentEligibility: AdditionalInvestmentEligibility;
	currentRiaPlanInvestmentStrategy?: InvestmentStrategy | null;
	currentRiaPlanName?: string;
	currentSelectedRiaPlanId?: string;
	dripEnabled: boolean;
	eligibleForDrip: boolean;
	eligibleForInvestorGoal: boolean;
	eligibleForRecurringInvestment: boolean;
	eligibleForShareholderVote: boolean;
	entityType: InvestmentEntityType;
	entityTypeLabel?: string;
	externalId: string;
	featureEligibility: FeatureEligibility;
	freezeReason?: string;
	hasActiveRecurringInvestmentSchedule: boolean;
	hasAdvisoryServices: boolean;
	hasEverOwnedIpoShares: boolean;
	hasLegacyInvestments: boolean;
	investmentEntityId: string;
	investmentEntityName: string;
	investorGoalExists: boolean;
	iraEntityMetadata: IraEntityMetadata;
	isFrozen: boolean;
	isIraEntity: boolean;
	isReitInvestor: boolean;
	isVerifiedAccredited: boolean;
	nickname: string;
	selected?: boolean;
	showBrowseMenuItem: boolean;
	showTaxCenter: boolean;
	userReferralEligibilityStatus: UserReferralEligibilityStatus;
}

export interface IraEntityMetadata {
	isMtcIra: boolean;
	isIraOnboardingFinished: boolean;
	iraType: IraAccountType | null;
	iraTypeLabel: string | null;
	verificationStatus?: IraVerificationStatus;
}
