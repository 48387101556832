import { computed, getCurrentInstance, onBeforeUnmount, ref } from 'vue';
import { ThirdPartyAuthType, ThirdPartySignupParameters } from 'types/third-party-auth';
import { app } from '@store/modules/app';
import { captureThirdPartyUser } from '@api/lead-capture';
import { defineStore } from 'pinia';
import { getItemFromLocalStorage } from '@utils/web-storage';
import { isWebview } from '@utils/composables';
import { user } from '@store/modules/user';

export const useThirdPartyAuthStore = defineStore('thirdPartyAuth', () => {
	const _isLoginEnabled = ref<boolean | null>(null);
	const _isSignupEnabled = ref<boolean | null>(null);
	const _isPageDisabled = ref(false);
	const isAppleLibraryLoaded = ref(false);
	const isGoogleLibraryLoaded = ref(false);

	const leadCaptured = computed((): boolean => {
		return !!getItemFromLocalStorage('emailcapture');
	});

	const isLoginEnabled = computed((): boolean => {
		return !_isPageDisabled.value && !!_isLoginEnabled.value;
	});

	const isSignupEnabled = computed((): boolean => {
		return !_isPageDisabled.value && !!_isSignupEnabled.value && !leadCaptured.value && !isWebview.value;
	});

	async function storeFeatureEnabled(): Promise<void> {
		if (_isLoginEnabled.value === null || _isSignupEnabled.value === null) {
			const [loginEnabled, signUpEnabled] = await Promise.all([
				app.isFeatureEnabled('google-apple-signup'),
				app.isUserInLDTestGroup({
					featureFlag: '2024-10-30-google-apple-signup',
					testGroup: 'test-with-third-party-signup'
				})
			]);

			_isLoginEnabled.value = loginEnabled;
			_isSignupEnabled.value = signUpEnabled;
		}
	}

	async function signUp(type: ThirdPartyAuthType, payload: ThirdPartySignupParameters): Promise<string> {
		const userResponseData = await captureThirdPartyUser(type, payload);

		await app.setUserAuthData(userResponseData.accessToken);

		let routeName: string;
		if (userResponseData.isNewUser) {
			await user.setThirdPartyUser(userResponseData);
			routeName = 'checkout-signup-success-interstitial';
		} else {
			routeName = 'account-overview';
		}

		return routeName;
	}

	function disable(reset: typeof onBeforeUnmount): void {
		if (!getCurrentInstance()) return;

		_isPageDisabled.value = true;
		reset(() => (_isPageDisabled.value = false));
	}

	function $reset(): void {
		_isLoginEnabled.value = null;
		_isSignupEnabled.value = null;
		_isPageDisabled.value = false;
		isAppleLibraryLoaded.value = false;
		isGoogleLibraryLoaded.value = false;
	}

	return {
		isAppleLibraryLoaded,
		isGoogleLibraryLoaded,
		isLoginEnabled,
		isSignupEnabled,
		storeFeatureEnabled,
		signUp,
		disable,
		$reset
	};
});
