<template>
	<div ref="target" class="module-md progress-module position-relative">
		<div class="module-section p-100">
			<div v-if="loading || isError">
				<div v-if="isError" class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
				<ModularDashboardPlaceholderLines v-else :show-header="true" :line-count="0" :use-animation="loading" />

				<ModularDashboardPlaceholderBlock
					:show-error-icon="isError"
					class="width-100-smo py-100"
					error-icon-size="small"
					:use-animation="loading"
				/>

				<div v-if="isError" class="pt-50 text-align-center">
					<ModularDashboardRetryButton @retry-clicked="loadModule()" />
				</div>
				<ModularDashboardPlaceholderLines v-else :line-count="2" :show-footer="true" :use-animation="true" />
			</div>
			<ProgressModuleMultipleMilestonesContent
				v-else-if="progressModuleDetails"
				:progress-module-details="progressModuleDetails"
				@load-next-milestone="loadNextMilestone"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import big from 'big.js';
import { ModularDashboardModule } from 'types/modular-dashboard';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import { ModularDashboardProgressModule } from 'types/modular-dashboard/custom';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import ProgressModuleMultipleMilestonesContent from '@components/account/progress-module-multiple-milestones-content.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useModularDashboardStore } from '@stores/modular-dashboard';

interface Props {
	module: ModularDashboardModule;
}

const props = defineProps<Props>();

const modularDashboardStore = useModularDashboardStore();

const isError = ref(false);
const loading = ref(true);
const target = ref<HTMLElement | null>(null);

const progressModuleDetails = computed((): ModularDashboardProgressModule | null => {
	return modularDashboardStore.progressModuleDetails;
});

watch(
	() => progressModuleDetails.value,
	(newModuleDetails, oldModuleDetails) => {
		if (oldModuleDetails === null && !!newModuleDetails && target.value) {
			trackMixpanelScrollIntoView(target.value, {
				'View Title': props.module.name,
				'View ID': props.module.name,
				'View Content': newModuleDetails.subText
			});
		}
	},
	{ immediate: true }
);

async function loadModule(): Promise<void> {
	loading.value = true;
	isError.value = false;

	try {
		await modularDashboardStore.storeProgressModuleDetails();
	} catch {
		isError.value = true;
	} finally {
		loading.value = false;
	}
}

async function loadNextMilestone(): Promise<void> {
	loading.value = true;
	isError.value = false;

	const delayInMilliseconds = 1500;
	let elapsed = big(0);
	const start = performance.now();

	try {
		await modularDashboardStore.storeNextProgressModuleDetails();
		const end = performance.now();

		elapsed = big(end).minus(start);
	} catch {
		isError.value = true;
	} finally {
		if (big(elapsed).gt(delayInMilliseconds)) {
			loading.value = false;
		} else {
			const remainingTime = Number(big(delayInMilliseconds).minus(elapsed));

			setTimeout(() => {
				loading.value = false;
			}, remainingTime);
		}
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>
<style lang="scss" scoped>
@use '../../../../../styles/constants/colors' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.progress-module {
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);

	@include respond-to(smo) {
		background: #fff;
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
	}

	.button-group {
		margin-top: 1rem;
	}
}
</style>
