<template>
	<div
		ref="target"
		class="mx-100-smo p-100 module display-flex flex-direction-column gap-50"
		:data-test="`delta-module-${props.module.name}`"
	>
		<div class="display-flex align-items-top justify-content-space-between">
			<div class="display-flex flex-direction-column gap-25">
				<div class="overline">{{ primaryLabel }}</div>
				<div class="heading-4">{{ primaryValue }}</div>
			</div>
			<div v-if="secondaryLabel && secondaryValue" class="display-flex flex-direction-column text-align-right">
				<div class="display-flex align-items-center gap-50 ml-auto">
					<span :class="secondaryValueIndicatorClass"></span
					><span class="body-lg font-weight-bold" :class="secondaryValueColorClass">{{
						secondaryValue
					}}</span>
				</div>
				<span class="body-xs text-color-content-secondary">{{ secondaryLabel }}</span>
			</div>
		</div>
		<div v-if="ctaLink" class="text-align-right">
			<BaseCtaLink :link="ctaLink" icon="arrow" />
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'DeltaModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { getPerformanceReturnIndicator, getReturnsColor } from '@utils/performance-portfolio';
import big from 'big.js';
import { getFormattedDeltaValue } from '@utils/modular-dashboard';
import { getLinkFromRoute } from '@utils/request';
import { Link } from 'types/layout';
import { ModularDashboardDeltaItem } from 'types/modular-dashboard/delta-module';
import { ModularDashboardModule } from 'types/modular-dashboard';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useModularDashboardStore } from '@stores/modular-dashboard';
import { useRouter } from 'vue-router';

interface Props {
	module: ModularDashboardModule;
}

const props = defineProps<Props>();

const router = useRouter();

const modularDashboardStore = useModularDashboardStore();

const target = ref<HTMLElement | null>(null);

const moduleData = computed((): ModularDashboardDeltaItem | null => {
	return modularDashboardStore.deltaModuleData[props.module.name];
});

const primaryLabel = computed((): string => moduleData.value?.primaryLabel ?? '');

const primaryValue = computed((): string => {
	if (!moduleData.value) {
		return '';
	}

	return getFormattedDeltaValue(moduleData.value.primaryValue, moduleData.value.primaryFormat);
});

const secondaryLabel = computed((): string => moduleData.value?.secondaryLabel ?? '');

const secondaryValue = computed((): string => {
	if (!moduleData.value?.secondaryValue) {
		return '';
	}

	const absValue = big(moduleData.value.secondaryValue).abs().toString();

	const displayValue = moduleData.value.secondaryFormat
		? getFormattedDeltaValue(absValue, moduleData.value.secondaryFormat)
		: absValue;

	return big(moduleData.value.secondaryValue).gte(0) ? displayValue : `(${displayValue})`;
});

const secondaryValueIndicatorClass = computed((): string =>
	moduleData.value?.secondaryValue ? getPerformanceReturnIndicator(moduleData.value.secondaryValue) : ''
);

const secondaryValueColorClass = computed((): string =>
	moduleData.value?.secondaryValue ? getReturnsColor(moduleData.value.secondaryValue) : ''
);

const ctaLink = computed((): Link | null => {
	if (!moduleData.value?.cta?.targetUrl) {
		return null;
	} else {
		return {
			...getLinkFromRoute(router, moduleData.value.cta.targetUrl, moduleData.value.cta.text),
			target: moduleData.value.cta.newTab ? '_blank' : '_self'
		};
	}
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': props.module.title,
			'View Content': props.module.type,
			'View ID': props.module.name
		});
	}
});

async function setup(): Promise<void> {
	try {
		await modularDashboardStore.storeDeltaModuleData(props.module.name);
	} catch {}
}

setup();
</script>

<style lang="scss" scoped>
@use '../../../../styles/components/performance-portfolio';
</style>
