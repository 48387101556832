<template>
	<div ref="target" class="height-100 module-md account-value-chart-module">
		<div
			v-if="isLoading || isError"
			class="display-flex align-items-center-lg flex-direction-column-mdo flex-direction-column-smo gap-100"
		>
			<ModularDashboardPlaceholderLines
				:line-count="1"
				:show-header="true"
				class="flex-1"
				:use-animation="isLoading"
			/>
			<ModularDashboardPlaceholderLines
				:line-count="isDesktop ? 1 : 2"
				:show-header="isDesktop"
				class="flex-1"
				:use-animation="isLoading"
			/>
			<ModularDashboardPlaceholderLines
				v-if="isDesktop"
				:line-count="1"
				:show-header="true"
				class="flex-1"
				:use-animation="isLoading"
			/>
		</div>
		<ModularDashboardPlaceholderBlock
			:placehold="isLoading || isError"
			:show-error-icon="isError"
			:error-message="moduleLoadingErrorMessage"
			:class="{ 'py-100': isLoading || isError }"
			classes="value-chart-module-placeholder-block"
			:use-animation="isLoading"
			@retry-clicked="loadModule()"
		>
			<div v-if="isInPreSettlementState" class="display-flex flex-direction-column gap-200">
				<div class="display-flex flex-direction-column gap-25 p-50">
					<div class="body-md font-weight-bold text-color-content-secondary">Account value</div>
					<div class="heading-1 text-color-content-primary">{{ preSettlementAccountValue }}</div>
				</div>
				<div class="display-flex flex-direction-column align-items-center gap-100 p-50">
					<LottieAnimation
						v-if="showAnimation"
						:lottie-file="lottieFileUrl"
						:loop="false"
						:max-retries="1"
						class="animation-size"
						@on-fail="animationFail = true"
					/>
					<BaseSvgIcon v-else height="140" width="140" name="hd-investment-folder" class="icon" />
					<p class="text-align-center mt-0" v-html="deorphanText(accountStatusDescription)" />
				</div>
			</div>
			<div v-else class="p-100-md">
				<NetReturnsOverTimeByTimeHorizonContainer
					:chart-data="chartDataByTimeHorizon"
					:chart-annotations="annotations"
					:active-annotations="activeAnnotations"
					@active-annotations="onActiveAnnotationUpdate"
					@mouseleave="onChartBlurred"
				/>

				<TransitionCollapse>
					<div v-if="performanceContextCards.length" class="mt-50">
						<PerformanceContextCarousel
							:cards="performanceContextCards"
							@carousel-scrolled="handleCarouselScrolled"
						/>
					</div>
				</TransitionCollapse>
			</div>
		</ModularDashboardPlaceholderBlock>
		<ModularDashboardPlaceholderLines v-if="isLoading || isError" :line-count="0" :show-footer="true" />
	</div>
</template>

<script lang="ts">
export default {
	name: 'AccountValueChartModule'
};
</script>

<script setup lang="ts">
import { AccountValueChartModuleMetadata, ModularDashboardModule } from 'types/modular-dashboard';
import { computed, onMounted, ref } from 'vue';
import { currency, deorphanText } from '@filters/shared-filters';
import { trackMixpanelEvent, trackMixpanelScrollIntoView } from '@utils/analytics';
import { ValueOverTimeAnnotation, ValueOverTimeAnnotationIconType } from 'types/charts/value-over-time-chart';
import { appCloudfrontPath } from '@constants';
import { getAccountValueSummary } from '@api/performance-portfolio';
import { isDesktop } from '@utils/composables';
import LottieAnimation from '@components/layout/lottie-animation.vue';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import { NetReturnsOverTimeByTimeHorizon } from 'types/account';
import NetReturnsOverTimeByTimeHorizonContainer from '@components/account/net-returns-over-time-by-time-horizon-container.vue';
import { PerformanceContextCardType } from 'types/performance-context';
import PerformanceContextCarousel from '@components/account/performance-context/performance-context-carousel.vue';
import spacetime from 'spacetime';
import TransitionCollapse from '@components/layout/transition-collapse.vue';
import { useModularDashboardStore } from '@stores/modular-dashboard';

interface Props {
	module: ModularDashboardModule<AccountValueChartModuleMetadata>;
}

const props = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

const modularDashboardStore = useModularDashboardStore();

const isLoading = ref(true);
const isError = ref(false);
const hasCarouselScrolled = ref(false);

const preSettlementAccountValue = ref('0');

const animationFail = ref(false);

const showAnimation = computed((): boolean => {
	return !animationFail.value;
});

const lottieFileUrl = `${appCloudfrontPath}/video/lottie-json/hd-investment-folder.json`;

const chartDataByTimeHorizon = computed((): NetReturnsOverTimeByTimeHorizon | null => {
	const timeHorizon = modularDashboardStore.accountValueChartActiveTimeHorizon?.name;
	return modularDashboardStore.accountValueChartDataByTimeHorizon && timeHorizon
		? modularDashboardStore.accountValueChartDataByTimeHorizon[timeHorizon] ?? null
		: null;
});

function getAnnotationIconType(cardType: PerformanceContextCardType): ValueOverTimeAnnotationIconType {
	return cardType === 'PERFORMANCE' ? 'PERFORMANCE' : 'DEFAULT';
}

const annotations = computed((): Array<ValueOverTimeAnnotation> => {
	const timeHorizon = modularDashboardStore.accountValueChartActiveTimeHorizon?.name;
	if (timeHorizon) {
		const data = modularDashboardStore.accountValuePerformanceContext.events[timeHorizon] ?? [];

		return data.map((contextEvent) => {
			const formattedDate = spacetime(contextEvent.eventDate, 'UTC').epoch;
			const nearestChartPoint = chartDataByTimeHorizon.value?.returnsOnDateDtos.find(
				(point) => spacetime(point.date, 'UTC').epoch >= formattedDate
			);
			const datePoint = nearestChartPoint ? spacetime(nearestChartPoint.date, 'UTC').epoch : formattedDate;
			return {
				date: datePoint,
				id: contextEvent.eventName,
				icon: getAnnotationIconType(contextEvent.cardData.type)
			};
		});
	}
	return [];
});

const activeAnnotations = computed(() => modularDashboardStore.accountValuePerformanceContext.activeEventIds);

const performanceContextCards = computed(() => {
	const timeHorizon = modularDashboardStore.accountValuePerformanceContext.timeHorizon;
	if (timeHorizon) {
		return modularDashboardStore.accountValuePerformanceContext.events[timeHorizon];
	} else {
		return [];
	}
});

const isInPreSettlementState = computed((): boolean => modularDashboardStore.isInPreSettlementState);

const accountStatusDescription = computed((): string => modularDashboardStore.accountStatusDescription);

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': 'Account Value Chart',
			'View Content': props.module.name,
			'View ID': props.module.type
		});
	}
});

function onActiveAnnotationUpdate(annotations: Array<ValueOverTimeAnnotation>): void {
	const ids = annotations.map((annotation) => annotation.id);
	modularDashboardStore.setAccountValueChartActivePerformanceContextEvents(ids);
}

function onChartBlurred(): void {
	modularDashboardStore.setAccountValueChartActivePerformanceContextEvents([]);
}

function handleCarouselScrolled(): void {
	if (!hasCarouselScrolled.value) {
		hasCarouselScrolled.value = true;
		trackMixpanelEvent('Action', {
			'Action Target': 'View scrolled',
			'Action Context': props.module.title ?? props.module.name
		});
	}
}

async function loadModule(): Promise<void> {
	isLoading.value = true;
	isError.value = false;

	try {
		if (!isInPreSettlementState.value) {
			await modularDashboardStore.storeAccountValueAndPerformanceContextData();
		} else {
			const accountValueSummary = await getAccountValueSummary();
			preSettlementAccountValue.value = currency(accountValueSummary.endingAccountValue);
		}
	} catch {
		isError.value = true;
	} finally {
		isLoading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>

<style lang="scss">
@use '../../../../../styles/components/performance-portfolio';
@use '../../../../../styles/constants/_colors.scss' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.account-value-chart-module {
	@include respond-to(smo) {
		background: #fff;
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
		padding: 1rem 1rem 1.5rem;
	}

	@include respond-to(md) {
		padding: 1rem 1rem 2rem;
	}

	.animation-size {
		height: 140px;
		width: 140px;
	}
}

:deep(.value-chart-module-placeholder-block) {
	width: 100%;
	height: 350px;
}

.value-chart-module-account-value {
	.svg-icon {
		display: none;
	}

	&:hover {
		color: currentColor;
		.svg-icon {
			display: block;
		}
	}
}

.value-chart-module-range-label {
	.svg-icon {
		margin-top: 2px;
		path {
			fill: currentColor;
		}
	}
}
</style>
